.main {
    background-color: var(--white);
    padding: var(--content-gap);
}

.block {
    display: flex;
    flex-direction: column;
    gap: var(--container-gap);
}

.intro {
    background-color: var(--secondary);
    color: var(--white);
    padding: var(--content-gap);
    display: flex;
    align-items: center;
    gap: var(--content-gap);
}

.introIcon {
    font-size: var(--h1);
}

.introText {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
}

.intro h2 {
    font-size: var(--h3);
}

.list {
    display: grid;
    grid-template-columns: var(--grid-4);
    gap: var(--space-xs);
}

.list li {
    display: flex;
    flex: 1;
}

.item {
    background-color: var(--neutral-ultra-light);
    border-radius: var(--radius-m);
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: var(--content-gap);
    border: 1px solid var(--neutral-ultra-light);
    transition: var(--transition);
}

.item:hover {
    background-color: transparent;
    border: 1px solid var(--secondary);
}

.heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--space-xs);
    
}

.urgencyIcon {
    font-size: var(--h3);
    line-height: var(--heading-line-height);
}

.number {
    font-size: var(--h2);
    line-height: var(--heading-line-height);
    font-weight: 700;
    color: var(--secondary);
}

@media screen and (max-width: 1280px) {
    .list {
        grid-template-columns: var(--grid-3);
    }
}

@media screen and (max-width: 1024px) {
    .item h3, .urgencyIcon {
        font-size: var(--h4);
    }

    .description {
        font-size: var(--text-s);
    }
}

@media screen and (max-width: 600px) {
    .intro {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .list {
        grid-template-columns: var(--grid-2);
    }
}

@media screen and (max-width: 450px) {
    .list {
        grid-template-columns: var(--grid-1);
    }
    .item {
        padding: var(--space-xs);
    }
}
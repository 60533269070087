#mapPage {
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 0;
    height: calc(100vh - var(--navbar));
    overflow: hidden;
    position: relative;
}

/* required styles */

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
    width: 1600px;
    height: 1600px;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
    display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg {
    max-width: none !important;
    max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
    max-width: none !important;
    max-height: none !important;
    width: auto;
    padding: 0;
}

.leaflet-container img.leaflet-tile {
    /* See: https://bugs.chromium.org/p/chromium/issues/detail?id=600120 */
    mix-blend-mode: plus-lighter;
}

.leaflet-container {
    -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
    -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
    filter: inherit;
    visibility: hidden;
}

.leaflet-tile-loaded {
    visibility: inherit;
}

.leaflet-zoom-box {
    width: 0;
    height: 0;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 800;
}

.leaflet-vml-shape {
    width: 1px;
    height: 1px;
}


/* control positioning */

.leaflet-control {
    position: relative;
    z-index: 800;
    pointer-events: visiblePainted;
    /* IE 9-10 doesn't have auto */
    pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
    position: absolute;
    z-index: 997;
    pointer-events: none;
}

.leaflet-top {
    top: 0;
}

.leaflet-right {
    right: 0;
}

.leaflet-bottom {
    bottom: 0;
}

.leaflet-left {
    left: 0;
}

.leaflet-control {
    float: left;
    clear: both;
}

.leaflet-right .leaflet-control {
    float: right;
}

.leaflet-top .leaflet-control {
    margin-top: var(--gutter);
}

.leaflet-bottom .leaflet-control {
    margin-bottom: var(--gutter);
}

.leaflet-left .leaflet-control {
    margin-left: var(--gutter);
}

.leaflet-right .leaflet-control {
    margin-right: var(--gutter);
}


/* zoom and fade animations */

.leaflet-fade-anim .leaflet-popup {
    opacity: 0;
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
    opacity: 1;
}

svg.leaflet-zoom-animated {
    will-change: transform;
}

/* visual tweaks */

.leaflet-container {
    background: var(--neutral);
    outline-offset: 1px;
}

.leaflet-container a {
    color: var(--secondary);
}

.leaflet-container button a {
    color: var(--btn-text-color);
}

.leaflet-container button:hover a {
    color: var(--btn-text-color-hover);
}

.leaflet-zoom-box {
    border: 2px dotted var(--secondary-light);
    background: rgba(255, 255, 255, 0.5);
}


/* general typography */
.leaflet-container {
    font-family: inherit;
    font-size: var(--text-s);
    line-height: var(--text-line-height);
}


/* general toolbar styles */

.leaflet-bar {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    border-radius: 0;
}

.leaflet-bar a {
    background-color: var(--white);
    border-bottom: 1px solid var(--neutral-light);
    width: 26px;
    height: 26px;
    line-height: 26px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: block;
}

.leaflet-bar a:hover,
.leaflet-bar a:focus {
    background-color: var(--neutral-ultra-light);
}

.leaflet-bar a:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
    cursor: default;
    background-color: var(--neutral-ultra-light);
    color: #bbb;
}

.leaflet-touch .leaflet-bar a {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
}

.leaflet-touch .leaflet-bar a:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.leaflet-touch .leaflet-bar a:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    font: bold 18px 'Lucida Console', Monaco, monospace;
    text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
    font-size: 22px;
}


/* layers control */

.leaflet-control-layers {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    background: var(--white);
    border-radius: 0;
}

.leaflet-control-layers-toggle {
    width: 36px;
    height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {  
    background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
    width: 44px;
    height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
    display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
    display: block;
    position: relative;
}

.leaflet-control-layers-expanded {
    padding: 6px 10px 6px 6px;
    color: var(--neutral);
    background: var(--white);
}

.leaflet-control-layers-scrollbar {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 5px;
}

.leaflet-control-layers-selector {
    margin-top: 2px;
    position: relative;
    top: 1px;
}

.leaflet-control-layers label {
    display: block;
    font-size: 13px;
    font-size: 1.08333em;
}

.leaflet-control-layers-separator {
    height: 0;
    border-top: 1px solid var(--neutral);
    margin: 5px -10px 5px -6px;
}

/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
    background: var(--white);
    margin: 0;
    font-size: var(--text-xs);
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
    padding: 0 5px;
    color: var(--neutral);
    line-height: 1.4;
}

.leaflet-control-attribution a {
    text-decoration: none;
}

.leaflet-control-attribution a:hover,
.leaflet-control-attribution a:focus {
    text-decoration: underline;
}

.leaflet-attribution-flag {
    display: inline !important;
    vertical-align: baseline !important;
    width: 1em;
    height: 0.6669em;
}

.leaflet-left .leaflet-control-scale {
    margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
    margin-bottom: 5px;
}

.leaflet-control-scale-line {
    border: 2px solid var(--neutral);
    border-top: none;
    line-height: 1.1;
    padding: 2px 5px 1px;
    white-space: nowrap;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.8);
    text-shadow: 1px 1px var(--white);
}

.leaflet-control-scale-line:not(:first-child) {
    border-top: 2px solid var(--neutral);
    border-bottom: none;
    margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
    border-bottom: 2px solid var(--neutral);
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    border: 1px solid var(--neutral-light);
    background-clip: padding-box;
}


/* popup */
.leaflet-popup {
    position: absolute;
    text-align: center;
    margin-bottom: 20px;
    /*top: 50% !important;
    left: 50% !important;
    bottom: initial !important;
    transform: translate(-50%, -50%) !important;*/
}

.leaflet-popup-content-wrapper {
    padding: 1px;
    text-align: left;
    border-radius: 0;
}

.leaflet-popup-content {
    margin: var(--space-s);
    line-height: 1.3;
    font-size: var(--text-s);
    min-height: 1px;
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
}

.leaflet-popup-content h3 {
    font-size: var(--h4);
}

.leaflet-popup-content h4 {
    font-size: var(--text-m);
}

.leaflet-popup-content p {
    margin: 0;
}

.leaflet-popup-tip-container {
    width: 40px;
    height: 20px;
    position: absolute;
    left: 50%;
    margin-top: -1px;
    margin-left: -20px;
    overflow: hidden;
    pointer-events: none;
    display: none; /* Retire le triangle des infobulles */
}

.leaflet-popup-tip {
    width: 17px;
    height: 17px;
    padding: 1px;

    margin: -10px auto 0;
    pointer-events: auto;

    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background: white;
    color: var(--neutral);
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    text-align: center;
    width: 24px;
    height: 24px;
    font: 16px/24px Tahoma, Verdana, sans-serif;
    color: var(--neutral);
    text-decoration: none;
    background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover,
.leaflet-container a.leaflet-popup-close-button:focus {
    color: var(--neutral);
}

.leaflet-popup-scrolled {
    overflow: auto;
}

.leaflet-oldie .leaflet-popup-tip {
    width: 24px;
    margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
    border: 1px solid var(--neutral);
}


/* div icon */

.leaflet-div-icon {
    background: var(--white);
    border: 1px solid var(--neutral);
}


/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
    position: absolute;
    padding: 6px;
    background-color: var(--white);
    border: 1px solid var(--white);
    border-radius: 0;
    color: var(--neutral);
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-interactive {
    cursor: pointer;
    pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
    position: absolute;
    pointer-events: none;
    border: 6px solid transparent;
    background: transparent;
    content: "";
}

/* Directions */

.leaflet-tooltip-bottom {
    margin-top: 6px;
}

.leaflet-tooltip-top {
    margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
    left: 50%;
    margin-left: -6px;
}

.leaflet-tooltip-top:before {
    bottom: 0;
    margin-bottom: -12px;
    border-top-color: var(--white);
}

.leaflet-tooltip-bottom:before {
    top: 0;
    margin-top: -12px;
    margin-left: -6px;
    border-bottom-color: var(--white);
}

.leaflet-tooltip-left {
    margin-left: -6px;
}

.leaflet-tooltip-right {
    margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
    top: 50%;
    margin-top: -6px;
}

.leaflet-tooltip-left:before {
    right: 0;
    margin-right: -12px;
    border-left-color: var(--white);
}

.leaflet-tooltip-right:before {
    left: 0;
    margin-left: -12px;
    border-right-color: var(--white);
}

[class*="marker-cluster"] div {
    background-color: hsla(80, 100%, 37%, .6);
}

/* Printing */

@media print {

    /* Prevent printers from removing background-images of controls. */
    .leaflet-control {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}

/* Areas */

/* svg.leaflet-zoom-animated .leaflet-interactive {
    fill: var(--carte-ultra-dark) !important;
    stroke: var(--carte-light) !important;
}*/

.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive:focus {
    --focus-color: var(--secondary-light);
    outline: solid !important;
    outline-offset: var(--focus-offset);
    outline-color: var(--focus-color) !important;
    outline-width: var(--focus-width);
}

/* .leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive:not(.marker-cluster) {
    filter: drop-shadow(0px 4px 0px var(--carte-light));
}*/

@media screen and (max-width: 1024px) {
    #mapPage {
        height: calc(100vh - var(--responsive-navbar));
    }
}
.shareButtons {
  display: flex;
  gap: var(--space-xs);
  align-items: center;
}

.shareButtons a {
  transition: var(--transition);
  font-size: var(--text-xl);
}

.shareButtons a:hover {
  color: var(--secondary);  /* Couleur au survol */
}

/* FilterControls.module.css */

.container {
  --focus-color: var(--carte);
  width: auto;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: visible;
  z-index: 998;
  transition: var(--transition);
}

.isActive {
  transform: translateX(-450px);
}

.toggleContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 5rem;
  width: 3rem;
  right: -3rem;
  padding-inline: .5rem;
  z-index: 1000;
  background-color: var(--white);
  border: 1px solid var(--neutral-light);
  cursor: pointer;
  transition: var(--transition);
  color: currentColor;
}

.toggleContainer:hover {
  background-color: var(--neutral-ultra-light);
}

.toggleContainer:focus {
  outline: solid;
  outline-offset: var(--focus-offset);
  outline-color: var(--focus-color);
  outline-width: var(--focus-width);
}

.toggle {
  margin: auto;
  display: flex;
  align-items: center;
}

.title {
  font-size: calc(var(--h3)*1.25);
}

.headingContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  padding-inline: var(--gutter);
}

.content {
  width: 450px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding-block: var(--gutter);
}

.dropdown {
  background-color: var(--white);
  border: var(--btn-border-width) var(--btn-border-style) var(--neutral-light);
  padding-block: var(--btn-padding-block);
  padding-inline: var(--btn-padding-inline) calc(var(--btn-padding-inline)*2);
  cursor: pointer;
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  color: currentColor;
}

.dropdownContainer {
  position: relative;
  display: flex;
}

.dropdownContainer svg {
  position: absolute;
  font-size: var(--text-m);
  top: 50%;
  right: calc(var(--btn-padding-block) + (var(--text-m)/4));
  transform: translateY(-50%);
  pointer-events: none; /* Empêche l'interaction avec l'élément */
}

.filtersHeader {
  padding-inline: var(--gutter);
}

.filtersContainer {
  display: flex;
  flex-direction: column;
  gap: var(--content-gap);
  overflow-y: auto;
  /* Scrollbar Firefox */
  scrollbar-width: var(--firefox-width);
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

/* Scrollbar base Chromium */

.filtersContainer::-webkit-scrollbar {
  width: var(--chromium-width);
  height: var(--chromium-height);
}

.filtersContainer::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

.filtersContainer::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border: 2px solid transparent;
}

.filters {
  display: flex;
  flex-direction: column;
}

.listItem {
  display: flex;
  align-items: center;
  gap: var(--content-gap);
  padding-block: var(--space-xs);
  padding-inline: var(--gutter);
  transition: var(--transition);
  cursor: pointer;
  font-size: var(--text-m);
}

.filters li {
  border-top: 1px solid var(--neutral-ultra-light);
}

.filters li:last-child {
  border-bottom: 1px solid var(--neutral-ultra-light);
}

.listItem:focus-within,
.subCatItem:focus-within {
  outline: solid;
  outline-offset: calc(var(--focus-offset) * -1);
  outline-color: var(--focus-color);
  outline-width: var(--focus-width);
}

.listItem:hover {
  background-color: var(--neutral-ultra-light);
}

.labelItem {
  display: flex;
  gap: var(--content-gap);
  align-items: center;
  width: 100%;
}

.listItem svg,
.labelItem svg {
  font-size: var(--text-l);
}

.container input[type='checkbox'] {
  width: 1.75rem;
  height: 1.75rem;
  outline: none;
  margin-left: auto;
  aspect-ratio: 1;
}

.searchFormContainer {
  padding-inline: var(--gutter);
  padding-block: var(--content-gap);
}

.searchForm {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--neutral-ultra-light);
  border-radius: var(--radius-circle);
  padding-inline: var(--btn-padding-inline);
  padding-block: var(--btn-padding-block);
  gap: var(--space-xs);
  position: relative;
}

.searchForm:focus-within {
  transition: var(--transition);
  outline-style: solid;
  outline-color: var(--focus-color);
  outline-width: var(--focus-width);
  outline-offset: var(--focus-offset);
}

.searchForm input {
  outline: none !important;
  width: 100%;
}

.searchForm svg {
  font-size: var(--text-l);
  color: var(--carte);
}

.subCategoryContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-ultra-light);
}

.subCatItem {
  display: flex;
  align-items: center;
  gap: var(--space-xs);
  padding-block: var(--space-xs);
  padding-inline: var(--gutter);
  cursor: pointer;
  transition: var(--transition);
}

.subCatItem:hover {
  background-color: var(--neutral-medium);
}

.resetButton {
  background: var(--neutral-ultra-light);
  border: 1px solid var(--neutral-light);
  padding: var(--btn-padding-block) var(--btn-padding-inline);
  border-radius: var(--radius-s);
  font-size: var(--text-s);
  cursor: pointer;
}

.resetButton:hover {
  background: var(--neutral-light);
}

.labelItem .hasChildrenIcon {
  font-size: var(--text-m);
  margin-left: auto;
  color: currentColor;
}

.noCategories {
  color: currentColor;
  padding-inline: var(--gutter);
  padding-block: var(--space-xs);
}

.noFiltersMsg {
  padding-block: var(--btn-padding-block);
  color: var(--carte);
}

@media screen and (max-width: 1024px) {
  .content, .container {
    width: 100%;
  }

  .content {
    margin-right: 4rem;
    display: none;
  }

  .container {
    transform: translateX(calc(-100% + 3rem));
  }

  .isActive {
    transform: translateX(0);
  }

  .container.isActive .content {
    display: flex;
  }

  .toggle {
    transform: rotate(180deg);
  }

  .toggleContainer {
    right: 0;
  }

  .isActive .toggleContainer {
    right: 1rem;
  }
}

.hidden {
  transform: translateX(-450px);
}


/* FilterControls.module.css */

.hasChildrenIcon {
  transition: transform 0.3s ease;
}

.expanded {
  transform: rotate(180deg);
}

.filtersReset {
  padding-top: var(--gutter);
  padding-inline: var(--gutter);
  display: flex;
}

/* Exemple de styles pour la zone contenant le champ de recherche */
.searchFormContainer {
  position: relative; 
  /* Important pour que la liste .suggestions se positionne 
     correctement en absolute par rapport à ce conteneur. */
}

/* Liste des suggestions */
.suggestions {
  position: absolute;
  top: calc(var(--text-line-height) + (var(--btn-padding-block) * 2));
  left: 0;
  right: 0;
  z-index: 9999;

  /* Style */
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: var(--white);
  border: 1px solid var(--neutral-light);
  gap: 5px;

  /* Limite la hauteur et rend scrollable au besoin */
  max-height: 200px;
  overflow-y: auto;
  padding: 5px;

  /* Scrollbar Firefox */
  scrollbar-width: var(--firefox-width);
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

/* Scrollbar base Chromium */

.suggestions::-webkit-scrollbar {
  width: var(--chromium-width);
  height: var(--chromium-height);
}

.suggestions::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

.suggestions::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border: 2px solid transparent;
}

/* Chaque élément de suggestion */
.suggestionItem {
  padding-block: var(--btn-padding-block);
  padding-inline: var(--btn-padding-inline);
  cursor: pointer;
  color: currentColor;

  /* Transition légère pour le hover */
  transition: background-color 0.2s ease;
}

/* Hover/Focus */
.suggestionItem:hover,
.suggestionItem:focus {
  background-color: var(--neutral-ultra-light); 
}

.icon {
  font-size: var(--text-l);
  width: var(--text-l);
}

/* Toggle switch */

.filterIgnore {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-xs);
  gap: var(--space-xs);
}

.switchLabel {
  display: inline-flex;
  align-items: center;
  gap: var(--space-xs);
  cursor: pointer;
  font-weight: 700;
  font-size: var(--text-l);
  line-height: var(--text-line-height);
  margin-left: auto;
}

.switchInput {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
}

.switchSlider {
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: var(--radius-circle);
  background-color: var(--neutral-ultra-light);
  transition: var(--transition);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.switchSlider::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background-color: var(--white);
  border-radius: var(--radius-circle);
  transition: var(--transition);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.switchInput:checked+.switchSlider {
  background-color: var(--carte);
}

.switchInput:checked+.switchSlider::before {
  transform: translateX(25px);
}
.pictoAccess {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    overflow: hidden;
}

.pictoAccess h3 {
    padding-inline: var(--space-s);
}

.pictoAccess iframe {
    width: 100%;
    height: auto;
    color: transparent;
}

@media screen and (max-width: 1024px) {
    .pictoAccess h3 {
        padding-inline: 0;
    }

    .pictoAccess iframe {
        max-width: 400px;
    }
}
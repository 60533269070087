.blogCard {
    background-color: var(--services-color);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: var(--white-text-color);
    transition: background-color 0.3s;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .date {
    font-size: 0.9em;
    color: #ccc;
    margin-top: -8px;
  }
  
  .excerpt {
    font-size: 14px;
    color: #fff;
    margin: 12px 0 16px;
  }
  
  /* Accessibilité */
  .redGreen {
    background-color: var(--redgreen-services-color);
    color: var(--white-text-color);
  }
  
  .blueYellow {
    background-color: #fffacd;
    color: #333;
  }
  
  .achromatopsia {
    background-color: #666;
    color: #000;
  }
  
  .blogCard:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
  
  h3 {
    font-size: var(--h3);
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .icon {
    font-size: 36px;
    margin-bottom: 12px;
    color: var(--white-text-color);
  }
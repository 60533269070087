.contactInfo {
    padding: var(--content-gap);
    background-color: var(--neutral-ultra-light);
  }
  
  .contactInfo a {
    border-bottom: 1px dotted currentColor;
  }

  .infoSection {
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
  }

  .infoItem {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
  }

  .infoItem p {
    margin: 0;
  }

  .infoItem ul {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
  }

  .infoSection h2 {
    font-size: var(--h4);
    color: var(--carte);
  }

  .thumbnail {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    object-fit: cover;
  }

  @media screen and (max-width: 1024px) {
    .thumbnail {
        aspect-ratio: 2/1;
    }
  }
.pageWrapper .headerSection {
    position: relative;
    padding-block: 0;
    margin-top: calc(var(--section-padding-block) * -1);
}

.headerSection .header {
    display: flex;
    flex-direction: column;
    padding: var(--content-gap);
    background-color: var(--white);
}

[class*="Archives_"] .offsetHeader {
    display: flex;
    flex-direction: column;
    padding-inline: var(--content-gap);
    padding-bottom: var(--content-gap);
    background-color: var(--white);
}

.headingBlock {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    width: 100%;
}

.filters {
    display: none;
    gap: var(--space-s);
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.filterList {
    display: flex;
    gap: calc(var(--space-xs)/2);
    flex-wrap: wrap;
}

.filters .label {
    font-weight: 700;
}

.filterContainer {
    display: flex;
    align-items: center;
    gap: var(--space-xs);
    flex-wrap: wrap;
}

.autoTag {
    background-color: var(--neutral-light);
    color: var(--white);
}

.filteredTag {
    background-color: var(--neutral-ultra-light);
    color: var(--neutral);
}

.autoTag,
.filteredTag {
    padding-block: var(--btn-padding-block);
    padding-inline: var(--btn-padding-inline);
    min-inline-size: var(--btn-min-width);
    line-height: var(--btn-line-height);
    border-radius: var(--radius-m);
    min-width: auto;
    display: flex;
    gap: var(--space-xs);
    cursor: pointer;
    font-size: var(--text-s);
}

.autoTag span,
.filteredTag span {
    color: currentColor;
}

.autoTag:focus,
.filteredTag:focus,
.resetTags:focus {
    outline: solid;
    outline-offset: var(--focus-offset);
    outline-color: var(--focus-color);
    outline-width: var(--focus-width);
}

.buttons {
    display: flex;
    gap: var(--space-s);
    align-items: center;
    justify-content: space-between;
}

.buttons button {
    min-width: auto;
}

.filters .resetTags,
[class*="arrow-link"].resetTags {
    font-size: var(--text-m);
    text-align: left;
}


.filterBtn {
    height: var(--text-l);
    width: var(--text-l);
}

.togglesContainer {
    width: 100%;
    display: flex;
}

.toggles {
    display: flex;
    margin-left: auto;
    gap: var(--space-xs);
}

.toggle {
    display: flex;
    gap: calc(var(--space-xs)/2);
    align-items: center;
    padding: calc(var(--space-xs)/2);
    border-radius: var(--radius-s);
    cursor: pointer;
    font-size: var(--text-m);
    transition: var(--transition);
    color: currentColor;
}

.toggle:focus {
    outline: solid;
    outline-offset: var(--focus-offset);
    outline-color: var(--focus-color);
    outline-width: var(--focus-width);
}

.active {
    background-color: var(--secondary);
    color: var(--white);
}

.icon {
    height: var(--text-l);
    width: var(--text-l);
    display: inline-block;
}

.selector {
    display: none;
    width: 100%;
    padding-block: var(--btn-padding-block);
    padding-inline: var(--btn-padding-inline);
    background-color: var(--white);
    line-height: var(--btn-line-height);
    font-size: var(--text-l);
    border-width: var(--btn-border-width);
    border-style: var(--btn-border-style);
    border-color: var(--secondary);
}

.selector option {
    line-height: var(--text-line-height);
}

.grid {
    display: grid;
    grid-template-columns: var(--grid-4);
    width: 100%;
    gap: var(--content-gap);
}

.list {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
}

.pageAssociations [class*="Card_thumbnailContainer_"] {
    background-color: var(--white);
}

.pageAssociations [class*="Card_thumbnail_"] {
    object-fit: contain;
    height: calc(100% - (var(--content-gap) * 2));
    width: calc(100% - (var(--content-gap) * 2));
    top: 50%;
    left: 50%;
    right: initial;
    bottom: initial;
    transform: translate(-50%,-50%);
}

/* Dropdown */

.viewSelect {
    background-color: var(--white);
    border: var(--btn-border-width) var(--btn-border-style) var(--neutral-ultra-light);
    padding-block: var(--btn-padding-inline);
    padding-inline: var(--btn-padding-inline) calc(var(--btn-padding-inline)*2);
    cursor: pointer;
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: var(--text-l);
    width: 100%;
}

.dropdownContainer {
    position: relative;
    display: none;
    width: 100%;
    margin-top: calc(var(--content-gap) * -1);
}

.dropdownContainer svg {
    position: absolute;
    font-size: var(--text-l);
    top: 50%;
    right: calc(var(--btn-padding-inline));
    transform: translateY(-50%);
    pointer-events: none;
    /* Empêche l'interaction avec l'élément */
    color: var(--secondary);
}

.headerDescription {
    padding-top: var(--space-s);
    padding-bottom: var(--space-xs);
    opacity: .5;
}

.headerDescription > *, 
.headerDescription strong {
    font-size: calc(var(--text-m)*1.15);
    font-weight: 400 !important;
    line-height: var(--text-line-height);
}

@media screen and (max-width: 1440px) {
    .grid {
        grid-template-columns: var(--grid-3);
    }
}

@media screen and (max-width: 1024px) {
    .grid {
        grid-template-columns: var(--grid-1);
    }

    [class*="Archives_grid"] [class*="thumbnailContainer_"],
    [class*="Archives_"] [class*="GridView_gridSection"] [class*="thumbnailContainer_"] {
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .buttons {
        flex-direction: column;
        align-items: flex-start;
    }

    .toggles {
        display: none;
    }

    .dropdownContainer {
        display: flex;
    }

    .selector {
        display: block;
    }

}
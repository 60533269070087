.comingSoonPage {
  min-height: 100vh;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.comingSoonPage .section {
  padding-block: var(--section-padding-block);
  margin: auto;
}

.comingSoonPage .metaSection {
  background-color: var(--primary);
  color: var(--white);
}

.comingSoonPage .content {
  display: grid;
  grid-template-columns: var(--grid-2);
  align-items: center;
}

.heroContent {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.heroContent img {
  width: 30rem;
  margin-bottom: var(--content-gap);
}

.heroContent .subtitle {
  font-size: var(--text-l);
}

.metaContainer {
  display: grid;
  grid-template-columns: var(--grid-3);
  gap: var(--container-gap);
  padding: 0;
}

/* --- Countdown Section --- */
.countdownSection {
  text-align: center;
  padding: var(--container-gap);
  background-color: var(--neutral-ultra-light);
  display: flex;
  flex-direction: column;
  gap: var(--content-gap);
}

.sectionTitle {
  font-size: var(--h3);
}

.countdown {
  display: flex;
  justify-content: center;
  gap: var(--content-gap);
}

.countdownItem {
  text-align: center;
  background: var(--white);
  padding: var(--space-xs);
  width: 10rem;
}

.countdownNumber {
  display: block;
  font-size: var(--h2);
  color: var(--secondary);
  font-weight: bold;
}

.countdownLabel {
  display: block;
  font-size: var(--text-s);
}

.countdownItem:last-of-type .countdownNumber {
  color: var(--secondary-light);
  }

@media screen and (max-width: 1240px) {
  .comingSoonPage .section {
    gap: var(--content-gap);
  }

  .countdownSection {
    padding-inline: var(--content-gap);
  }

  .metaContainer {
    grid-template-columns: var(--grid-2);
  }
}

@media screen and (max-width: 1024px) {
  .comingSoonPage .content {
    grid-template-columns: var(--grid-1);
    gap: var(--container-gap);
  }

  .comingSoonPage .heroContent {
    align-items: center;
  }

  .comingSoonPage .heroContent h1,
  .comingSoonPage .heroContent p {
    text-align: center;
  }

  .countdownSection {
    padding-inline: var(--container-gap);
  }
}

@media screen and (max-width: 600px) {
  .metaContainer {
    grid-template-columns: var(--grid-1);
  }

  .countdownSection {
    padding: var(--gutter);
  }

  .countdown {
    gap: var(--gutter);
    display: grid;
    grid-template-columns: var(--grid-2);
  }

  .countdownItem {
    width: 100%;
  }
}

  
  .breadcrumbs ul {
    display: flex;
    list-style-type: none;
    gap: calc(var(--space-xs));
    align-items: center;
    width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
  }

  .breadcrumbs a,
  .breadcrumbs span {
    line-height: var(--text-line-height);
    height: 100%;
    display: inline-block;
  }
  
  .breadcrumbs a {
    position: relative;
  }

  .breadcrumbs a::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: currentColor 1px solid;
    width: 0;
    transition: var(--transition);
  }

  .breadcrumbs a:hover::before {
    width: 100%;
  }

  .breadcrumbs span {
    color: var(--secondary);
  }
  
  .breadcrumbs li::after {
    content: '>';
    margin-left: calc(var(--space-xs));
    height: 100%;
    display: inline-block;
  }
  
  .breadcrumbs li:last-child::after {
    content: '';  /* Supprimer la barre après le dernier élément */
    display: none;
  }
  
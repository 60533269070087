.pdfReader {
  width: 100%;
  overflow-x: auto;
}

.pdfReader canvas {
  width: 100% !important;
  height: auto !important;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  margin: 0 1rem;
}

.reader {
  --rpv-default-layout__body-background-color: var(--white);
  --rpv-default-layout__container-border-color: var(--neutral-light);
  --rpv-default-layout__toolbar-background-color: var(--neutral-ultra-light);
  --rpv-default-layout__toolbar-border-bottom-color: var(--neutral-light);
  --rpv-default-layout__sidebar-border-color: var(--neutral-light);
  --rpv-default-layout__sidebar--opened-background-color: var(--white);
  --rpv-default-layout__sidebar-headers-background-color: var(--neutral-ultra-light);
  --rpv-default-layout__sidebar-content--opened-background-color: var(--white);
  --rpv-default-layout__sidebar-content--opened-color: var(--neutral);
  --rpv-default-layout__sidebar-content--opened-border-color: transparent;
  height: 100%;
}

.reader [class*="rpv-default-layout__toolbar"] {
  height: 4rem;
  border: 0;
}

.reader [class*="rpv-default-layout__sidebar--ltr"] {
  border: 0;
  padding-top: 4rem;
}

.reader [class*="rpv-default-layout__sidebar-headers"] {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-block: .5rem;
  padding-inline: .575rem;
}

.rpv-default-layout__sidebar--ltr .reader [class*="rpv-default-layout__body"] {
  background-color: var(--white);
  padding-top: 3rem;
}

.reader [class*="rpv-core__inner-pages"] {
  display: flex;
}
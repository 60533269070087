/* Styles communs */

.pageWrapper .pageContent {
  position: relative;
  padding-top: 0;
  margin-top: calc(var(--section-padding-block) * -1);
}

.mainSection .headerContainer {
  display: flex;
  flex-direction: column;
  padding: var(--content-gap);
  background-color: var(--white);
  gap: calc(var(--content-gap)*2);
}

.mainSection .main {
  background-color: var(--white);
  padding: var(--content-gap);
  display: grid;
  grid-template-columns: var(--grid-3-1);
}

.pageWrapper .mainSection {
  gap: 0;
}

.header {
  display: flex;
  width: 100%;
  gap: var(--content-gap);
}

.headingBlock {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  width: 100%;
}

.metaContainer {
  display: flex;
  flex-direction: column;
  gap: var(--content-gap);
}

.meta {
  display: flex;
  gap: var(--space-xs);
  align-items: center;
}

.metaIcon {
  color: var(--secondary);
}

.metaIcon svg {
  height: var(--text-xl);
  width: var(--text-xl);
  display: block;
}

.metaInfo {
  display: block;
  white-space: nowrap;
}

.badgeList {
  display: flex;
  gap: var(--space-xs);
  flex-wrap: wrap;
}

.badgeList li {
  display: flex;
}

.mainColumn {
  display: flex;
  flex-direction: column;
  gap: var(--content-gap);
}

.date {
  font-size: calc(var(--h3)*1.2);
  font-weight: 700;
}

.content :where(ul),
.content :where(ol) {
  margin: 0 0 1.2em;
  display: flex;
  flex-direction: column;
  gap: .6em;
}

.content li {
  margin-left: var(--space-m);
  padding-left: var(--space-xs);
  position: relative;
}

.content li::marker {
  color: var(--secondary);
}

.content :where(h1),
.content :where(h2),
.content :where(h3),
.content :where(h4) {
  margin: 0 0 .6em;
}

.content blockquote {
  position: relative;
  padding-left: calc(var(--space-m) + (var(--space-xs)/2));
  border-left: calc(var(--space-xs)/2) solid var(--secondary);
  margin: 0 0 .6em;
  font-style: italic;
}

.content a {
  color: var(--secondary);
  text-decoration: underline;
  transition: var(--transition);
}

.content a:hover {
  color: var(--secondary-light);
}

.content div + .pictureItem {
  margin-top: var(--content-gap);
}

.content figure[class*="media"] {
  margin-block: var(--content-gap);
}

.content figure[class*="media"] iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}

.pictures {
  display: flex;
  gap: var(--content-gap);
  flex-wrap: wrap;
}

.actions {
  display: flex;
  gap: var(--space-xs);
  flex-wrap: wrap;
}

.sidebar {
  display: flex;
  height: 100%;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
  gap: var(--content-gap);
}

.sticky {
  position: sticky;
  top: calc(var(--navbar) + var(--gutter));
}

.sticky:empty,
.content:empty {
  display: none;
}

.mobileSummary:empty {
  display: none;
}

.posterContainer {
  position: relative;
}

.poster {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.zoom {
  --focus-color: var(--secondary-light);
  position: absolute;
  width: 5rem;
  height: auto;
  aspect-ratio: 1;
  bottom: var(--gutter);
  right: var(--gutter);
  z-index: 1;
  background-color: var(--white);
  border-radius: var(--radius-circle);
  cursor: pointer;
}

.zoomIcon {
  fill: var(--secondary);
  width: 3rem;
}

.associationPage .content,
.poiPage .content {
  margin-block: var(--content-gap);
}

.nearestEvents {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .header {
    flex-direction: column;
  }

  .metaContainer {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .mainSection .main {
    grid-template-columns: var(--grid-1);
  }

  .eventPage .sidebar {
    order: -1;
  }
}
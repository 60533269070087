.link {
    --focus-color: currentColor;
    line-height: var(--text-line-height);
    position: relative;
    font-style: italic;
    display: block;
}

.link::before {
    content: '';
    position: absolute;
    background-color: currentColor;
    width: 0;
    height: 1px;
    left: 0;
    bottom: 0;
    transition: var(--transition);
}

.link:hover::before {
    width: 100%;
}

.evenements {
    color: var(--events);
}

.enfance-et-jeunesse {
    color: var(--jeunesse);
}

.votre-collectivite {
    color: var(--collectivite);
}

.seniors {
    color: var(--seniors);
}

.mobilite {
    color: var(--mobilite);
}

.services-et-demarches {
    color: var(--services);
}

.visiter-arras {
    color: var(--visiter);
}

.arras-ville-dynamique {
    color: var(--inclusive);
}

.partenaires {
    color: var(--partenaires);
}

.partenaires {
    color: var(--partenaires);
}

.numeros-d-urgence {
    color: var(--urgence);
}

.contact {
    color: var(--contact);
}
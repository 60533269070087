/* src/components/common/SocialIcons.module.css */

.container {
  position: relative;
}

.container [class*="SocialIcons_container_"] {
  transform: translateY(calc(-50% - ((var(--section-padding-block)/2))));
}

@media screen and (max-width: 1440px) {
  .container [class*="SocialIcons_container_"] {
      display: none;
  }
}
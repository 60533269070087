.section {
    background-color: var(--services);
    padding-block: 0 !important;
    color: var(--white);
    height: var(--navbar);
    justify-content: center !important;
    position: fixed !important;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500 !important;
}

.section .container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: var(--content-gap);
    align-items: center;
    width: 100%; 
}

.content {
    display: flex;
    gap: var(--content-gap);
    align-items: center;
    flex: 1;
}

.content Button {
    min-width: 15rem;
}

.content Button svg {
    display: none;
}

.icon {
    width: var(--h2);
    aspect-ratio: 1;
    display: flex;
    align-items: center;
}

/* Texte défilant */
.message {
    display: block;
    padding: 0;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 100%;
    height: var(--text-line-height);
    color: var(--white);
}

.message div {
    position: absolute;
    min-width: 100%;
    /* au minimum la largeur du conteneur */
}

.message div span,
.message div:after {
    position: relative;
    display: inline-block;
    font-size: var(--text-l);
    white-space: nowrap;
    left: var(--space-l);
}

.message div span {
    animation: defilement 20s infinite linear;
}

.message div:after {
    position: absolute;
    top: 0;
    left: var(--space-l);
    content: attr(data-text);
    animation: defilement2 20s infinite linear;
}

@keyframes defilement {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: -100%;
    }
}

@keyframes defilement2 {
    0% {
        margin-left: 100%;
    }

    100% {
        margin-left: 0%;
    }
}

@media screen and (max-width: 1024px) {
    .section {
        top: 0 !important;
        bottom: initial !important;
        height: var(--responsive-navbar);
    }

    .content Button {
        min-width: auto;
    }

    .content Button span {
        display: none;
    }

    .content Button svg {
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .icon {
        display: none;
    }
}
/* Header Styles */
.header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 9999;
  padding: var(--space-xs) var(--gutter);
  height: var(--navbar);
  background-color: var(--white);
  color: var(--neutral);
  box-shadow: 0 .5rem .5rem rgba(0,0,0,.05);
}

.headerContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: var(--space-xs);
}

.heading {
  display: flex;
  gap: var(--space-xs);
  align-items: center;
  margin-right: auto;
}

.brand, .brandSubSite {
  white-space: nowrap;
  font-size: var(--h3);
  font-weight: 700;
}

.brandSubSite {
  color: var(--primary);
  margin-left: var(--space-xs);
}

.searchForm {
  display: flex;
  align-items: center;
  width: auto;
  background-color: var(--neutral-ultra-light);
  border-radius: var(--radius-circle);
  padding-inline: var(--btn-padding-inline);
  padding-block: var(--btn-padding-block);
  gap: var(--space-xs);
}

.searchForm:focus-within {
  transition: var(--transition);
  outline-style: solid;
  outline-color: var(--focus-color);
  outline-width: var(--focus-width);
  outline-offset: var(--focus-offset);
}

.searchForm input {
  outline: none !important;
}

.searchForm button {
  cursor: pointer;
}

.searchForm svg {
  font-size: var(--text-l);
  color: var(--secondary);
}

.header [class*="ProfileButton_"],
.accessButton {
  position: relative;
}

.accessButton {
  --btn-background: transparent;
  --btn-background-hover: var(--primary-light);
  --btn-text-color: var(--primary);
  --btn-text-color-hover: var(--white);
  --btn-border-color: var(--primary);
  --btn-border-color-hover: var(--primary-light);
  --focus-color: var(--primary-light);
}

.header [class*="ProfileButton_"]:hover .icon,
.accessButton:hover .icon {
  color: var(--white);
}

.icon {
  height: var(--text-l);
  width: var(--text-l);
}

.accessibilityIcon {
  color: var(--secondary);
}

.customizeIcon {
  color: var(--white);
}

@media screen and (max-width: 1240px) {

  .header [class*="ProfileButton_"],
  .accessButton {
    min-width: auto;
  }

  .header [class*="ProfileButton_"] .buttonLabel,
  .accessButton .buttonLabel {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .searchForm {
    /* Solution temporaire */
    display: none;
  }
}

/* Header.module.css */

/* Styles existants ... */

/* Styles pour la liste des suggestions */
.suggestionsList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: none;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000; /* Assurez-vous que la liste est au-dessus des autres éléments */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.suggestionItem {
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.suggestionItem:hover,
.suggestionItem:focus {
  background-color: #f0f0f0;
}

.suggestionLabel {
  font-size: 16px;
  color: #333;
}

.suggestionType {
  font-size: 14px;
  color: #888;
}

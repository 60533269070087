.hero {
  padding: 0;
  position: relative;
  gap: 0 !important;
}

.heroMedia {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  padding-inline: calc(var(--gutter)/2);
  height: calc(var(--widget-offset) + (var(--section-space-l)/2) + var(--container-gap));
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heroVideo, .heroVideo video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.widgetSection {
  padding-top: 0;
  padding-inline: var(--gutter);
  margin-top: calc(((var(--section-space-l)/2) + var(--container-gap)) * -1);
}

.widgetContainer {

  background-color: var(--white);
  padding-inline: var(--gutter);
}

.widgetWrapper {
  padding: var(--container-gap);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--content-gap);
}

.searchContainer {
  display: flex;
  flex-direction: row;
  gap: var(--container-gap);
  align-items: center;
}

.searchContainer img {
  height: var(--section-space-l);
  width: auto;
  object-fit: contain;
  max-width: 20rem;
}

.searchContainer h1 {
  position: absolute;
  top: -99999px;
}

.searchContainer h2 {
  position: absolute;
  top: -99999px;
}

@media screen and (max-width: 1024px) {
  .widgetSection {
    margin-top: calc(((var(--heading-line-height)*2) + (var(--text-line-height)*2) + (var(--gutter)*2)) * -1);
  }

  .widgetContainer {
    padding-inline: 0;
  }

  .widgetWrapper {
    padding: var(--gutter);
  }

  .searchContainer {
    flex-direction: column;
    gap: var(--content-gap);
  }

  .searchContainer img {
    display: none;
  }

  .searchContainer h2 {
    position: static;
    width: 100%;
    text-align: left;
  }

  .heroMedia {
    height: calc(100vh - var(--responsive-navbar));
    min-height: 40rem;
    padding-inline: 0;
  }
}

@media screen and (max-height: 45rem),
(max-width: 1440px) {
  .hero [class*="SocialIcons_container_"] {
    display: none;
  }
}
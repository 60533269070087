.searchContainer {
  padding: var(--gutter);
  position: relative;
}

.searchForm {
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  background-color: var(--neutral-ultra-light);
  border-radius: var(--radius-circle);
  padding-inline: var(--btn-padding-inline);
  padding-block: var(--btn-padding-block);
  gap: var(--space-xs);
}

.searchForm:focus-within {
  transition: var(--transition);
  outline-style: solid;
  outline-color: var(--focus-color);
  outline-width: var(--focus-width);
  outline-offset: var(--focus-offset);
}

.searchForm input {
  outline: none !important;
  width: 100%;
}

.searchForm button {
  cursor: pointer;
}


.searchForm svg {
  font-size: var(--text-l);
  color: var(--secondary);
}

/* Styles pour la liste des suggestions */
.suggestionsList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--white);
  max-height: calc((var(--text-line-height) + (var(--btn-padding-block)*2)) * 4);
  overflow-y: auto;
  z-index: 1000;
  /* Assurez-vous que la liste est au-dessus des autres éléments */
}

.suggestionItem {
  padding-inline: var(--btn-padding-inline);
  padding-block: var(--btn-padding-block);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-xs);
}

.suggestionItem:hover,
.suggestionItem:focus {
  background-color: var(--neutral-ultra-light);
}

.suggestionLabel {
  font-size: var(--text-m);
  color: currentColor;
}

.suggestionType {
  font-size: var(--text-s);
  color: currentColor;
  opacity: .75;
}

/* Styles pour le message de chargement */
.loading {
  margin-top: 5px;
  font-size: var(--text-s);
  color: currentColor;
  opacity: .75;
}
.burgerButton {
  --btn-background: var(--primary);
  --btn-background-hover: var(--primary-light);
  --btn-text-color: var(--white);
  --btn-text-color-hover: var(--white);
  --btn-border-color: var(--primary);
  --btn-border-color-hover: var(--primary-light);
  --focus-color: var(--primary-light);
  min-width: auto;
  position: relative;
  background-color: var(--btn-background);
  color: var(--btn-text-color);
  border-color: var(--btn-border-color);
}

.burgerButton:hover {
  background-color: var(--btn-background-hover);
  border-color: var(--btn-border-color-hover);
  color: var(--btn-text-color-hover);
}

.burger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: var(--text-line-height);
  aspect-ratio: 2/3;
  gap: .4rem;
  cursor: pointer;
  outline: none;
  /* Supprime le focus par défaut */
}

.burger span {
  text-wrap: var(--text-text-wrap);
  line-height: var(--text-line-height);
}

.line {
  width: 100%;
  height: .2rem;
  background-color: currentColor;
}
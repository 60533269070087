:root {
  /* General */
  --root-font-size: 62.5%;
  --default-font-size: 16px;
  --transition: all .2s ease-out;
  --navbar: 7.45rem;
  --responsive-navbar: 5.5rem;

  /* Offset */
  --widget-offset: calc(60vh - var(--navbar));
  --responsive-widget-offset: 60vh;

  /* Accessiility focus */
  --focus-color: var(--secondary);
  --focus-width: 0.2rem;
  --focus-offset: 0.25em;

  /* Radius */
  --radius: 1rem;
  --radius-50: 50%;
  --radius-circle: 50vw;
  --radius-none: 0;
  --radius-xs: 0.4444444444rem;
  --radius-s: 0.6666666667rem;
  --radius-m: 1rem;
  --radius-l: 1.5rem;
  --radius-xl: 2.25rem;
  --radius-xxl: 3.375rem;

  /* Buttons */
  --btn-padding-block: 0.5em;
  --btn-padding-inline: 1.25em;
  --btn-min-width: 14rem;
  --btn-line-height: var(--text-line-height);
  --btn-font-weight: 400;
  --btn-font-style: normal;
  --btn-text-decoration: none;
  --btn-text-decoration-hover: none;
  --btn-letter-spacing: 0.5px;
  --btn-text-transform: none;
  --btn-border-width: 1px;
  --btn-outline-border-width: 0.25rem;
  --btn-border-style: solid;
  --btn-border-radius: 0;
  --btn-justify-content: center;
  --btn-text-align: center;
  --btn-transition: var(--transition);
  --btn-radius: var(--radius-circle);

  /* Buttons Hover */
  --primary-hover: var(--primary-light);
  --secondary-hover: var(--secondary-light);
  --neutral-hover: var(--neutral-light);
  --white-hover: var(--neutral-ultra-light);

  /* Scrollbars */

  --scrollbar-thumb: currentColor;
  --scrollbar-track: rgba(0, 0, 0, .1);
  --firefox-width: thin;
  --chromium-width: 12px;
  --chromium-height: 12px;

  /* Variables de grilles */
  --grid-1: repeat(1, minmax(0, 1fr));
  --grid-2: repeat(2, minmax(0, 1fr));
  --grid-3: repeat(3, minmax(0, 1fr));
  --grid-4: repeat(4, minmax(0, 1fr));
  --grid-5: repeat(5, minmax(0, 1fr));
  --grid-6: repeat(6, minmax(0, 1fr));
  --grid-7: repeat(7, minmax(0, 1fr));
  --grid-8: repeat(8, minmax(0, 1fr));
  --grid-9: repeat(9, minmax(0, 1fr));
  --grid-10: repeat(10, minmax(0, 1fr));
  --grid-11: repeat(11, minmax(0, 1fr));
  --grid-12: repeat(12, minmax(0, 1fr));
  --grid-1-2: minmax(0, 1fr) minmax(0, 2fr);
  --grid-1-3: minmax(0, 1fr) minmax(0, 3fr);
  --grid-1-4: minmax(0, 1fr) minmax(0, 4fr);
  --grid-1-5: minmax(0, 1fr) minmax(0, 5fr);
  --grid-2-1: minmax(0, 2fr) minmax(0, 1fr);
  --grid-2-3: minmax(0, 2fr) minmax(0, 3fr);
  --grid-2-4: minmax(0, 2fr) minmax(0, 4fr);
  --grid-2-5: minmax(0, 2fr) minmax(0, 5fr);
  --grid-3-1: minmax(0, 3fr) minmax(0, 1fr);
  --grid-3-2: minmax(0, 3fr) minmax(0, 2fr);
  --grid-3-4: minmax(0, 3fr) minmax(0, 4fr);
  --grid-3-5: minmax(0, 3fr) minmax(0, 5fr);
  --grid-4-1: minmax(0, 4fr) minmax(0, 1fr);
  --grid-4-2: minmax(0, 4fr) minmax(0, 2fr);
  --grid-4-3: minmax(0, 4fr) minmax(0, 3fr);
  --grid-4-5: minmax(0, 4fr) minmax(0, 5fr);
  --grid-5-1: minmax(0, 5fr) minmax(0, 1fr);
  --grid-5-2: minmax(0, 5fr) minmax(0, 2fr);
  --grid-5-3: minmax(0, 5fr) minmax(0, 3fr);
  --grid-5-4: minmax(0, 5fr) minmax(0, 4fr);
}

:root {
  /* Variables de font fallback pour les navigateurs anciens */

  --text-line-height: calc(6px + 2.5ex);
  --heading-line-height: calc(4px + 2.25ex);

  --text-text-wrap: pretty;
  --heading-text-wrap: wrap;

  --gutter: 2.4rem;
  --text-xs: 1.26rem;
  --text-s: 1.42rem;
  --text-m: 1.8rem;
  --text-l: 2.025rem;
  --text-xl: 2.28rem;
  --text-xxl: 2.565rem;

  --h6: 1.26rem;
  --h5: 1.42rem;
  --h4: 1.8rem;
  --h3: 2.025rem;
  --h2: 2.28rem;
  --h1: 2.565rem;

  --featured-heading: calc(var(--h3)*1.25);
}

/* Variables de font fluides */

@supports (font-size: calc(1vw + 2vw)) {
  :root {
    --text-xs: calc(1.1rem + (0 * ((100vw - 32rem) / 112)));
    --text-s: calc(1.2rem + (0 * ((100vw - 32rem) / 112)));
    --text-m: calc(1.4rem + (0.2 * ((100vw - 32rem) / 112)));
    --text-l: calc(1.68rem + (0.4528 * ((100vw - 32rem) / 112)));
    --text-xl: calc(2.016rem + (0.8270224 * ((100vw - 32rem) / 112)));
    --text-xxl: calc(2.4192rem + (1.3705488592 * ((100vw - 32rem) / 112)));
    --h6: calc(1.1rem + (0 * ((100vw - 32rem) / 112)));
    --h5: calc(1.2rem + (0 * ((100vw - 32rem) / 112)));
    --h4: calc(1.6rem + (0.4 * ((100vw - 32rem) / 112)));
    --h3: calc(1.8rem + (0.6 * ((100vw - 32rem) / 112)));
    --h2: calc(2.4rem + (2.6 * ((100vw - 32rem) / 112)));
    --h1: calc(3rem + (3 * ((100vw - 32rem) / 112)));
  }

  @media (min-width: 1981px) {
    :root {
      --text-xs: 1.1rem;
      --text-s: 1.2rem;
      --text-m: 1.6rem;
      --text-l: 2.1328rem;
      --text-xl: 2.8430224rem;
      --text-xxl: 3.7897488592rem;
      --h6: 1.1rem;
      --h5: 1.2rem;
      --h4: 2rem;
      --h3: 2.4rem;
      --h2: 5rem;
      --h1: 6rem;
    }
  }
}

@supports (font-size: clamp(1vw, 2vw, 3vw)) {
  :root {
    --text-xs: clamp(1.1rem, calc(0vw + 1.1rem), 1.1rem);
    --text-s: clamp(1.2rem, calc(0vw + 1.2rem), 1.2rem);
    --text-m: clamp(1.4rem, calc(0.1785714286vw + 1.3428571429rem), 1.6rem);
    --text-l: clamp(1.68rem, calc(0.4042857143vw + 1.5506285714rem), 2.1328rem);
    --text-xl: clamp(2.016rem, calc(0.7384128571vw + 1.7797078857rem), 2.8430224rem);
    --text-xxl: clamp(2.4192rem, calc(1.2237043386vw + 2.0276146117rem), 3.7897488592rem);
    --h6: clamp(1.1rem, calc(0vw + 1.1rem), 1.1rem);
    --h5: clamp(1.2rem, calc(0vw + 1.2rem), 1.2rem);
    --h4: clamp(1.6rem, calc(0.3571428571vw + 1.4857142857rem), 2rem);
    --h3: clamp(1.8rem, calc(0.5357142857vw + 1.6285714286rem), 2.4rem);
    --h2: clamp(2.4rem, calc(2.3214285714vw + 1.6571428571rem), 5rem);
    --h1: clamp(3rem, calc(2.6785714286vw + 2.1428571429rem), 6rem);
  }
}

/* Spacing */

:root {
  --content-gap: var(--space-m);
  --container-gap: var(--space-xl);
  --grid-gap: var(--space-m);

  --section-padding-block: var(--section-space-s);

  --space-xs: 1.896rem;
  --space-s: 2.133rem;
  --space-m: 2.4rem;
  --space-l: 2.7rem;
  --space-xl: 3.038rem;
  --space-xxl: 3.42rem;

  --section-space-xs: 3.84rem;
  --section-space-s: 4.8rem;
  --section-space-m: 6rem;
  --section-space-l: 7.5rem;
  --section-space-xl: 9.375rem;
  --section-space-xxl: 11.719rem;

}

@supports (padding: calc(1vw + 2vw)) {
  :root {
    --space-xs: calc(1.0666666667rem + (0.2666666667 * ((100vw - 32rem) / 112)));
    --space-s: calc(1.6rem + (0.4 * ((100vw - 32rem) / 112)));
    --space-m: calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    --space-l: calc(3.1992rem + (1.3008 * ((100vw - 32rem) / 112)));
    --space-xl: calc(4.2645336rem + (2.4854664 * ((100vw - 32rem) / 112)));
    --space-xxl: calc(5.6846232888rem + (4.4403767112 * ((100vw - 32rem) / 112)));
    --section-space-xs: calc(3.2rem + (2.1333333333 * ((100vw - 32rem) / 112)));
    --section-space-s: calc(4.8rem + (3.2 * ((100vw - 32rem) / 112)));
    --section-space-m: calc(7.2rem + (4.8 * ((100vw - 32rem) / 112)));
    --section-space-l: calc(9.5976rem + (8.4024 * ((100vw - 32rem) / 112)));
    --section-space-xl: calc(12.7936008rem + (14.2063992 * ((100vw - 32rem) / 112)));
    --section-space-xxl: calc(17.0538698664rem + (23.4461301336 * ((100vw - 32rem) / 112)));
    --section-padding-x: calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
    --gutter: calc(2.4rem + (0.6 * ((100vw - 32rem) / 112)));
  }

  @media (min-width: 1981px) {
    :root {
      --space-xs: 1.3333333333rem;
      --space-s: 2rem;
      --space-m: 3rem;
      --space-l: 4.5rem;
      --space-xl: 6.75rem;
      --space-xxl: 10.125rem;
      --section-space-xs: 5.3333333333rem;
      --section-space-s: 8rem;
      --section-space-m: 12rem;
      --section-space-l: 18rem;
      --section-space-xl: 27rem;
      --section-space-xxl: 40.5rem;
      --section-padding-x: 3rem;
      --gutter: 3rem;
    }
  }
}

@supports (padding: clamp(1vw, 2vw, 3vw)) {
  :root {
    --space-xs: clamp(1.0666666667rem, calc(0.2380952381vw + 0.9904761905rem), 1.3333333333rem);
    --space-s: clamp(1.6rem, calc(0.3571428571vw + 1.4857142857rem), 2rem);
    --space-m: clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
    --space-l: clamp(3.1992rem, calc(1.1614285714vw + 2.8275428571rem), 4.5rem);
    --space-xl: clamp(4.2645336rem, calc(2.2191664286vw + 3.5544003429rem), 6.75rem);
    --space-xxl: clamp(5.6846232888rem, calc(3.9646220636vw + 4.4159442285rem), 10.125rem);
    --section-space-xs: clamp(3.2rem, calc(1.9047619048vw + 2.5904761905rem), 5.3333333333rem);
    --section-space-s: clamp(4.8rem, calc(2.8571428571vw + 3.8857142857rem), 8rem);
    --section-space-m: clamp(7.2rem, calc(4.2857142857vw + 5.8285714286rem), 12rem);
    --section-space-l: clamp(9.5976rem, calc(7.5021428571vw + 7.1969142857rem), 18rem);
    --section-space-xl: clamp(12.7936008rem, calc(12.684285vw + 8.7346296rem), 27rem);
    --section-space-xxl: clamp(17.0538698664rem, calc(20.9340447621vw + 10.3549755425rem), 40.5rem);
    --section-padding-x: clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
    --gutter: clamp(2.4rem, calc(0.5357142857vw + 2.2285714286rem), 3rem);
  }
}
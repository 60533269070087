.pageType {
    opacity: 0;
    transform: translateY(20px);  /* Déplacer légèrement vers le bas */
    transition: opacity 0.5s ease, transform 0.5s ease;  /* Animation d'apparition */
  }
  
  .pageType.loaded {
    opacity: 1;
    transform: translateY(0);  /* Remonter à sa position initiale */
  }

  .pageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  
  .thumbnail {
    width: 100%;
    max-width: 600px;
    height: auto;
    margin: 20px 0;
  }
  
  .textBlock {
    margin: 20px 0;
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
/* src/components/common/Banner.module.css */

.bannerContainer {
    width: 100%;
    overflow: hidden;     /* Assure que l'image reste dans le conteneur */
    padding-inline: calc(var(--gutter)/2);
  }
  
  .bannerImage {
    width: 100%;
    height: auto;         /* Conserve le ratio de l'image */
    max-height: 40rem;    /* Hauteur maximale, ajustable selon vos besoins */
    object-fit: cover;    /* Couvre le conteneur en coupant les bords si nécessaire */
  }
  
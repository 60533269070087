.content {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
}

.content p br {
    margin-bottom: 1.2em;
}

.content p br:last-of-type {
    margin-bottom: 0;
}

.content ul {
    padding: var(--content-gap);
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    background-color: var(--neutral-ultra-light);
    margin-block: var(--space-xs);
}

.content a {
    color: var(--secondary);
    text-decoration: underline dotted;
}

.content h2 {
    font-size: var(--h3);
}

.content h3 {
    font-size: var(--h4);
}
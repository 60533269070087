/* Global */

body .main-wrapper.redGreen,
body .main-wrapper.blueYellow,
body .main-wrapper.blackWhite {
    color: var(--neutral);
    background-color: var(--neutral-ultra-light);
}

body.dark,
body .main-wrapper.dark {
    color: var(--white);
    background-color: var(--neutral-dark);
}

.redGreen {
    --focus-color: var(--red-green-secondary);
}

.blueYellow {
    --focus-color: var(--blue-yellow-secondary);
}

.blackWhite {
    --focus-color: var(--black-white-secondary);
}

.dark {
    --focus-color: var(--dark-secondary);
}

.dark input {
    color: var(--white);
}

.redGreen .arrow-link {
    color: var(--red-green-secondary);
}

.blueYellow .arrow-link {
    color: var(--blue-yellow-secondary);
}

.blackWhite .arrow-link {
    color: var(--black-white-secondary);
}

.dark .arrow-link {
    color: var(--dark-secondary);
}

.redGreen .arrow-link:hover {
    color: var(--red-green-secondary-light);
}

.blueYellow .arrow-link:hover {
    color: var(--blue-yellow-secondary-light);
}

.blackWhite .arrow-link:hover {
    color: var(--black-white-secondary-light);
}

.dark .arrow-link:hover {
    color: var(--dark-secondary-light);
}

.redGreen .separatorLine {
    background-color: var(--red-green-secondary);
}

.blueYellow .separatorLine {
    background-color: var(--blue-yellow-secondary);
}

.blackWhite .separatorLine {
    background-color: var(--black-white-secondary);
}

.dark .separatorLine {
    background-color: var(--dark-secondary);
}

/* Categories */

.redGreen [class*="Categories_"][class*="evenements_"] {
    background-color: var(--red-green-events);
    color: var(--white);
}

.blueYellow [class*="Categories_"][class*="evenements_"] {
    background-color: var(--blue-yellow-events);
    color: var(--white);
}

.blackWhite [class*="Categories_"][class*="evenements_"] {
    background-color: var(--black-white-events);
    color: var(--neutral);
}

.dark [class*="Categories_"][class*="evenements_"] {
    background-color: var(--dark-events);
    color: var(--white);
}

.redGreen [class*="Categories_"][class*="enfance-et-jeunesse_"] {
    background-color: var(--red-green-jeunesse);
    color: var(--white);
}

.blueYellow [class*="Categories_"][class*="enfance-et-jeunesse_"] {
    background-color: var(--blue-yellow-jeunesse);
    color: var(--white);
}

.blackWhite [class*="Categories_"][class*="enfance-et-jeunesse_"] {
    background-color: var(--black-white-jeunesse);
    color: var(--neutral);
}

.dark [class*="Categories_"][class*="enfance-et-jeunesse_"] {
    background-color: var(--dark-jeunesse);
    color: var(--white);
}

.redGreen [class*="Categories_"][class*="votre-collectivite_"] {
    background-color: var(--red-green-collectivite);
    color: var(--white);
}

.blueYellow [class*="Categories_"][class*="votre-collectivite_"] {
    background-color: var(--blue-yellow-collectivite);
    color: var(--white);
}

.blackWhite [class*="Categories_"][class*="votre-collectivite_"] {
    background-color: var(--black-white-collectivite);
    color: var(--white);
}

.dark [class*="Categories_"][class*="votre-collectivite_"] {
    background-color: var(--dark-collectivite);
    color: var(--white);
}

.redGreen [class*="Categories_"][class*="seniors_"] {
    background-color: var(--red-green-seniors);
    color: var(--white);
}

.blueYellow [class*="Categories_"][class*="seniors_"] {
    background-color: var(--blue-yellow-seniors);
    color: var(--white);
}

.blackWhite [class*="Categories_"][class*="seniors_"] {
    background-color: var(--black-white-seniors);
    color: var(--white);
}

.dark [class*="Categories_"][class*="seniors_"] {
    background-color: var(--dark-seniors);
    color: var(--white);
}

.redGreen [class*="Categories_"][class*="mobilite_"] {
    background-color: var(--red-green-mobilite);
    color: var(--white);
}

.blueYellow [class*="Categories_"][class*="mobilite_"] {
    background-color: var(--blue-yellow-mobilite);
    color: var(--white);
}

.blackWhite [class*="Categories_"][class*="mobilite_"] {
    background-color: var(--black-white-mobilite);
    color: var(--white);
}

.dark [class*="Categories_"][class*="mobilite_"] {
    background-color: var(--dark-mobilite);
    color: var(--white);
}

.redGreen [class*="Categories_"][class*="services"] {
    background-color: var(--red-green-services);
    color: var(--white);
}

.blueYellow [class*="Categories_"][class*="services"] {
    background-color: var(--blue-yellow-services);
    color: var(--white);
}

.blackWhite [class*="Categories_"][class*="services"] {
    background-color: var(--black-white-services);
    color: var(--white);
}

.dark [class*="Categories_"][class*="services"] {
    background-color: var(--dark-services);
    color: var(--white);
}

.redGreen [class*="Categories_"][class*="visiter-arras_"] {
    background-color: var(--red-green-visiter);
    color: var(--white);
}

.blueYellow [class*="Categories_"][class*="visiter-arras_"] {
    background-color: var(--blue-yellow-visiter);
    color: var(--white);
}

.blackWhite [class*="Categories_"][class*="visiter-arras_"] {
    background-color: var(--black-white-visiter);
    color: var(--white);
}

.dark [class*="Categories_"][class*="visiter-arras_"] {
    background-color: var(--dark-visiter);
    color: var(--white);
}

.redGreen [class*="Categories_"][class*="arras-ville-dynamique_"] {
    background-color: var(--red-green-inclusive);
    color: var(--white);
}

.blueYellow [class*="Categories_"][class*="arras-ville-dynamique_"] {
    background-color: var(--blue-yellow-inclusive);
    color: var(--white);
}

.blackWhite [class*="Categories_"][class*="arras-ville-dynamique_"] {
    background-color: var(--black-white-inclusive);
    color: var(--neutral);
}

.dark [class*="Categories_"][class*="arras-ville-dynamique_"] {
    background-color: var(--dark-inclusive);
    color: var(--white);
}

.redGreen [class*="Categories_"][class*="partenaires_"] {
    background-color: var(--red-green-partenaires);
    color: var(--white);
}

.blueYellow [class*="Categories_"][class*="partenaires_"] {
    background-color: var(--blue-yellow-partenaires);
    color: var(--white);
}

.blackWhite [class*="Categories_"][class*="partenaires_"] {
    background-color: var(--black-white-partenaires);
    color: var(--white);
}

.dark [class*="Categories_"][class*="partenaires_"] {
    background-color: var(--dark-partenaires);
    color: var(--white);
}

.redGreen [class*="Categories_"][class*="partenaires_"] {
    background-color: var(--red-green-partenaires);
    color: var(--white);
}

.blueYellow [class*="Categories_"][class*="partenaires_"] {
    background-color: var(--blue-yellow-partenaires);
    color: var(--white);
}

.blackWhite [class*="Categories_"][class*="partenaires_"] {
    background-color: var(--black-white-partenaires);
    color: var(--white);
}

.dark [class*="Categories_"][class*="partenaires_"] {
    background-color: var(--dark-partenaires);
    color: var(--white);
}

.redGreen [class*="Categories_"][class*="numeros-d-urgence_"] {
    background-color: var(--red-green-urgence);
    color: var(--white);
}

.blueYellow [class*="Categories_"][class*="numeros-d-urgence_"] {
    background-color: var(--blue-yellow-urgence);
    color: var(--white);
}

.blackWhite [class*="Categories_"][class*="numeros-d-urgence_"] {
    background-color: var(--black-white-urgence);
    color: var(--white);
}

.dark [class*="Categories_"][class*="numeros-d-urgence_"] {
    background-color: var(--dark-urgence);
    color: var(--white);
}

.redGreen [class*="Categories_"][class*="contact_"] {
    background-color: var(--red-green-contact);
    color: var(--neutral);
}

.blueYellow [class*="Categories_"][class*="contact_"] {
    background-color: var(--blue-yellow-contact);
    color: var(--neutral);
}

.blackWhite [class*="Categories_"][class*="contact_"] {
    background-color: var(--black-white-contact);
    color: var(--neutral);
}

.dark [class*="Categories_"][class*="contact_"] {
    background-color: var(--dark-contact);
    color: var(--white);
}

/* Buttons */

.redGreen [class*="Button_neutral"] {
    --focus-color: var(--red-green-primary);
}

.blueYellow [class*="Button_neutral"] {
    --focus-color: var(--blue-yellow-primary);
}

.blackWhite [class*="Button_neutral"] {
    --focus-color: var(--black-white-primary);
}

.dark [class*="Button_neutral"] {
    --focus-color: var(--dark-primary);
}

.redGreen [class*="Button_primary"],
.redGreen [class*="ProfileButton_citoyen_"] {
    --btn-background: var(--red-green-primary);
    --btn-background-hover: var(--red-green-primary-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--red-green-primary);
    --btn-border-color-hover: var(--red-green-primary-light);
    --focus-color: var(--red-green-primary-light);
}

.blueYellow [class*="Button_primary"],
.blueYellow [class*="ProfileButton_citoyen_"] {
    --btn-background: var(--blue-yellow-primary);
    --btn-background-hover: var(--blue-yellow-primary-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--blue-yellow-primary);
    --btn-border-color-hover: var(--blue-yellow-primary-light);
    --focus-color: var(--blue-yellow-primary-light);
}

.blackWhite [class*="Button_primary"],
.blackWhite [class*="ProfileButton_citoyen_"] {
    --btn-background: var(--black-white-primary);
    --btn-background-hover: var(--black-white-primary-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--black-white-primary);
    --btn-border-color-hover: var(--black-white-primary-light);
    --focus-color: var(--black-white-primary-light);
}

.dark [class*="Button_primary"],
.dark [class*="ProfileButton_citoyen_"] {
    --btn-background: var(--dark-primary);
    --btn-background-hover: var(--dark-primary-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--dark-primary);
    --btn-border-color-hover: var(--dark-primary-light);
    --focus-color: var(--dark-primary-light);
}

.redGreen [class*="Button_secondary"] {
    --btn-background: var(--red-green-secondary);
    --btn-background-hover: var(--red-green-secondary-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--red-green-secondary);
    --btn-border-color-hover: var(--red-green-secondary-light);
    --focus-color: var(--red-green-secondary-light);
}

.blueYellow [class*="Button_secondary"] {
    --btn-background: var(--blue-yellow-secondary);
    --btn-background-hover: var(--blue-yellow-secondary-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--blue-yellow-secondary);
    --btn-border-color-hover: var(--blue-yellow-secondary-light);
    --focus-color: var(--blue-yellow-secondary-light);
}

.blackWhite [class*="Button_secondary"] {
    --btn-background: var(--black-white-secondary);
    --btn-background-hover: var(--black-white-secondary-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--black-white-secondary);
    --btn-border-color-hover: var(--black-white-secondary-light);
    --focus-color: var(--black-white-secondary-light);
}

.dark [class*="Button_secondary"] {
    --btn-background: var(--dark-secondary);
    --btn-background-hover: var(--dark-secondary-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--dark-secondary);
    --btn-border-color-hover: var(--dark-secondary-light);
    --focus-color: var(--dark-secondary-light);
}

.redGreen [class*="ProfileButton_touriste_"] {
    --btn-background: var(--red-green-visiter);
    --btn-background-hover: var(--red-green-visiter-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--red-green-visiter);
    --btn-border-color-hover: var(--red-green-visiter-light);
    --focus-color: var(--red-green-visiter-light);
}

.blueYellow [class*="ProfileButton_touriste_"] {
    --btn-background: var(--blue-yellow-visiter);
    --btn-background-hover: var(--blue-yellow-visiter-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--blue-yellow-visiter);
    --btn-border-color-hover: var(--blue-yellow-visiter-light);
    --focus-color: var(--blue-yellow-visiter-light);
}

.blackWhite [class*="ProfileButton_touriste_"] {
    --btn-background: var(--black-white-visiter);
    --btn-background-hover: var(--black-white-visiter-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--black-white-visiter);
    --btn-border-color-hover: var(--black-white-visiter-light);
    --focus-color: var(--black-white-visiter-light);
}

.dark [class*="ProfileButton_touriste_"] {
    --btn-background: var(--dark-visiter);
    --btn-background-hover: var(--dark-visiter-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--dark-visiter);
    --btn-border-color-hover: var(--dark-visiter-light);
    --focus-color: var(--dark-visiter-light);
}

.redGreen [class*="ProfileButton_jeune_"] {
    --btn-background: var(--red-green-jeunesse);
    --btn-background-hover: var(--red-green-jeunesse-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--red-green-jeunesse);
    --btn-border-color-hover: var(--red-green-jeunesse-light);
    --focus-color: var(--red-green-jeunesse-light);
}

.blueYellow [class*="ProfileButton_jeune_"] {
    --btn-background: var(--blue-yellow-jeunesse);
    --btn-background-hover: var(--blue-yellow-jeunesse-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--blue-yellow-jeunesse);
    --btn-border-color-hover: var(--blue-yellow-jeunesse-light);
    --focus-color: var(--blue-yellow-jeunesse-light);
}

.blackWhite [class*="ProfileButton_jeune_"] {
    --btn-background: var(--black-white-jeunesse);
    --btn-background-hover: var(--black-white-jeunesse-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--black-white-jeunesse);
    --btn-border-color-hover: var(--black-white-jeunesse-light);
    --focus-color: var(--black-white-jeunesse-light);
}

.dark [class*="ProfileButton_jeune_"] {
    --btn-background: var(--dark-jeunesse);
    --btn-background-hover: var(--dark-jeunesse-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--dark-jeunesse);
    --btn-border-color-hover: var(--dark-jeunesse-light);
    --focus-color: var(--dark-jeunesse-light);
}

.redGreen [class*="ProfileButton_senior_"] {
    --btn-background: var(--red-green-seniors);
    --btn-background-hover: var(--red-green-seniors-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--red-green-seniors);
    --btn-border-color-hover: var(--red-green-seniors-light);
    --focus-color: var(--red-green-seniors-light);
}

.blueYellow [class*="ProfileButton_senior_"] {
    --btn-background: var(--blue-yellow-seniors);
    --btn-background-hover: var(--blue-yellow-seniors-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--blue-yellow-seniors);
    --btn-border-color-hover: var(--blue-yellow-seniors-light);
    --focus-color: var(--blue-yellow-seniors-light);
}

.blackWhite [class*="ProfileButton_senior_"] {
    --btn-background: var(--black-white-seniors);
    --btn-background-hover: var(--black-white-seniors-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--black-white-seniors);
    --btn-border-color-hover: var(--black-white-seniors-light);
    --focus-color: var(--black-white-seniors-light);
}

.dark [class*="ProfileButton_senior_"] {
    --btn-background: var(--dark-seniors);
    --btn-background-hover: var(--dark-seniors-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--dark-seniors);
    --btn-border-color-hover: var(--dark-seniors-light);
    --focus-color: var(--dark-seniors-light);
}

.redGreen [class*="ProfileButton_parent_"] {
    --btn-background: var(--red-green-collectivite);
    --btn-background-hover: var(--red-green-collectivite-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--red-green-collectivite);
    --btn-border-color-hover: var(--red-green-collectivite-light);
    --focus-color: var(--red-green-collectivite-light);
}

.blueYellow [class*="ProfileButton_parent_"] {
    --btn-background: var(--blue-yellow-collectivite);
    --btn-background-hover: var(--blue-yellow-collectivite-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--blue-yellow-collectivite);
    --btn-border-color-hover: var(--blue-yellow-collectivite-light);
    --focus-color: var(--blue-yellow-collectivite-light);
}

.blackWhite [class*="ProfileButton_parent_"] {
    --btn-background: var(--black-white-collectivite);
    --btn-background-hover: var(--black-white-collectivite-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--black-white-collectivite);
    --btn-border-color-hover: var(--black-white-collectivite-light);
    --focus-color: var(--black-white-collectivite-light);
}

.dark [class*="ProfileButton_parent_"] {
    --btn-background: var(--dark-collectivite);
    --btn-background-hover: var(--dark-collectivite-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--dark-collectivite);
    --btn-border-color-hover: var(--dark-collectivite-light);
    --focus-color: var(--dark-collectivite-light);
}

.redGreen [class*="ProfileButton_professionnel_"] {
    --btn-background: var(--red-green-partenaires);
    --btn-background-hover: var(--red-green-partenaires-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--red-green-partenaires);
    --btn-border-color-hover: var(--red-green-partenaires-light);
    --focus-color: var(--red-green-partenaires-light);
}

.blueYellow [class*="ProfileButton_professionnel_"] {
    --btn-background: var(--blue-yellow-partenaires);
    --btn-background-hover: var(--blue-yellow-partenaires-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--blue-yellow-partenaires);
    --btn-border-color-hover: var(--blue-yellow-partenaires-light);
    --focus-color: var(--blue-yellow-partenaires-light);
}

.blackWhite [class*="ProfileButton_professionnel_"] {
    --btn-background: var(--black-white-partenaires);
    --btn-background-hover: var(--black-white-partenaires-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--black-white-partenaires);
    --btn-border-color-hover: var(--black-white-partenaires-light);
    --focus-color: var(--black-white-partenaires-light);
}

.dark [class*="ProfileButton_professionnel_"] {
    --btn-background: var(--dark-partenaires);
    --btn-background-hover: var(--dark-partenaires-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--dark-partenaires);
    --btn-border-color-hover: var(--dark-partenaires-light);
    --focus-color: var(--dark-partenaires-light);
}

.redGreen [class*="LoadMoreButton_"] {
    color: var(--red-green-secondary);
}

.redGreen [class*="LoadMoreButton_"]:hover {
    background-color: var(--red-green-secondary);
    border-color: var(--red-green-secondary);
}

.redGreen [class*="LoadMoreButton_"] [class*="icon_"] {
    background-color: var(--red-green-secondary);
}

.blueYellow [class*="LoadMoreButton_"] {
    color: var(--blue-yellow-secondary);
}

.blueYellow [class*="LoadMoreButton_"]:hover {
    background-color: var(--blue-yellow-secondary);
    border-color: var(--blue-yellow-secondary);
}

.blueYellow [class*="LoadMoreButton_"] [class*="icon_"] {
    background-color: var(--blue-yellow-secondary);
}

.blackWhite [class*="LoadMoreButton_"] {
    color: var(--black-white-secondary);
}

.blackWhite [class*="LoadMoreButton_"]:hover {
    background-color: var(--black-white-secondary);
    border-color: var(--black-white-secondary);
}

.blackWhite [class*="LoadMoreButton_"] [class*="icon_"] {
    background-color: var(--black-white-secondary);
}

.dark [class*="LoadMoreButton_"] {
    border-color: var(--neutral-dark);
    color: var(--dark-secondary);
    background-color: var(--neutral-dark);
}

.dark [class*="LoadMoreButton_"]:hover {
    background-color: var(--dark-secondary);
    border-color: var(--dark-secondary);
}

.dark [class*="LoadMoreButton_"] [class*="icon_"] {
    background-color: var(--dark-secondary);
}

.dark [class*="EventModal_"][class*="closeButton_"] {
    background-color: var(--neutral-ultra-dark);
}

/* Header Nav */

.dark [class*="Header_"][class*="header_"] {
    background-color: var(--neutral-dark);
    color: var(--white);
}

.dark [class*="Header_"][class*="header_"] [class*="searchForm_"],
.dark [class*="HeaderMobile_"][class*="header_"] [class*="SidebarMobile_"][class*="sidebar_"] [class*="searchForm_"] {
    background-color: var(--neutral);
    border: var(--dark-secondary) var(--btn-border-width) solid;
}

.dark [class*="Header_"][class*="header_"] [class*="searchForm_"] input {
    color: var(--white);
}

.redGreen [class*="Header_"][class*="header_"] [class*="accessButton_"] {
    --btn-background: transparent;
    --btn-background-hover: var(--red-green-primary-light);
    --btn-text-color: var(--red-green-primary);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--red-green-primary);
    --btn-border-color-hover: var(--red-green-primary-light);
    --focus-color: var(--red-green-primary-light);
}

.blueYellow [class*="Header_"][class*="header_"] [class*="accessButton_"] {
    --btn-background: transparent;
    --btn-background-hover: var(--blue-yellow-primary-light);
    --btn-text-color: var(--blue-yellow-primary);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--blue-yellow-primary);
    --btn-border-color-hover: var(--blue-yellow-primary-light);
    --focus-color: var(--blue-yellow-primary-light);
}

.blackWhite [class*="Header_"][class*="header_"] [class*="accessButton_"] {
    --btn-background: transparent;
    --btn-background-hover: var(--black-white-primary-light);
    --btn-text-color: var(--black-white-primary);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--black-white-primary);
    --btn-border-color-hover: var(--black-white-primary-light);
    --focus-color: var(--black-white-primary-light);
}

.dark [class*="Header_"][class*="header_"] [class*="accessButton_"] {
    --btn-background: transparent;
    --btn-background-hover: var(--neutral-ultra-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--white);
    --btn-border-color-hover: var(--neutral-ultra-light);
    --focus-color: var(--white);
}

.redGreen [class*="Header_"][class*="header_"] [class*="accessButton_"]:hover [class*="icon_"],
.blueYellow [class*="Header_"][class*="header_"] [class*="accessButton_"]:hover [class*="icon_"],
.blackWhite [class*="Header_"][class*="header_"] [class*="accessButton_"]:hover [class*="icon_"] {
    color: var(--white);
}

.dark [class*="Header_"][class*="header_"] [class*="accessButton_"]:hover [class*="icon_"] {
    color: var(--neutral);
}

.redGreen [class*="Header_"][class*="header_"] [class*="headerButton_"],
.redGreen [class*="Header_"][class*="header_"] [class*="burgerButton_"] {
    --btn-background: var(--red-green-primary);
    --btn-background-hover: var(--red-green-primary-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--red-green-primary);
    --btn-border-color-hover: var(--red-green-primary-light);
    --focus-color: var(--red-green-primary-light);
}

.blueYellow [class*="Header_"][class*="header_"] [class*="headerButton_"],
.blueYellow [class*="Header_"][class*="header_"] [class*="burgerButton_"] {
    --btn-background: var(--blue-yellow-primary);
    --btn-background-hover: var(--blue-yellow-primary-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--blue-yellow-primary);
    --btn-border-color-hover: var(--blue-yellow-primary-light);
    --focus-color: var(--blue-yellow-primary-light);
}

.blackWhite [class*="Header_"][class*="header_"] [class*="headerButton_"],
.blackWhite [class*="Header_"][class*="header_"] [class*="burgerButton_"] {
    --btn-background: var(--black-white-primary);
    --btn-background-hover: var(--black-white-primary-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--black-white-primary);
    --btn-border-color-hover: var(--black-white-primary-light);
    --focus-color: var(--black-white-primary-light);
}

.dark [class*="Header_"][class*="header_"] [class*="headerButton_"],
.dark [class*="Header_"][class*="header_"] [class*="burgerButton_"] {
    --btn-background: var(--dark-primary);
    --btn-background-hover: var(--dark-primary-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--dark-primary);
    --btn-border-color-hover: var(--dark-primary-light);
    --focus-color: var(--dark-primary-light);
}

.redGreen [class*="Header_"][class*="header_"] [class*="searchForm_"] svg,
.redGreen [class*="Header_"][class*="header_"] [class*="accessibilityIcon_"],
.redGreen [class*="HeaderMobile_"][class*="header_"] [class*="SidebarMobile_"][class*="sidebar_"] [class*="searchForm_"] svg {
    color: var(--red-green-secondary);
}

.blueYellow [class*="Header_"][class*="header_"] [class*="searchForm_"] svg,
.blueYellow [class*="Header_"][class*="header_"] [class*="accessibilityIcon_"],
.blueYellow [class*="HeaderMobile_"][class*="header_"] [class*="SidebarMobile_"][class*="sidebar_"] [class*="searchForm_"] svg {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="Header_"][class*="header_"] [class*="searchForm_"] svg,
.blackWhite [class*="Header_"][class*="header_"] [class*="accessibilityIcon_"],
.blackWhite [class*="HeaderMobile_"][class*="header_"] [class*="SidebarMobile_"][class*="sidebar_"] [class*="searchForm_"] svg {
    color: var(--blackWhite-secondary);
}

.dark [class*="Header_"][class*="header_"] [class*="searchForm_"] svg,
.dark [class*="Header_"][class*="header_"] [class*="accessibilityIcon_"],
.dark [class*="HeaderMobile_"][class*="header_"] [class*="SidebarMobile_"][class*="sidebar_"] [class*="searchForm_"] svg {
    color: var(--dark-secondary);
}

/* Header nav mobile */

.dark [class*="HeaderMobile_"] [class*="headerContent_"] {
    background-color: var(--neutral-dark);
}

.dark [class*="HeaderMobile_"] [class*="burgerBtn_"][class*="isActive"] {
    background-color: var(--neutral-ultra-dark);
}

.redGreen [class*="HeaderMobile_"] [class*="icon_"] {
    fill: var(--red-green-primary);
}

.blueYellow [class*="HeaderMobile_"] [class*="icon_"] {
    fill: var(--blue-yellow-primary);
}

.blackWhite [class*="HeaderMobile_"] [class*="icon_"] {
    fill: var(--black-white-primary);
}

.dark [class*="HeaderMobile_"] [class*="icon_"] {
    fill: var(--dark-primary);
}

.redGreen [class*="HeaderMobile_"] [class*="navSeparator_"] {
    background-color: var(--red-green-primary);
}

.blueYellow [class*="HeaderMobile_"] [class*="navSeparator_"] {
    background-color: var(--blue-yellow-primary);
}

.blackWhite [class*="HeaderMobile_"] [class*="navSeparator_"] {
    background-color: var(--black-white-primary);
}

.dark [class*="HeaderMobile_"] [class*="navSeparator_"] {
    background-color: var(--dark-primary);
}

.dark [class*="HeaderMobile_"] [class*="line_"] {
    background-color: var(--dark-primary);
}



/* Sidebar Menu */

.dark [class*="SidebarDesktop_"][class*="sidebar_"],
.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="closeContainer_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="closeContainer_"],
.dark [class*="AccessibilitySidebar_"][class*="sidebar_"],
.dark [class*="AccessibilitySidebar_"][class*="sidebar_"] [class*="closeContainer_"],
.dark [class*="AccessibilitySidebar_buttons_"] {
    background-color: var(--neutral);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="closeButton_"]:hover,
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="closeButton_"]:hover,
.dark [class*="AccessibilitySidebar_"][class*="sidebar_"] [class*="closeButton_"]:hover {
    background-color: var(--neutral-ultra-dark);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"] {
    color: currentColor;
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navList_"] li,
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navList_"] li {
    border-top: 1px solid var(--neutral-ultra-dark);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navList_"] li:last-child,
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navList_"] li:last-child {
    border-bottom: 1px solid var(--neutral-ultra-dark);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"]:hover,
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"]:hover {
    background-color: var(--neutral-ultra-dark);
}

.redGreen [class*="SidebarDesktop_socialsIcon_"]:hover,
.redGreen [class*="SidebarMobile_socialsIcon_"]:hover {
    color: var(--red-green-secondary);
}

.blueYellow [class*="SidebarDesktop_socialsIcon_"]:hover,
.blueYellow [class*="SidebarMobile_socialsIcon_"]:hover {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="SidebarDesktop_socialsIcon_"]:hover,
.blackWhite [class*="SidebarMobile_socialsIcon_"]:hover {
    color: var(--black-white-secondary);
}

.dark [class*="SidebarDesktop_socialsIcon_"]:hover,
.dark [class*="SidebarMobile_socialsIcon_"]:hover {
    color: var(--dark-secondary);
}

.redGreen [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="evenements_"],
.redGreen [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="evenements_"] {
    fill: var(--red-green-events);
}

.redGreen [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="enfance-et-jeunesse_"],
.redGreen [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="enfance-et-jeunesse_"] {
    fill: var(--red-green-jeunesse);
}

.redGreen [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="votre-collectivite_"],
.redGreen [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="votre-collectivite_"] {
    fill: var(--red-green-collectivite);
}

.redGreen [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="seniors_"],
.redGreen [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="seniors_"] {
    fill: var(--red-green-seniors);
}

.redGreen [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="mobilite_"],
.redGreen [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="mobilite_"] {
    fill: var(--red-green-mobilite);
}

.redGreen [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="services"],
.redGreen [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="services"] {
    fill: var(--red-green-services);
}

.redGreen [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="visiter-arras_"],
.redGreen [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="visiter-arras_"] {
    fill: var(--red-green-visiter);
}

.redGreen [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="arras-ville-dynamique_"],
.redGreen [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="arras-ville-dynamique_"] {
    fill: var(--red-green-inclusive);
}

.redGreen [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="partenaires_"],
.redGreen [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="partenaires_"],
.redGreen [class*="navItem_"][href*="associations"] {
    fill: var(--red-green-partenaires);
}

.redGreen [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="numeros-d-urgence_"],
.redGreen [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="numeros-d-urgence_"],
.redGreen [class*="navItem_"][href*="urgence"] {
    fill: var(--red-green-urgence);
}

.redGreen [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="contact_"],
.redGreen [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="contact_"],
.redGreen [class*="navItem_"][href*="contact"] {
    fill: var(--red-green-contact);
}

.redGreen [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="carte-interactive_"],
.redGreen [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="carte-interactive_"],
.redGreen [class*="navItem_"][href*="carte"] {
    fill: var(--red-green-carte);
}

.blueYellow [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="evenements_"],
.blueYellow [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="evenements_"] {
    fill: var(--blue-yellow-events);
}

.blueYellow [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="enfance-et-jeunesse_"],
.blueYellow [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="enfance-et-jeunesse_"] {
    fill: var(--blue-yellow-jeunesse);
}

.blueYellow [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="votre-collectivite_"],
.blueYellow [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="votre-collectivite_"] {
    fill: var(--blue-yellow-collectivite);
}

.blueYellow [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="seniors_"],
.blueYellow [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="seniors_"] {
    fill: var(--blue-yellow-seniors);
}

.blueYellow [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="mobilite_"],
.blueYellow [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="mobilite_"] {
    fill: var(--blue-yellow-mobilite);
}

.blueYellow [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="services"],
.blueYellow [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="services"] {
    fill: var(--blue-yellow-services);
}

.blueYellow [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="visiter-arras_"],
.blueYellow [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="visiter-arras_"] {
    fill: var(--blue-yellow-visiter);
}

.blueYellow [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="arras-ville-dynamique_"],
.blueYellow [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="arras-ville-dynamique_"] {
    fill: var(--blue-yellow-inclusive);
}

.blueYellow [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="partenaires_"],
.blueYellow [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="partenaires_"],
.blueYellow [class*="navItem_"][href*="associations"] {
    fill: var(--blue-yellow-partenaires);
}

.blueYellow [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="numeros-d-urgence_"],
.blueYellow [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="numeros-d-urgence_"],
.blueYellow [class*="navItem_"][href*="urgence"] {
    fill: var(--blue-yellow-urgence);
}

.blueYellow [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="contact_"],
.blueYellow [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="contact_"],
.blueYellow [class*="navItem_"][href*="contact"] {
    fill: var(--blue-yellow-contact);
}

.blueYellow [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="carte-interactive_"],
.blueYellow [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="carte-interactive_"],
.blueYellow [class*="navItem_"][href*="carte"] {
    fill: var(--blue-yellow-carte);
}

.blackWhite [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="evenements_"],
.blackWhite [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="evenements_"] {
    fill: var(--black-white-events);
}

.blackWhite [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="enfance-et-jeunesse_"],
.blackWhite [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="enfance-et-jeunesse_"] {
    fill: var(--black-white-jeunesse);
}

.blackWhite [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="votre-collectivite_"],
.blackWhite [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="votre-collectivite_"] {
    fill: var(--black-white-collectivite);
}

.blackWhite [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="seniors_"],
.blackWhite [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="seniors_"] {
    fill: var(--black-white-seniors);
}

.blackWhite [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="mobilite_"],
.blackWhite [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="mobilite_"] {
    fill: var(--black-white-mobilite);
}

.blackWhite [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="services"],
.blackWhite [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="services"] {
    fill: var(--black-white-services);
}

.blackWhite [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="visiter-arras_"],
.blackWhite [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="visiter-arras_"] {
    fill: var(--black-white-visiter);
}

.blackWhite [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="arras-ville-dynamique_"],
.blackWhite [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="arras-ville-dynamique_"] {
    fill: var(--black-white-inclusive);
}

.blackWhite [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="partenaires_"],
.blackWhite [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="partenaires_"],
.blackWhite [class*="navItem_"][href*="associations"] {
    fill: var(--black-white-partenaires);
}

.blackWhite [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="numeros-d-urgence_"],
.blackWhite [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="numeros-d-urgence_"],
.blackWhite [class*="navItem_"][href*="urgence"] {
    fill: var(--black-white-urgence);
}

.blackWhite [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="contact_"],
.blackWhite [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="contact_"],
.blackWhite [class*="navItem_"][href*="contact"] {
    fill: var(--black-white-contact);
}

.blackWhite [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="carte-interactive_"],
.blackWhite [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="carte-interactive_"],
.blackWhite [class*="navItem_"][href*="carte"] {
    fill: var(--black-white-carte);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="evenements_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="evenements_"] {
    fill: var(--dark-events-light);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="enfance-et-jeunesse_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="enfance-et-jeunesse_"] {
    fill: var(--dark-jeunesse-light);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="votre-collectivite_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="votre-collectivite_"] {
    fill: var(--dark-collectivite-light);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="seniors_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="seniors_"] {
    fill: var(--dark-seniors-light);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="mobilite_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="mobilite_"] {
    fill: var(--dark-mobilite-light);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="services"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="services"] {
    fill: var(--dark-services-light);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="visiter-arras_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="visiter-arras_"] {
    fill: var(--dark-visiter-light);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="arras-ville-dynamique_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="arras-ville-dynamique_"] {
    fill: var(--dark-inclusive-light);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="partenaires_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="partenaires_"],
.dark [class*="navItem_"][href*="associations"] {
    fill: var(--dark-partenaires-light);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="numeros-d-urgence_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="numeros-d-urgence_"],
.dark [class*="navItem_"][href*="urgence"] {
    fill: var(--dark-urgence-light);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="contact_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="contact_"],
.dark [class*="navItem_"][href*="contact"] {
    fill: var(--dark-contact-light);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="carte-interactive_"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="carte-interactive_"],
.dark [class*="navItem_"][href*="carte"] {
    fill: var(--dark-carte-light);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="evenements_"][class*="selectedCategory"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="evenements_"][class*="selectedCategory"] {
    fill: var(--white);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="enfance-et-jeunesse_"][class*="selectedCategory"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="enfance-et-jeunesse_"][class*="selectedCategory"] {
    fill: var(--white);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="votre-collectivite_"][class*="selectedCategory"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="votre-collectivite_"][class*="selectedCategory"] {
    fill: var(--white);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="seniors_"][class*="selectedCategory"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="seniors_"][class*="selectedCategory"] {
    fill: var(--white);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="mobilite_"][class*="selectedCategory"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="mobilite_"][class*="selectedCategory"] {
    fill: var(--white);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="services"][class*="selectedCategory"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="services"][class*="selectedCategory"] {
    fill: var(--white);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="visiter-arras_"][class*="selectedCategory"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="visiter-arras_"][class*="selectedCategory"] {
    fill: var(--white);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="arras-ville-dynamique_"][class*="selectedCategory"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="arras-ville-dynamique_"][class*="selectedCategory"] {
    fill: var(--white);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="partenaires_"][class*="selectedCategory"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="partenaires_"][class*="selectedCategory"] {
    fill: var(--white);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="numeros-d-urgence_"][class*="selectedCategory"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="numeros-d-urgence_"][class*="selectedCategory"] {
    fill: var(--white);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="contact_"][class*="selectedCategory"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="contact_"][class*="selectedCategory"] {
    fill: var(--white);
}

.dark [class*="SidebarDesktop_"][class*="sidebar_"] [class*="navItem_"][class*="carte-interactive_"][class*="selectedCategory"],
.dark [class*="SidebarMobile_"][class*="sidebar_"] [class*="navItem_"][class*="carte-interactive_"][class*="selectedCategory"] {
    fill: var(--white);
}

.redGreen [class*="evenements_"][class*="selectedCategory_"],
.redGreen [class*="subSidebar_"][class*="evenements_"] {
    --focus-color: var(--red-green-events);
    background-color: var(--red-green-events);
    color: var(--white);
    fill: var(--white);
}

.blueYellow [class*="evenements_"][class*="selectedCategory_"],
.blueYellow [class*="subSidebar_"][class*="evenements_"] {
    --focus-color: var(--blue-yellow-events);
    background-color: var(--blue-yellow-events);
    color: var(--white);
    fill: var(--white);
}

.blackWhite [class*="evenements_"][class*="selectedCategory_"],
.blackWhite [class*="subSidebar_"][class*="evenements_"] {
    --focus-color: var(--black-white-events);
    background-color: var(--black-white-events);
    color: var(--neutral);
    fill: var(--neutral);
}

.dark [class*="evenements_"][class*="selectedCategory_"],
.dark [class*="subSidebar_"][class*="evenements_"] {
    --focus-color: var(--dark-events);
    background-color: var(--dark-events);
    color: var(--white);
    fill: var(--white);
}

.redGreen [class*="enfance-et-jeunesse_"][class*="selectedCategory_"],
.redGreen [class*="subSidebar_"][class*="enfance-et-jeunesse_"] {
    --focus-color: var(--red-green-jeunesse);
    background-color: var(--red-green-jeunesse);
    color: var(--white);
    fill: var(--white);
}

.blueYellow [class*="enfance-et-jeunesse_"][class*="selectedCategory_"],
.blueYellow [class*="subSidebar_"][class*="enfance-et-jeunesse_"] {
    --focus-color: var(--blue-yellow-jeunesse);
    background-color: var(--blue-yellow-jeunesse);
    color: var(--white);
    fill: var(--white);
}

.blackWhite [class*="enfance-et-jeunesse_"][class*="selectedCategory_"],
.blackWhite [class*="subSidebar_"][class*="enfance-et-jeunesse_"] {
    --focus-color: var(--black-white-jeunesse);
    background-color: var(--black-white-jeunesse);
    color: var(--white);
    fill: var(--white);
}

.dark [class*="enfance-et-jeunesse_"][class*="selectedCategory_"],
.dark [class*="subSidebar_"][class*="enfance-et-jeunesse_"] {
    --focus-color: var(--dark-jeunesse);
    background-color: var(--dark-jeunesse);
    color: var(--white);
    fill: var(--white);
}

.redGreen [class*="votre-collectivite_"][class*="selectedCategory_"],
.redGreen [class*="subSidebar_"][class*="votre-collectivite_"] {
    --focus-color: var(--red-green-collectivite);
    background-color: var(--red-green-collectivite);
    color: var(--white);
    fill: var(--white);
}

.blueYellow [class*="votre-collectivite_"][class*="selectedCategory_"],
.blueYellow [class*="subSidebar_"][class*="votre-collectivite_"] {
    --focus-color: var(--blue-yellow-collectivite);
    background-color: var(--blue-yellow-collectivite);
    color: var(--white);
    fill: var(--white);
}

.blackWhite [class*="votre-collectivite_"][class*="selectedCategory_"],
.blackWhite [class*="subSidebar_"][class*="votre-collectivite_"] {
    --focus-color: var(--black-white-collectivite);
    background-color: var(--black-white-collectivite);
    color: var(--white);
    fill: var(--white);
}

.dark [class*="votre-collectivite_"][class*="selectedCategory_"],
.dark [class*="subSidebar_"][class*="votre-collectivite_"] {
    --focus-color: var(--dark-collectivite);
    background-color: var(--dark-collectivite);
    color: var(--white);
    fill: var(--white);
}

.redGreen [class*="seniors_"][class*="selectedCategory_"],
.redGreen [class*="subSidebar_"][class*="seniors_"] {
    --focus-color: var(--red-green-seniors);
    background-color: var(--red-green-seniors);
    color: var(--white);
    fill: var(--white);
}

.blueYellow [class*="seniors_"][class*="selectedCategory_"],
.blueYellow [class*="subSidebar_"][class*="seniors_"] {
    --focus-color: var(--blue-yellow-seniors);
    background-color: var(--blue-yellow-seniors);
    color: var(--white);
    fill: var(--white);
}

.blackWhite [class*="seniors_"][class*="selectedCategory_"],
.blackWhite [class*="subSidebar_"][class*="seniors_"] {
    --focus-color: var(--black-white-seniors);
    background-color: var(--black-white-seniors);
    color: var(--white);
    fill: var(--white);
}

.dark [class*="seniors_"][class*="selectedCategory_"],
.dark [class*="subSidebar_"][class*="seniors_"] {
    --focus-color: var(--dark-seniors);
    background-color: var(--dark-seniors);
    color: var(--white);
    fill: var(--white);
}

.redGreen [class*="mobilite_"][class*="selectedCategory_"],
.redGreen [class*="subSidebar_"][class*="mobilite_"] {
    --focus-color: var(--red-green-mobilite);
    background-color: var(--red-green-mobilite);
    color: var(--white);
    fill: var(--white);
}

.blueYellow [class*="mobilite_"][class*="selectedCategory_"],
.blueYellow [class*="subSidebar_"][class*="mobilite_"] {
    --focus-color: var(--blue-yellow-mobilite);
    background-color: var(--blue-yellow-mobilite);
    color: var(--white);
    fill: var(--white);
}

.blackWhite [class*="mobilite_"][class*="selectedCategory_"],
.blackWhite [class*="subSidebar_"][class*="mobilite_"] {
    --focus-color: var(--black-white-mobilite);
    background-color: var(--black-white-mobilite);
    color: var(--white);
    fill: var(--white);
}

.dark [class*="mobilite_"][class*="selectedCategory_"],
.dark [class*="subSidebar_"][class*="mobilite_"] {
    --focus-color: var(--dark-mobilite);
    background-color: var(--dark-mobilite);
    color: var(--white);
    fill: var(--white);
}

.redGreen [class*="services"][class*="selectedCategory_"],
.redGreen [class*="subSidebar_"][class*="services"] {
    --focus-color: var(--red-green-services);
    background-color: var(--red-green-services);
    color: var(--white);
    fill: var(--white);
}

.blueYellow [class*="services"][class*="selectedCategory_"],
.blueYellow [class*="subSidebar_"][class*="services"] {
    --focus-color: var(--blue-yellow-services);
    background-color: var(--blue-yellow-services);
    color: var(--white);
    fill: var(--white);
}

.blackWhite [class*="services"][class*="selectedCategory_"],
.blackWhite [class*="subSidebar_"][class*="services"] {
    --focus-color: var(--black-white-services);
    background-color: var(--black-white-services);
    color: var(--white);
    fill: var(--white);
}

.dark [class*="services"][class*="selectedCategory_"],
.dark [class*="subSidebar_"][class*="services"] {
    --focus-color: var(--dark-services);
    background-color: var(--dark-services);
    color: var(--white);
    fill: var(--white);
}

.redGreen [class*="visiter-arras_"][class*="selectedCategory_"],
.redGreen [class*="subSidebar_"][class*="visiter-arras_"] {
    --focus-color: var(--red-green-visiter);
    background-color: var(--red-green-visiter);
    color: var(--white);
    fill: var(--white);
}

.blueYellow [class*="visiter-arras_"][class*="selectedCategory_"],
.blueYellow [class*="subSidebar_"][class*="visiter-arras_"] {
    --focus-color: var(--blue-yellow-visiter);
    background-color: var(--blue-yellow-visiter);
    color: var(--white);
    fill: var(--white);
}

.blackWhite [class*="visiter-arras_"][class*="selectedCategory_"],
.blackWhite [class*="subSidebar_"][class*="visiter-arras_"] {
    --focus-color: var(--black-white-visiter);
    background-color: var(--black-white-visiter);
    color: var(--white);
    fill: var(--white);
}

.dark [class*="visiter-arras_"][class*="selectedCategory_"],
.dark [class*="subSidebar_"][class*="visiter-arras_"] {
    --focus-color: var(--dark-visiter);
    background-color: var(--dark-visiter);
    color: var(--white);
    fill: var(--white);
}

.redGreen [class*="arras-ville-dynamique_"][class*="selectedCategory_"],
.redGreen [class*="subSidebar_"][class*="arras-ville-dynamique_"] {
    --focus-color: var(--red-green-inclusive);
    background-color: var(--red-green-inclusive);
    color: var(--white);
    fill: var(--white);
}

.blueYellow [class*="arras-ville-dynamique_"][class*="selectedCategory_"],
.blueYellow [class*="subSidebar_"][class*="arras-ville-dynamique_"] {
    --focus-color: var(--blue-yellow-inclusive);
    background-color: var(--blue-yellow-inclusive);
    color: var(--white);
    fill: var(--white);
}

.blackWhite [class*="arras-ville-dynamique_"][class*="selectedCategory_"],
.blackWhite [class*="subSidebar_"][class*="arras-ville-dynamique_"] {
    --focus-color: var(--black-white-inclusive);
    background-color: var(--black-white-inclusive);
    color: var(--neutral);
    fill: var(--neutral);
}

.dark [class*="arras-ville-dynamique_"][class*="selectedCategory_"],
.dark [class*="subSidebar_"][class*="arras-ville-dynamique_"] {
    --focus-color: var(--dark-inclusive);
    background-color: var(--dark-inclusive);
    color: var(--white);
    fill: var(--white);
}

.redGreen [class*="partenaires_"][class*="selectedCategory_"],
.redGreen [class*="subSidebar_"][class*="partenaires_"] {
    --focus-color: var(--red-green-partenaires);
    background-color: var(--red-green-partenaires);
    color: var(--white);
    fill: var(--white);
}

.blueYellow [class*="partenaires_"][class*="selectedCategory_"],
.blueYellow [class*="subSidebar_"][class*="partenaires_"] {
    --focus-color: var(--blue-yellow-partenaires);
    background-color: var(--blue-yellow-partenaires);
    color: var(--white);
    fill: var(--white);
}

.blackWhite [class*="partenaires_"][class*="selectedCategory_"],
.blackWhite [class*="subSidebar_"][class*="partenaires_"] {
    --focus-color: var(--black-white-partenaires);
    background-color: var(--black-white-partenaires);
    color: var(--white);
    fill: var(--white);
}

.dark [class*="partenaires_"][class*="selectedCategory_"],
.dark [class*="subSidebar_"][class*="partenaires_"] {
    --focus-color: var(--dark-partenaires);
    background-color: var(--dark-partenaires);
    color: var(--white);
    fill: var(--white);
}

.redGreen [class*="partenaires_"][class*="selectedCategory_"],
.redGreen [class*="subSidebar_"][class*="partenaires_"] {
    --focus-color: var(--red-green-partenaires);
    background-color: var(--red-green-partenaires);
    color: var(--white);
    fill: var(--white);
}

.blueYellow [class*="partenaires_"][class*="selectedCategory_"],
.blueYellow [class*="subSidebar_"][class*="partenaires_"] {
    --focus-color: var(--blue-yellow-partenaires);
    background-color: var(--blue-yellow-partenaires);
    color: var(--white);
    fill: var(--white);
}

.blackWhite [class*="partenaires_"][class*="selectedCategory_"],
.blackWhite [class*="subSidebar_"][class*="partenaires_"] {
    --focus-color: var(--black-white-partenaires);
    background-color: var(--black-white-partenaires);
    color: var(--white);
    fill: var(--white);
}

.dark [class*="partenaires_"][class*="selectedCategory_"],
.dark [class*="subSidebar_"][class*="partenaires_"] {
    --focus-color: var(--dark-partenaires);
    background-color: var(--dark-partenaires);
    color: var(--white);
    fill: var(--white);
}

.redGreen [class*="numeros-d-urgence_"][class*="selectedCategory_"],
.redGreen [class*="subSidebar_"][class*="numeros-d-urgence_"] {
    --focus-color: var(--red-green-urgence);
    background-color: var(--red-green-urgence);
    color: var(--white);
    fill: var(--white);
}

.blueYellow [class*="numeros-d-urgence_"][class*="selectedCategory_"],
.blueYellow [class*="subSidebar_"][class*="numeros-d-urgence_"] {
    --focus-color: var(--blue-yellow-urgence);
    background-color: var(--blue-yellow-urgence);
    color: var(--white);
    fill: var(--white);
}

.blackWhite [class*="numeros-d-urgence_"][class*="selectedCategory_"],
.blackWhite [class*="subSidebar_"][class*="numeros-d-urgence_"] {
    --focus-color: var(--black-white-urgence);
    background-color: var(--black-white-urgence);
    color: var(--white);
    fill: var(--white);
}

.dark [class*="numeros-d-urgence_"][class*="selectedCategory_"],
.dark [class*="subSidebar_"][class*="numeros-d-urgence_"] {
    --focus-color: var(--dark-urgence);
    background-color: var(--dark-urgence);
    color: var(--white);
    fill: var(--white);
}

.redGreen [class*="contact_"][class*="selectedCategory_"],
.redGreen [class*="subSidebar_"][class*="contact_"] {
    --focus-color: var(--red-green-contact);
    background-color: var(--red-green-contact);
    color: var(--neutral);
    fill: var(--neutral);
}

.blueYellow [class*="contact_"][class*="selectedCategory_"],
.blueYellow [class*="subSidebar_"][class*="contact_"] {
    --focus-color: var(--blue-yellow-contact);
    background-color: var(--blue-yellow-contact);
    color: var(--neutral);
    fill: var(--neutral);
}

.blackWhite [class*="contact_"][class*="selectedCategory_"],
.blackWhite [class*="subSidebar_"][class*="contact_"] {
    --focus-color: var(--black-white-contact);
    background-color: var(--black-white-contact);
    color: var(--neutral);
    fill: var(--neutral);
}

.dark [class*="contact_"][class*="selectedCategory_"],
.dark [class*="subSidebar_"][class*="contact_"] {
    --focus-color: var(--dark-contact);
    background-color: var(--dark-contact);
    color: var(--white);
    fill: var(--white);
}

.redGreen [class*="subSidebar_"][class*="evenements_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blueYellow [class*="subSidebar_"][class*="evenements_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blackWhite [class*="subSidebar_"][class*="evenements_"] [class*="icon_"] svg {
    fill: var(--neutral);
}

.dark [class*="subSidebar_"][class*="evenements_"] [class*="icon_"] svg {
    fill: var(--white);
}

.redGreen [class*="subSidebar_"][class*="enfance-et-jeunesse_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blueYellow [class*="subSidebar_"][class*="enfance-et-jeunesse_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blackWhite [class*="subSidebar_"][class*="enfance-et-jeunesse_"] [class*="icon_"] svg {
    fill: var(--white);
}

.dark [class*="subSidebar_"][class*="enfance-et-jeunesse_"] [class*="icon_"] svg {
    fill: var(--white);
}

.redGreen [class*="subSidebar_"][class*="votre-collectivite_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blueYellow [class*="subSidebar_"][class*="votre-collectivite_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blackWhite [class*="subSidebar_"][class*="votre-collectivite_"] [class*="icon_"] svg {
    fill: var(--white);
}

.dark [class*="subSidebar_"][class*="votre-collectivite_"] [class*="icon_"] svg {
    fill: var(--white);
}

.redGreen [class*="subSidebar_"][class*="seniors_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blueYellow [class*="subSidebar_"][class*="seniors_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blackWhite [class*="subSidebar_"][class*="seniors_"] [class*="icon_"] svg {
    fill: var(--white);
}

.dark [class*="subSidebar_"][class*="seniors_"] [class*="icon_"] svg {
    fill: var(--white);
}

.redGreen [class*="subSidebar_"][class*="mobilite_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blueYellow [class*="subSidebar_"][class*="mobilite_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blackWhite [class*="subSidebar_"][class*="mobilite_"] [class*="icon_"] svg {
    fill: var(--white);
}

.dark [class*="subSidebar_"][class*="mobilite_"] [class*="icon_"] svg {
    fill: var(--white);
}

.redGreen [class*="subSidebar_"][class*="services"] [class*="icon_"] svg {
    fill: var(--white);
}

.blueYellow [class*="subSidebar_"][class*="services"] [class*="icon_"] svg {
    fill: var(--white);
}

.blackWhite [class*="subSidebar_"][class*="services"] [class*="icon_"] svg {
    fill: var(--white);
}

.dark [class*="subSidebar_"][class*="services"] [class*="icon_"] svg {
    fill: var(--white);
}

.redGreen [class*="subSidebar_"][class*="visiter-arras_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blueYellow [class*="subSidebar_"][class*="visiter-arras_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blackWhite [class*="subSidebar_"][class*="visiter-arras_"] [class*="icon_"] svg {
    fill: var(--white);
}

.dark [class*="subSidebar_"][class*="visiter-arras_"] [class*="icon_"] svg {
    fill: var(--white);
}

.redGreen [class*="subSidebar_"][class*="arras-ville-dynamique_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blueYellow [class*="subSidebar_"][class*="arras-ville-dynamique_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blackWhite [class*="subSidebar_"][class*="arras-ville-dynamique_"] [class*="icon_"] svg {
    fill: var(--neutral);
}

.dark [class*="subSidebar_"][class*="arras-ville-dynamique_"] [class*="icon_"] svg {
    fill: var(--white);
}

.redGreen [class*="subSidebar_"][class*="partenaires_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blueYellow [class*="subSidebar_"][class*="partenaires_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blackWhite [class*="subSidebar_"][class*="partenaires_"] [class*="icon_"] svg {
    fill: var(--white);
}

.dark [class*="subSidebar_"][class*="partenaires_"] [class*="icon_"] svg {
    fill: var(--white);
}

.redGreen [class*="subSidebar_"][class*="partenaires_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blueYellow [class*="subSidebar_"][class*="partenaires_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blackWhite [class*="subSidebar_"][class*="partenaires_"] [class*="icon_"] svg {
    fill: var(--white);
}

.dark [class*="subSidebar_"][class*="partenaires_"] [class*="icon_"] svg {
    fill: var(--white);
}

.redGreen [class*="subSidebar_"][class*="numeros-d-urgence_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blueYellow [class*="subSidebar_"][class*="numeros-d-urgence_"] [class*="icon_"] svg {
    fill: var(--white);
}

.blackWhite [class*="subSidebar_"][class*="numeros-d-urgence_"] [class*="icon_"] svg {
    fill: var(--white);
}

.dark [class*="subSidebar_"][class*="numeros-d-urgence_"] [class*="icon_"] svg {
    fill: var(--white);
}

.redGreen [class*="subSidebar_"][class*="contact_"] [class*="icon_"] svg {
    fill: var(--neutral);
}

.blueYellow [class*="subSidebar_"][class*="contact_"] [class*="icon_"] svg {
    fill: var(--neutral);
}

.blackWhite [class*="subSidebar_"][class*="contact_"] [class*="icon_"] svg {
    fill: var(--neutral);
}

.dark [class*="subSidebar_"][class*="contact_"] [class*="icon_"] svg {
    fill: var(--white);
}

.redGreen [class*="subSidebar_"]>*,
.blueYellow [class*="subSidebar_"]>*,
.blackWhite [class*="subSidebar_"]>*,
.dark [class*="subSidebar_"]>* {
    --focus-color: currentColor;
}

.redGreen [class*="subSidebar_"][class*="services"] [class*="navItem_"]:hover {
    background-color: var(--red-green-services-light);
}

.blueYellow [class*="subSidebar_"][class*="services"] [class*="navItem_"]:hover {
    background-color: var(--blue-yellow-services-light);
}

.blackWhite [class*="subSidebar_"][class*="services"] [class*="navItem_"]:hover {
    background-color: var(--black-white-services-light);
}

.dark [class*="subSidebar_"][class*="services"] [class*="navItem_"]:hover {
    background-color: var(--dark-services-light);
}

.redGreen [class*="subSidebar_"][class*="enfance-et-jeunesse"] [class*="navItem_"]:hover {
    background-color: var(--red-green-jeunesse-light);
    color: var(--neutral);
}

.blueYellow [class*="subSidebar_"][class*="enfance-et-jeunesse"] [class*="navItem_"]:hover {
    background-color: var(--blue-yellow-jeunesse-light);
}

.blackWhite [class*="subSidebar_"][class*="enfance-et-jeunesse"] [class*="navItem_"]:hover {
    background-color: var(--black-white-jeunesse-light);
}

.dark [class*="subSidebar_"][class*="enfance-et-jeunesse"] [class*="navItem_"]:hover {
    background-color: var(--dark-jeunesse-light);
}

.redGreen [class*="subSidebar_"][class*="seniors"] [class*="navItem_"]:hover {
    background-color: var(--red-green-seniors-light);
}

.blueYellow [class*="subSidebar_"][class*="seniors"] [class*="navItem_"]:hover {
    background-color: var(--blue-yellow-seniors-light);
}

.blackWhite [class*="subSidebar_"][class*="seniors"] [class*="navItem_"]:hover {
    background-color: var(--black-white-seniors-light);
}

.dark [class*="subSidebar_"][class*="seniors"] [class*="navItem_"]:hover {
    background-color: var(--dark-seniors-light);
}

.redGreen [class*="subSidebar_"][class*="evenements"] [class*="navItem_"]:hover {
    background-color: var(--red-green-events-light);
}

.blueYellow [class*="subSidebar_"][class*="evenements"] [class*="navItem_"]:hover {
    background-color: var(--blue-yellow-events-light);
}

.blackWhite [class*="subSidebar_"][class*="evenements"] [class*="navItem_"]:hover {
    background-color: var(--black-white-events-light);
}

.dark [class*="subSidebar_"][class*="evenements"] [class*="navItem_"]:hover {
    background-color: var(--dark-events-light);
}

.redGreen [class*="subSidebar_"][class*="mobilite"] [class*="navItem_"]:hover {
    background-color: var(--red-green-mobilite-light);
}

.blueYellow [class*="subSidebar_"][class*="mobilite"] [class*="navItem_"]:hover {
    background-color: var(--blue-yellow-mobilite-light);
}

.blackWhite [class*="subSidebar_"][class*="mobilite"] [class*="navItem_"]:hover {
    background-color: var(--black-white-mobilite-light);
}

.dark [class*="subSidebar_"][class*="mobilite"] [class*="navItem_"]:hover {
    background-color: var(--dark-mobilite-light);
}

.redGreen [class*="subSidebar_"][class*="votre-collectivite"] [class*="navItem_"]:hover {
    background-color: var(--red-green-collectivite-light);
}

.blueYellow [class*="subSidebar_"][class*="votre-collectivite"] [class*="navItem_"]:hover {
    background-color: var(--blue-yellow-collectivite-light);
}

.blackWhite [class*="subSidebar_"][class*="votre-collectivite"] [class*="navItem_"]:hover {
    background-color: var(--black-white-collectivite-light);
}

.dark [class*="subSidebar_"][class*="votre-collectivite"] [class*="navItem_"]:hover {
    background-color: var(--dark-collectivite-light);
}

.redGreen [class*="subSidebar_"][class*="visiter-arras"] [class*="navItem_"]:hover {
    background-color: var(--red-green-visiter-light);
}

.blueYellow [class*="subSidebar_"][class*="visiter-arras"] [class*="navItem_"]:hover {
    background-color: var(--blue-yellow-visiter-light);
}

.blackWhite [class*="subSidebar_"][class*="visiter-arras"] [class*="navItem_"]:hover {
    background-color: var(--black-white-visiter-light);
}

.dark [class*="subSidebar_"][class*="visiter-arras"] [class*="navItem_"]:hover {
    background-color: var(--dark-visiter-light);
}

.redGreen [class*="subSidebar_"][class*="partenaires"] [class*="navItem_"]:hover {
    background-color: var(--red-green-partenaires-light);
}

.blueYellow [class*="subSidebar_"][class*="partenaires"] [class*="navItem_"]:hover {
    background-color: var(--blue-yellow-partenaires-light);
}

.blackWhite [class*="subSidebar_"][class*="partenaires"] [class*="navItem_"]:hover {
    background-color: var(--black-white-partenaires-light);
}

.dark [class*="subSidebar_"][class*="partenaires"] [class*="navItem_"]:hover {
    background-color: var(--dark-partenaires-light);
}

.redGreen [class*="subSidebar_"][class*="numeros-d-urgence"] [class*="navItem_"]:hover {
    background-color: var(--red-green-urgence-light);
}

.blueYellow [class*="subSidebar_"][class*="numeros-d-urgence"] [class*="navItem_"]:hover {
    background-color: var(--blue-yellow-urgence-light);
}

.blackWhite [class*="subSidebar_"][class*="numeros-d-urgence"] [class*="navItem_"]:hover {
    background-color: var(--black-white-urgence-light);
}

.dark [class*="subSidebar_"][class*="numeros-d-urgence"] [class*="navItem_"]:hover {
    background-color: var(--dark-urgence-light);
}

.redGreen [class*="subSidebar_"][class*="contact"] [class*="navItem_"]:hover {
    background-color: var(--red-green-contact-light);
}

.blueYellow [class*="subSidebar_"][class*="contact"] [class*="navItem_"]:hover {
    background-color: var(--blue-yellow-contact-light);
}

.blackWhite [class*="subSidebar_"][class*="contact"] [class*="navItem_"]:hover {
    background-color: var(--black-white-contact-light);
}

.dark [class*="subSidebar_"][class*="contact"] [class*="navItem_"]:hover {
    background-color: var(--dark-contact-light);
}

.redGreen [class*="subSidebar_"][class*="arras-ville-dynamique"] [class*="navItem_"]:hover {
    background-color: var(--red-green-inclusive-light);
    color: var(--neutral);
}

.blueYellow [class*="subSidebar_"][class*="arras-ville-dynamique"] [class*="navItem_"]:hover {
    background-color: var(--blue-yellow-inclusive-light);
}

.blackWhite [class*="subSidebar_"][class*="arras-ville-dynamique"] [class*="navItem_"]:hover {
    background-color: var(--black-white-inclusive-light);
}

/* Responsive sidebar */

.redGreen [class*="SidebarMobile_"] [class*="accessButton_"] [class*="icon_"],
.blueYellow [class*="SidebarMobile_"] [class*="accessButton_"] [class*="icon_"],
.blackWhite [class*="SidebarMobile_"] [class*="accessButton_"] [class*="icon_"],
.dark [class*="SidebarMobile_"] [class*="accessButton_"] [class*="icon_"] {
    color: var(--white);
}

/* Accessibility options */

.redGreen [class*="AccessibilitySidebar_headingContainer_"],
.redGreen [class*="AccessibilitySidebar_"] [class*="percent_"] {
    color: var(--red-green-primary);
}

.blueYellow [class*="AccessibilitySidebar_headingContainer_"],
.blueYellow [class*="AccessibilitySidebar_"] [class*="percent_"] {
    color: var(--blue-yellow-primary);
}

.blackWhite [class*="AccessibilitySidebar_headingContainer_"],
.blackWhite [class*="AccessibilitySidebar_"] [class*="percent_"] {
    color: var(--black-white-primary);
}

.dark [class*="AccessibilitySidebar_headingContainer_"],
.dark [class*="AccessibilitySidebar_"] [class*="percent_"] {
    color: var(--dark-primary);
}

.redGreen [class*="AccessibilitySidebar_"] [class*="adjustContainer_"] button {
    background-color: var(--red-green-secondary);
}

.blueYellow [class*="AccessibilitySidebar_"] [class*="adjustContainer_"] button {
    background-color: var(--blue-yellow-secondary);
}

.blackWhite [class*="AccessibilitySidebar_"] [class*="adjustContainer_"] button {
    background-color: var(--black-white-secondary);
}

.dark [class*="AccessibilitySidebar_"] [class*="adjustContainer_"] button {
    background-color: var(--dark-secondary);
}

.dark [class*="AccessibilitySidebar_"] [class*="gridItem_"] {
    background-color: var(--neutral-dark);
    border-color: var(--neutral-dark);
}

.dark [class*="AccessibilitySidebar_"] [class*="gridItem_"]:hover {
    background-color: var(--neutral-ultra-dark);
    border-color: var(--neutral-ultra-dark);
}

.redGreen [class*="AccessibilitySidebar_"][class*="selected_"] {
    box-shadow: inset 0 0 0 var(--btn-border-width) var(--red-green-secondary);
}

.blueYellow [class*="AccessibilitySidebar_"][class*="selected_"] {
    box-shadow: inset 0 0 0 var(--btn-border-width) var(--blue-yellow-secondary);
}

.blackWhite [class*="AccessibilitySidebar_"][class*="selected_"] {
    box-shadow: inset 0 0 0 var(--btn-border-width) var(--black-white-secondary);
}

.dark [class*="AccessibilitySidebar_"][class*="selected_"] {
    box-shadow: inset 0 0 0 var(--btn-border-width) var(--dark-secondary);
}

.dark [class*="AccessibilitySidebar_"] [class*="resetButton_"]:hover {
    background-color: var(--neutral-ultra-dark);
    border-color: var(--neutral-ultra-dark);
}

.redGreen [class*="AccessibilitySidebar_"] [class*="switchInput"]:checked+[class*="switchSlider"] {
    background-color: var(--red-green-primary);
}

.redGreen [class*="AccessibilitySidebar_"] [class*="switchInput"]:checked+[class*="switchSlider"] {
    background-color: var(--red-green-primary);
}

.blueYellow [class*="AccessibilitySidebar_"] [class*="switchInput"]:checked+[class*="switchSlider"] {
    background-color: var(--blue-yellow-primary);
}

.blackWhite [class*="AccessibilitySidebar_"] [class*="switchInput"]:checked+[class*="switchSlider"] {
    background-color: var(--black-white-primary);
}

.dark [class*="AccessibilitySidebar_"] [class*="switchInput"]:checked+[class*="switchSlider"] {
    background-color: var(--dark-primary);
}

.dark [class*="AccessibilitySidebar_"] [class*="switchSlider"] {
    background-color: var(--neutral-dark);
}

/* Socials hero */

.dark [class*="SocialIcons_"] [class*="socialIconsContainer_"] {
    background-color: var(--neutral);
}

.dark [class*="SocialIcons_"] [class*="socialLink_"],
.dark [class*="SocialIcons_"] [class*="webcamLink_"] {
    color: var(--white);
    fill: var(--white);
}

.redGreen [class*="SocialIcons_"] [class*="socialLink_"]:hover,
.redGreen [class*="SocialIcons_"] [class*="webcamLink_"]:hover {
    color: var(--red-green-secondary);
    fill: var(--red-green-secondary);
}

.blueYellow [class*="SocialIcons_"] [class*="socialLink_"]:hover,
.blueYellow [class*="SocialIcons_"] [class*="webcamLink_"]:hover {
    color: var(--blue-yellow-secondary);
    fill: var(--blue-yellow-secondary);
}

.blackWhite [class*="SocialIcons_"] [class*="socialLink_"]:hover,
.blackWhite [class*="SocialIcons_"] [class*="webcamLink_"]:hover {
    color: var(--black-white-secondary);
    fill: var(--black-white-secondary);
}

.dark [class*="SocialIcons_"] [class*="socialLink_"]:hover,
.dark [class*="SocialIcons_"] [class*="webcamLink_"]:hover {
    color: var(--dark-secondary-light);
    fill: var(--dark-secondary-light);
}

/* Search */

.dark [class*="SearchBar_"] [class*="searchInput_"],
.dark [class*="SearchBar_"] [class*="fileTypeSelect"] {
    border-color: var(--dark-secondary);
}

.dark [class*="SearchBar_"] [class*="fileTypeSelect"] {
    color: var(--white);
    background-color: var(--neutral);
}

.dark [class*="SearchBar_"][class*="suggestionsList_"] {
    background-color: var(--neutral-dark);
    border-color: var(--dark-secondary);
}

.dark [class*="SearchBar_"][class*="suggestionItem"]:hover {
    background-color: var(--dark-secondary-light);
}

/* Home */

.dark [class*="widgetSection_"] [class*="widgetContainer_"] {
    background-color: var(--neutral);
    color: var(--white);
}

.redGreen [class*="QuickLinks_iconWrapper_"]:not([class*="Categories_"]),
.redGreen [class*="QuickLinks_responsiveItem_"]:not([class*="Categories_"]) {
    background-color: var(--red-green-events);
    color: var(--white);
    fill: currentColor;
}

.blueYellow [class*="QuickLinks_iconWrapper_"]:not([class*="Categories_"]),
.blueYellow [class*="QuickLinks_responsiveItem_"]:not([class*="Categories_"]) {
    background-color: var(--blue-yellow-events);
    color: var(--white);
    fill: currentColor;
}

.blackWhite [class*="QuickLinks_iconWrapper_"]:not([class*="Categories_"]),
.blackWhite [class*="QuickLinks_responsiveItem_"]:not([class*="Categories_"]) {
    background-color: var(--black-white-events);
    color: var(--neutral);
    fill: currentColor;
}

.dark [class*="QuickLinks_iconWrapper_"]:not([class*="Categories_"]),
.dark [class*="QuickLinks_responsiveItem_"]:not([class*="Categories_"]) {
    background-color: var(--dark-events);
    color: var(--white);
    fill: currentColor;
}

/* TEMP */
.redGreen [class*="linkItem"][aria-label*="urgence"] [class*="iconWrapper"] {
    background-color: var(--red-green-urgence);
}

.blueYellow [class*="linkItem"][aria-label*="urgence"] [class*="iconWrapper"] {
    background-color: var(--blue-yellow-urgence);
}

.blackWhite [class*="linkItem"][aria-label*="urgence"] [class*="iconWrapper"] {
    background-color: var(--black-white-urgence);
}

.dark [class*="linkItem"][aria-label*="urgence"] [class*="iconWrapper"] {
    background-color: var(--dark-urgence);
}

.redGreen [class*="linkItem"][aria-label*="bbaye"] [class*="iconWrapper"] {
    background-color: var(--red-green-visiter);
}

.blueYellow [class*="linkItem"][aria-label*="bbaye"] [class*="iconWrapper"] {
    background-color: var(--blue-yellow-visiter);
}

.blackWhite [class*="linkItem"][aria-label*="bbaye"] [class*="iconWrapper"] {
    background-color: var(--black-white-visiter);
}

.dark [class*="linkItem"][aria-label*="bbaye"] [class*="iconWrapper"] {
    background-color: var(--dark-visiter);
}
/* FIN TEMP */

.redGreen [class*="CustomizeProfile_"] [class*="customizeButton_"] {
    color: var(--red-green-secondary);
}

.blueYellow [class*="CustomizeProfile_"] [class*="customizeButton_"] {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="CustomizeProfile_"] [class*="customizeButton_"] {
    color: var(--black-white-secondary);
}

.dark [class*="CustomizeProfile_"] [class*="customizeButton_"] {
    background-color: var(--neutral);
    border-color: var(--neutral);
    color: var(--dark-secondary);
}

.redGreen [class*="CustomizeProfile_"] [class*="customizeButton_"]:hover,
.blueYellow [class*="CustomizeProfile_"] [class*="customizeButton_"]:hover,
.blackWhite [class*="CustomizeProfile_"] [class*="customizeButton_"]:hover {
    color: currentColor;
}

.dark [class*="CustomizeProfile_"] [class*="customizeButton_"]:hover {
    background-color: var(--dark-secondary);
    border-color: var(--dark-secondary);
    color: var(--white);
}

.redGreen [class*="CustomizeProfile_"] [class*="customizeButton_"] [class*="icon"],
.blueYellow [class*="CustomizeProfile_"] [class*="customizeButton_"] [class*="icon"],
.blackWhite [class*="CustomizeProfile_"] [class*="customizeButton_"] [class*="icon"] {
    color: currentColor;
}

.dark [class*="CustomizeProfile_"] [class*="customizeButton_"] [class*="icon"] {
    color: var(--dark-secondary);
}

.redGreen [class*="CustomizeProfile_"] [class*="customizeButton_"]:hover [class*="icon"] svg,
.blueYellow [class*="CustomizeProfile_"] [class*="customizeButton_"]:hover [class*="icon"] svg,
.blackWhite [class*="CustomizeProfile_"] [class*="customizeButton_"]:hover [class*="icon"] svg {
    color: currentColor !important;
    fill: currentColor !important;
}

.dark [class*="CustomizeProfile_"] [class*="customizeButton_"]:hover [class*="icon"] svg {
    color: var(--white) !important;
    fill: var(--white) !important;
}

.redGreen [class*="CustomizeProfile_"] [class*="separator"] {
    background-color: var(--red-green-secondary);
}

.blueYellow [class*="CustomizeProfile_"] [class*="separator"] {
    background-color: var(--blue-yellow-secondary);
}

.blackWhite [class*="CustomizeProfile_"] [class*="separator"] {
    background-color: var(--black-white-secondary);
}

.dark [class*="CustomizeProfile_"] [class*="separator"] {
    background-color: var(--dark-secondary);
}

/* Map CTA */

.redGreen [class*="MapCta_"] [class*="greenButton_"] {
    --btn-background: var(--red-green-carte);
    --btn-background-hover: var(--red-green-carte-light);
    --btn-text-color: var(--neutral);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--red-green-carte);
    --btn-border-color-hover: var(--red-green-carte-light);
    --focus-color: var(--red-green-carte-dark);
}

.blueYellow [class*="MapCta_"] [class*="greenButton_"] {
    --btn-background: var(--blue-yellow-carte);
    --btn-background-hover: var(--blue-yellow-carte-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--blue-yellow-carte);
    --btn-border-color-hover: var(--blue-yellow-carte-light);
    --focus-color: var(--blue-yellow-carte-dark);
}

.blackWhite [class*="MapCta_"] [class*="greenButton_"] {
    --btn-background: var(--black-white-carte);
    --btn-background-hover: var(--black-white-carte-light);
    --btn-text-color: var(--neutral);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--black-white-carte);
    --btn-border-color-hover: var(--black-white-carte-light);
    --focus-color: var(--black-white-carte-dark);
}

.dark [class*="MapCta_"] [class*="greenButton_"] {
    --btn-background: var(--dark-carte);
    --btn-background-hover: var(--dark-carte-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--dark-carte);
    --btn-border-color-hover: var(--dark-carte-light);
    --focus-color: var(--dark-carte-dark);
}

.redGreen [class*="MapCta_"] [class*="icon_"] {
    fill: var(--red-green-carte);
}

.blueYellow [class*="MapCta_"] [class*="icon_"] {
    fill: var(--blue-yellow-carte);
}

.blackWhite [class*="MapCta_"] [class*="icon_"] {
    fill: var(--black-white-carte);
}

.dark [class*="MapCta_"] [class*="icon_"] {
    fill: var(--dark-carte);
}

.dark [class*="MapCta_"] [class*="content_"] {
    background-color: var(--neutral);
}

.redGreen [class*="MapCta_"] [class*="ctaContainer_"] [class*="mediaContainer_"],
.redGreen [class*="MapCta_"] [class*="ctaContainer_"] [class*="mediaContainer_"]::after {
    background-color: var(--red-green-carte);
}

.blueYellow [class*="MapCta_"] [class*="ctaContainer_"] [class*="mediaContainer_"],
.blueYellow [class*="MapCta_"] [class*="ctaContainer_"] [class*="mediaContainer_"]::after {
    background-color: var(--blue-yellow-carte);
}

.blackWhite [class*="MapCta_"] [class*="ctaContainer_"] [class*="mediaContainer_"],
.blackWhite [class*="MapCta_"] [class*="ctaContainer_"] [class*="mediaContainer_"]::after {
    background-color: var(--black-white-carte);
}

.dark [class*="MapCta_"] [class*="ctaContainer_"] [class*="mediaContainer_"],
.dark [class*="MapCta_"] [class*="ctaContainer_"] [class*="mediaContainer_"]::after {
    background-color: var(--dark-carte);
}

@media screen and (max-width: 1024px) {
    .redGreen [class*="MapCta_"] [class*="greenButton_"] {
        --btn-background: var(--neutral);
        --btn-background-hover: var(--neutral-light);
        --btn-text-color: var(--white);
        --btn-text-color-hover: var(--white);
        --btn-border-color: var(--neutral);
        --btn-border-color-hover: var(--neutral-light);
        --focus-color: var(--neutral-dark);
    }

    .blueYellow [class*="MapCta_"] [class*="greenButton_"] {
        --btn-background: var(--white);
        --btn-background-hover: var(--neutral-ultra-light);
        --btn-text-color: var(--neutral);
        --btn-text-color-hover: var(--neutral);
        --btn-border-color: var(--white);
        --btn-border-color-hover: var(--neutral-ultra-light);
        --focus-color: var(--white);
    }

    .blackWhite [class*="MapCta_"] [class*="greenButton_"] {
        --btn-background: var(--neutral);
        --btn-background-hover: var(--neutral-light);
        --btn-text-color: var(--white);
        --btn-text-color-hover: var(--white);
        --btn-border-color: var(--neutral);
        --btn-border-color-hover: var(--neutral-light);
        --focus-color: var(--neutral-dark);
    }

    .dark [class*="MapCta_"] [class*="greenButton_"] {
        --btn-background: var(--white);
        --btn-background-hover: var(--neutral-ultra-light);
        --btn-text-color: var(--neutral);
        --btn-text-color-hover: var(--neutral);
        --btn-border-color: var(--white);
        --btn-border-color-hover: var(--neutral-ultra-light);
        --focus-color: var(--white);
    }

    .redGreen [class*="MapCta_"] [class*="ctaContainer_"] [class*="icon_"] {
        fill: var(--neutral);
    }

    .blueYellow [class*="MapCta_"] [class*="ctaContainer_"] [class*="icon_"] {
        fill: var(--white);
    }

    .blackWhite [class*="MapCta_"] [class*="ctaContainer_"] [class*="icon_"] {
        fill: var(--neutral);
    }

    .dark [class*="MapCta_"] [class*="ctaContainer_"] [class*="icon_"] {
        fill: var(--white);
    }

    .redGreen [class*="MapCta_"] [class*="content_"] {
        background-color: var(--red-green-carte);
        color: var(--neutral);
    }

    .blueYellow [class*="MapCta_"] [class*="content_"] {
        background-color: var(--blue-yellow-carte);
        color: var(--white);
    }

    .blackWhite [class*="MapCta_"] [class*="content_"] {
        background-color: var(--black-white-carte);
        color: var(--neutral);
    }

    .dark [class*="MapCta_"] [class*="content_"] {
        background-color: var(--dark-carte);
        color: var(--white);
    }

}

/* Cards */

.dark [class*="Card_"] [class*="content_"] {
    background-color: var(--neutral);
    color: currentColor;
}

.dark [class*="featuredEvent_"] [class*="eventInfo_"] {
    background-color: var(--neutral);
    color: var(--white);
}

.dark [class*="eventInfo_"] .arrow-link,
.dark [class*="Card_"] [class*="content_"] .arrow-link {
    color: var(--dark-primary);
}

.dark [class*="eventInfo_"] .arrow-link:hover,
.dark [class*="Card_"] [class*="content_"] .arrow-link:hover {
    color: var(--white);
}

.redGreen [class*="InfoCard_"][class*="blog_"] {
    background-color: var(--red-green-services);
    color: var(--white);
}

.blueYellow [class*="InfoCard_"][class*="blog_"] {
    background-color: var(--blue-yellow-services);
    color: var(--white);
}

.blackWhite [class*="InfoCard_"][class*="blog_"] {
    background-color: var(--black-white-services);
    color: var(--white);
}

.dark [class*="InfoCard_"][class*="blog_"] {
    background-color: var(--dark-services);
    color: var(--white);
}

.redGreen [class*="InfoCard_"][class*="agenda_"] {
    background-color: var(--red-green-events);
    color: var(--white);
}

.blueYellow [class*="InfoCard_"][class*="agenda_"] {
    background-color: var(--blue-yellow-events);
    color: var(--white);
}

.blackWhite [class*="InfoCard_"][class*="agenda_"] {
    background-color: var(--black-white-events);
    color: var(--neutral);
}

.dark [class*="InfoCard_"][class*="agenda_"] {
    background-color: var(--dark-events);
    color: var(--white);
}

.redGreen [class*="InfoCard_"][class*="agenda_"] button,
.blueYellow [class*="InfoCard_"][class*="agenda_"] button,
.dark [class*="InfoCard_"][class*="agenda_"] button,
.redGreen [class*="InfoCard_"][class*="blog_"] button,
.blueYellow [class*="InfoCard_"][class*="blog_"] button,
.blackWhite [class*="InfoCard_"][class*="blog_"] button,
.dark [class*="InfoCard_"][class*="blog_"] button {
    --btn-background: var(--white);
    --btn-background-hover: var(--neutral-ultra-light);
    --btn-border-color: var(--white);
    --btn-border-color-hover: var(--neutral-ultra-light);
    --btn-text-color: var(--neutral);
    --btn-text-color-hover: var(--neutral);
    --focus-color: var(--white);
}

.blackWhite [class*="InfoCard_"][class*="agenda_"] button {
    --btn-background: var(--neutral);
    --btn-background-hover: var(--neutral-light);
    --btn-border-color: var(--neutral);
    --btn-border-color-hover: var(--neutral-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --focus-color: var(--neutral);
}

.redGreen [class*="CardList_"][class*="grid_"]>li:not(:last-of-type)::after {
    border-color: var(--red-green-secondary);
}

.blueYellow [class*="CardList_"][class*="grid_"]>li:not(:last-of-type)::after {
    border-color: var(--blue-yellow-secondary);
}

.blackWhite [class*="CardList_"][class*="grid_"]>li:not(:last-of-type)::after {
    border-color: var(--black-white-secondary);
}

.dark [class*="CardList_"][class*="grid_"]>li:not(:last-of-type)::after {
    border-color: var(--dark-secondary);
}

.redGreen [class*="_dotList_"] [class*="_dotItem_"]:nth-child(3)::after {
    color: var(--red-green-primary);
    border-color: var(--red-green-primary);
}

.blueYellow [class*="_dotList_"] [class*="_dotItem_"]:nth-child(3)::after {
    color: var(--blue-yellow-primary);
    border-color: var(--blue-yellow-primary);
}

.blackWhite [class*="_dotList_"] [class*="_dotItem_"]:nth-child(3)::after {
    color: var(--black-white-primary);
    border-color: var(--black-white-primary);
}

.dark [class*="_dotList_"] [class*="_dotItem_"]:nth-child(3)::after {
    color: var(--dark-primary);
    border-color: var(--dark-primary);
}

/* Kiosque */

.dark [class*="kiosqueSection_"] [class*="featuredItem_"],
.dark [class*="kiosqueSection_"] [class*="actu_"] {
    background-color: var(--neutral);
}

.redGreen [class*="kiosqueSection_"] [class*="autres_"] {
    background-color: var(--red-green-collectivite);
}

.blueYellow [class*="kiosqueSection_"] [class*="autres_"] {
    background-color: var(--blue-yellow-collectivite);
}

.blackWhite [class*="kiosqueSection_"] [class*="autres_"] {
    background-color: var(--black-white-collectivite);
}

.dark [class*="kiosqueSection_"] [class*="autres_"] {
    background-color: var(--dark-collectivite);
}

/* Liens pratiques */

.redGreen [class*="smartSection_"] [class*="bgPharos_"] {
    background-color: var(--red-green-pharos);
    color: var(--white);
}

.blueYellow [class*="smartSection_"] [class*="bgPharos_"] {
    background-color: var(--blue-yellow-pharos);
    color: var(--white);
}

.blackWhite [class*="smartSection_"] [class*="bgPharos_"] {
    background-color: var(--black-white-pharos);
    color: var(--neutral);
}

.dark [class*="smartSection_"] [class*="bgPharos_"] {
    background-color: var(--dark-pharos);
    color: var(--white);
}

.blackWhite [class*="smartSection_"] [class*="figurePharos_"] {
    mix-blend-mode: difference;
}

.blackWhite [class*="smartSection_"] [class*="bgPharos_"] button {
    --btn-background: var(--neutral);
    --btn-background-hover: var(--neutral-light);
    --btn-border-color: var(--neutral);
    --btn-border-color-hover: var(--neutral-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --focus-color: var(--neutral);
}

.redGreen [class*="smartSection_"] [class*="bgApp_"] {
    background-color: var(--red-green-visiter);
    color: var(--white);
}

.blueYellow [class*="smartSection_"] [class*="bgApp_"] {
    background-color: var(--blue-yellow-visiter);
    color: var(--white);
}

.blackWhite [class*="smartSection_"] [class*="bgApp_"] {
    background-color: var(--black-white-visiter);
    color: var(--white);
}

.dark [class*="smartSection_"] [class*="bgApp_"] {
    background-color: var(--dark-visiter);
    color: var(--white);
}

.redGreen [class*="smartSection_"] [class*="bgThelma_"] {
    background-color: var(--red-green-services);
    color: var(--white);
}

.blueYellow [class*="smartSection_"] [class*="bgThelma_"] {
    background-color: var(--blue-yellow-services);
    color: var(--white);
}

.blackWhite [class*="smartSection_"] [class*="bgThelma_"] {
    background-color: var(--black-white-services);
    color: var(--white);
}

.dark [class*="smartSection_"] [class*="bgThelma_"] {
    background-color: var(--dark-services);
    color: var(--white);
}

/* Breadcrumbs */

.redGreen [class*="Breadcrumbs_"] span {
    color: var(--red-green-secondary);
}

.blueYellow [class*="Breadcrumbs_"] span {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="Breadcrumbs_"] span {
    color: var(--black-white-secondary);
}

.dark [class*="Breadcrumbs_"] span {
    color: var(--dark-secondary);
}

/* Archives */

.dark [class*="Archives_"] [class*="header_"] {
    background-color: var(--neutral);
}

.dark [class*="Archives_"] [class*="autoTag_"] {
    background-color: var(--neutral-ultra-dark);
    border: 1px solid var(--neutral-ultra-dark);
}

.dark [class*="Archives_"] [class*="filteredTag_"] {
    background-color: transparent;
    color: currentColor;
    border: 1px solid var(--white);
}

.dark [class*="Archives_"] [class*="offsetHeader_"] {
    background-color: var(--neutral);
}

.dark [class*="EventFilter_categoryFilter_"] {
    background-color: var(--neutral);
}

.dark [class*="EventFilter_categoryFilter_"],
.dark [class*="EventFilter_nameFilter_"] {
    border-color: var(--white);
}

.redGreen [class*="Archives_"] [class*="toggles_"] [class*="active_"] {
    background-color: var(--red-green-secondary);
}

.blueYellow [class*="Archives_"] [class*="toggles_"] [class*="active_"] {
    background-color: var(--blue-yellow-secondary);
}

.blackWhite [class*="Archives_"] [class*="toggles_"] [class*="active_"] {
    background-color: var(--black-white-secondary);
}

.dark [class*="Archives_"] [class*="toggles_"] [class*="active_"] {
    background-color: var(--dark-secondary);
}

.dark [class*="Archives_"] [class*="list_"] [class*="listItem_"],
.dark [class*="Archives_"] [class*="listView_"] [class*="listItem_"],
.dark [class*="NewsList_listItem__"] {
    background-color: var(--neutral);
}

/* Category Archives list and Tooltip */

.redGreen [class*="CategoryListArchives_"][class*="evenements_"],
.redGreen [class*="Archives_"] [class*="tagList"] .evenements {
    color: var(--red-green-events);
}

.blueYellow [class*="CategoryListArchives_"][class*="evenements_"],
.blueYellow [class*="Archives_"] [class*="tagList"] .evenements {
    color: var(--blue-yellow-events);
}

.dark [class*="CategoryListArchives_"][class*="evenements_"],
.dark [class*="Archives_"] [class*="tagList"] .evenements {
    color: var(--dark-events);
}

.redGreen [class*="CategoryListArchives_"][class*="enfance-et-jeunesse_"],
.redGreen [class*="Archives_"] [class*="tagList"] .enfance-et-jeunesse {
    color: var(--red-green-jeunesse);
}

.blueYellow [class*="CategoryListArchives_"][class*="enfance-et-jeunesse_"],
.blueYellow [class*="Archives_"] [class*="tagList"] .enfance-et-jeunesse {
    color: var(--blue-yellow-jeunesse);
}

.dark [class*="CategoryListArchives_"][class*="enfance-et-jeunesse_"],
.dark [class*="Archives_"] [class*="tagList"] .enfance-et-jeunesse {
    color: var(--dark-jeunesse);
}

.redGreen [class*="CategoryListArchives_"][class*="votre-collectivite_"],
.redGreen [class*="Archives_"] [class*="tagList"] .votre-collectivite {
    color: var(--red-green-collectivite);
}

.blueYellow [class*="CategoryListArchives_"][class*="votre-collectivite_"],
.blueYellow [class*="Archives_"] [class*="tagList"] .votre-collectivite {
    color: var(--blue-yellow-collectivite);
}

.dark [class*="CategoryListArchives_"][class*="votre-collectivite_"],
.dark [class*="Archives_"] [class*="tagList"] .votre-collectivite {
    color: var(--dark-collectivite);
}

.redGreen [class*="CategoryListArchives_"][class*="seniors_"],
.redGreen [class*="Archives_"] [class*="tagList"] .seniors {
    color: var(--red-green-seniors);
}

.blueYellow [class*="CategoryListArchives_"][class*="seniors_"],
.blueYellow [class*="Archives_"] [class*="tagList"] .seniors {
    color: var(--blue-yellow-seniors);
}

.dark [class*="CategoryListArchives_"][class*="seniors_"],
.dark [class*="Archives_"] [class*="tagList"] .seniors {
    color: var(--dark-seniors);
}

.redGreen [class*="CategoryListArchives_"][class*="mobilite_"],
.redGreen [class*="Archives_"] [class*="tagList"] .mobilite {
    color: var(--red-green-mobilite);
}

.blueYellow [class*="CategoryListArchives_"][class*="mobilite_"],
.blueYellow [class*="Archives_"] [class*="tagList"] .mobilite {
    color: var(--blue-yellow-mobilite);
}

.dark [class*="CategoryListArchives_"][class*="mobilite_"],
.dark [class*="Archives_"] [class*="tagList"] .mobilite {
    color: var(--dark-mobilite);
}

.redGreen [class*="CategoryListArchives_"][class*="services"],
.redGreen [class*="Archives_"] [class*="tagList"] [class*="services"] {
    color: var(--red-green-services);
}

.blueYellow [class*="CategoryListArchives_"][class*="services"],
.blueYellow [class*="Archives_"] [class*="tagList"] [class*="services"] {
    color: var(--blue-yellow-services);
}

.dark [class*="CategoryListArchives_"][class*="services"],
.dark [class*="Archives_"] [class*="tagList"] [class*="services"] {
    color: var(--dark-services);
}

.redGreen [class*="CategoryListArchives_"][class*="visiter-arras_"],
.redGreen [class*="Archives_"] [class*="tagList"] .visiter-arras {
    color: var(--red-green-visiter);
}

.blueYellow [class*="CategoryListArchives_"][class*="visiter-arras_"],
.blueYellow [class*="Archives_"] [class*="tagList"] .visiter-arras {
    color: var(--blue-yellow-visiter);
}

.dark [class*="CategoryListArchives_"][class*="visiter-arras_"],
.dark [class*="Archives_"] [class*="tagList"] .visiter-arras {
    color: var(--dark-visiter);
}

.redGreen [class*="CategoryListArchives_"][class*="arras-ville-dynamique_"],
.redGreen [class*="Archives_"] [class*="tagList"] .arras-ville-dynamique {
    color: var(--red-green-inclusive);
}

.blueYellow [class*="CategoryListArchives_"][class*="arras-ville-dynamique_"],
.blueYellow [class*="Archives_"] [class*="tagList"] .arras-ville-dynamique {
    color: var(--blue-yellow-inclusive);
}

.dark [class*="CategoryListArchives_"][class*="arras-ville-dynamique_"],
.dark [class*="Archives_"] [class*="tagList"] .arras-ville-dynamique {
    color: var(--dark-inclusive);
}

.redGreen [class*="CategoryListArchives_"][class*="partenaires_"],
.redGreen [class*="Archives_"] [class*="tagList"] .partenaires {
    color: var(--red-green-partenaires);
}

.blueYellow [class*="CategoryListArchives_"][class*="partenaires_"],
.blueYellow [class*="Archives_"] [class*="tagList"] .partenaires {
    color: var(--blue-yellow-partenaires);
}

.dark [class*="CategoryListArchives_"][class*="partenaires_"],
.dark [class*="Archives_"] [class*="tagList"] .partenaires {
    color: var(--dark-partenaires);
}

.redGreen [class*="CategoryListArchives_"][class*="numeros-d-urgence_"],
.redGreen [class*="Archives_"] [class*="tagList"] .numeros-d-urgence {
    color: var(--red-green-urgence);
}

.blueYellow [class*="CategoryListArchives_"][class*="numeros-d-urgence_"],
.blueYellow [class*="Archives_"] [class*="tagList"] .numeros-d-urgence {
    color: var(--blue-yellow-urgence);
}

.dark [class*="CategoryListArchives_"][class*="numeros-d-urgence_"],
.dark [class*="Archives_"] [class*="tagList"] .numeros-d-urgence {
    color: var(--dark-urgence);
}

.redGreen [class*="CategoryListArchives_"][class*="contact_"],
.redGreen [class*="Archives_"] [class*="tagList"] .contact {
    color: var(--red-green-contact);
}

.blueYellow [class*="CategoryListArchives_"][class*="contact_"],
.blueYellow [class*="Archives_"] [class*="tagList"] .contact {
    color: var(--blue-yellow-contact-dark);
}

.dark [class*="CategoryListArchives_"][class*="contact_"],
.dark [class*="Archives_"] [class*="tagList"] .contact {
    color: var(--dark-contact-ultra-dark);
}

.blackWhite [class*="CategoryListArchives_"][class*="evenements_"],
.blackWhite [class*="CategoryListArchives_"][class*="enfance-et-jeunesse_"],
.blackWhite [class*="CategoryListArchives_"][class*="votre-collectivite_"],
.blackWhite [class*="CategoryListArchives_"][class*="seniors_"],
.blackWhite [class*="CategoryListArchives_"][class*="mobilite_"],
.blackWhite [class*="CategoryListArchives_"][class*="services"],
.blackWhite [class*="CategoryListArchives_"][class*="visiter-arras_"],
.blackWhite [class*="CategoryListArchives_"][class*="arras-ville-dynamique_"],
.blackWhite [class*="CategoryListArchives_"][class*="partenaires_"],
.blackWhite [class*="CategoryListArchives_"][class*="numeros-d-urgence_"],
.blackWhite [class*="CategoryListArchives_"][class*="contact_"],
.blackWhite [class*="Archives_"] [class*="tagList"] li,
.blackWhite [class*="tooltipItem_"] span {
    color: var(--neutral);
}

/* Singles */

.dark [class*="ContentPage_"] [class*="headerContainer_"],
.dark [class*="ContentPage_"] [class*="main_"] {
    background-color: var(--neutral);
}

.redGreen [class*="ContentPage_"] [class*="metaIcon_"],
.redGreen [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] li::marker,
.redGreen [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] a {
    color: var(--red-green-secondary);
}

.blueYellow [class*="ContentPage_"] [class*="metaIcon_"],
.blueYellow [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] li::marker,
.blueYellow [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] a {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="ContentPage_"] [class*="metaIcon_"],
.blackWhite [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] li::marker,
.blackWhite [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] a {
    color: var(--black-white-secondary);
}

.dark [class*="ContentPage_"] [class*="metaIcon_"],
.dark [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] li::marker,
.dark [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] a {
    color: var(--dark-secondary);
}

.redGreen [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] a:hover {
    color: var(--red-green-secondary-light);
}

.blueYellow [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] a:hover {
    color: var(--blue-yellow-secondary-light);
}

.blackWhite [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] a:hover {
    color: var(--black-white-secondary-light);
}

.dark [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] a:hover {
    color: var(--dark-secondary-light);
}

.redGreen [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] blockquote {
    border-color: var(--red-green-secondary);
}

.blueYellow [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] blockquote {
    border-color: var(--blue-yellow-secondary);
}

.blackWhite [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] blockquote {
    border-color: var(--black-white-secondary);
}

.dark [class*="ContentPage_"] [class*="mainColumn_"] [class*="content_"] blockquote {
    border-color: var(--dark-secondary);
}

/* Associations et POI */
.dark [class*="AssociationPage_contactInfo_"],
.dark [class*="PointOfInterestPage_contactInfo"] {
    background-color: var(--neutral-dark);
}

.redGreen [class*="PointOfInterestPage_infoItem"] h2 {
    color: var(--red-green-carte);
}

.blueYellow [class*="PointOfInterestPage_infoItem"] h2 {
    color: var(--blue-yellow-carte);
}

.blackWhite [class*="PointOfInterestPage_infoItem"] h2 {
    color: var(--black-white-carte);
}

.dark [class*="PointOfInterestPage_infoItem"] h2 {
    color: var(--dark-carte);
}

/* Urgence numbers page */

.redGreen [class*="UrgenceNumbersPage_intro_"] {
    background-color: var(--red-green-secondary);
}

.blueYellow [class*="UrgenceNumbersPage_intro_"] {
    background-color: var(--blue-yellow-secondary);
}

.blackWhite [class*="UrgenceNumbersPage_intro_"] {
    background-color: var(--black-white-secondary);
}

.dark [class*="UrgenceNumbersPage_intro_"] {
    background-color: var(--dark-secondary);
}

.redGreen [class*="UrgenceNumbersPage_number_"] {
    color: var(--red-green-secondary);
}

.blueYellow [class*="UrgenceNumbersPage_number_"] {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="UrgenceNumbersPage_number_"] {
    color: var(--black-white-secondary);
}

.dark [class*="UrgenceNumbersPage_number_"] {
    color: var(--dark-secondary);
}

.dark [class*="UrgenceNumbersPage_list_"] li [class*="UrgenceNumbersPage_item_"],
.dark [class*="Archives_"] [class*="SubCategory_container_"] [class*="SubCategory_list_"] [class*="SubCategory_listItem_"] {
    background-color: var(--neutral-dark);
    border-color: var(--neutral-dark);
}

.dark [class*="UrgenceNumbersPage_list_"] li [class*="UrgenceNumbersPage_item_"]:hover,
.dark [class*="Archives_"] [class*="SubCategory_container_"] [class*="SubCategory_list_"] [class*="SubCategory_listItem_"]:hover {
    background-color: var(--neutral-ultra-dark);
}

/* Sub Category page */

.redGreen [class*="Archives_"] [class*="SubCategory_container_"] [class*="SubCategory_list_"] [class*="SubCategory_listItem_"] [class*="SubCategory_icon"] {
    color: var(--red-green-secondary);
}

.blueYellow [class*="Archives_"] [class*="SubCategory_container_"] [class*="SubCategory_list_"] [class*="SubCategory_listItem_"] [class*="SubCategory_icon"] {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="Archives_"] [class*="SubCategory_container_"] [class*="SubCategory_list_"] [class*="SubCategory_listItem_"] [class*="SubCategory_icon"] {
    color: var(--black-white-secondary);
}

.dark [class*="Archives_"] [class*="SubCategory_container_"] [class*="SubCategory_list_"] [class*="SubCategory_listItem_"] [class*="SubCategory_icon"] {
    color: var(--dark-secondary-light);
}

/* File list */

.redGreen [class*="FileList_"] [class*="fileItem_"]:not(:last-child) {
    border-bottom: 1px solid var(--red-green-secondary);
}

.blueYellow [class*="FileList_"] [class*="fileItem_"]:not(:last-child) {
    border-bottom: 1px solid var(--blue-yellow-secondary);
}

.blackWhite [class*="FileList_"] [class*="fileItem_"]:not(:last-child) {
    border-bottom: 1px solid var(--black-white-secondary);
}

.dark [class*="FileList_"] [class*="fileItem_"]:not(:last-child) {
    border-bottom: 1px solid var(--dark-secondary);
}

.redGreen [class*="FileList_"] [class*="fileIcon_"] {
    color: var(--red-green-secondary);
}

.blueYellow [class*="FileList_"] [class*="fileIcon_"] {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="FileList_"] [class*="fileIcon_"] {
    color: var(--black-white-secondary);
}

.dark [class*="FileList_"] [class*="fileIcon_"] {
    color: var(--dark-secondary);
}

.redGreen [class*="FileList_"] button[class*="icon_"] {
    background-color: var(--red-green-secondary);
}

.blueYellow [class*="FileList_"] button[class*="icon_"] {
    background-color: var(--blue-yellow-secondary);
}

.blackWhite [class*="FileList_"] button[class*="icon_"] {
    background-color: var(--black-white-secondary);
}

.dark [class*="FileList_"] button[class*="icon_"] {
    background-color: var(--dark-secondary);
}

.redGreen [class*="FileList_"] button[class*="icon_"]:hover {
    background-color: var(--red-green-secondary-light);
}

.blueYellow [class*="FileList_"] button[class*="icon_"]:hover {
    background-color: var(--blue-yellow-secondary-light);
}

.blackWhite [class*="FileList_"] button[class*="icon_"]:hover {
    background-color: var(--black-white-secondary-light);
}

.dark [class*="FileList_"] button[class*="icon_"]:hover {
    background-color: var(--dark-secondary-light);
}

/* Summary */

.dark [class*="Summary_"][class*="summary_"] {
    background-color: var(--neutral-ultra-dark);
}

.redGreen [class*="Summary_"][class*="summary_"] a:hover {
    color: var(--red-green-secondary);
}

.blueYellow [class*="Summary_"][class*="summary_"] a:hover {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="Summary_"][class*="summary_"] a:hover {
    color: var(--black-white-secondary);
}

.dark [class*="Summary_"][class*="summary_"] a:hover {
    color: var(--dark-secondary);
}

/* Share Buttons */

.redGreen [class*="ShareButtons_"][class*="shareButtons_"] a:hover {
    color: var(--red-green-secondary);
}

.blueYellow [class*="ShareButtons_"][class*="shareButtons_"] a:hover {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="ShareButtons_"][class*="shareButtons_"] a:hover {
    color: var(--black-white-secondary);
}

.dark [class*="ShareButtons_"][class*="shareButtons_"] a:hover {
    color: var(--dark-secondary);
}

/* Footer & contact */

.redGreen [class*="footer_"] {
    background-color: var(--red-green-primary);
    color: var(--white);
}

.blueYellow [class*="footer_"] {
    background-color: var(--blue-yellow-primary);
    color: var(--white);
}

.blackWhite [class*="footer_"] {
    background-color: var(--black-white-primary);
    color: var(--white);
}

.dark [class*="footer_"] {
    background-color: var(--dark-primary-dark);
    color: var(--white);
}

.redGreen [class*="footer_"] [class*="socialLink_"]:hover {
    color: var(--red-green-secondary-light);
    /* Couleur de survol */
    fill: var(--red-green-secondary-light);
    /* Couleur de survol */
}

.blueYellow [class*="footer_"] [class*="socialLink_"]:hover {
    color: var(--blue-yellow-secondary-light);
    /* Couleur de survol */
    fill: var(--blue-yellow-secondary-light);
    /* Couleur de survol */
}

.blackWhite [class*="footer_"] [class*="socialLink_"]:hover {
    color: var(--black-white-secondary-ultra-light);
    /* Couleur de survol */
    fill: var(--black-white-secondary-ultra-light);
    /* Couleur de survol */
}

.dark [class*="footer_"] [class*="socialLink_"]:hover {
    color: var(--dark-secondary-light);
    /* Couleur de survol */
    fill: var(--dark-secondary-light);
    /* Couleur de survol */
}

.redGreen [class*="footer_"] [class*="webcamLink_"]:hover {
    fill: var(--red-green-secondary-light);
}

.blueYellow [class*="footer_"] [class*="webcamLink_"]:hover {
    fill: var(--blue-yellow-secondary-light);
    /* Couleur de survol */
}

.blackWhite [class*="footer_"] [class*="webcamLink_"]:hover {
    fill: var(--black-white-secondary-ultra-light);
    /* Couleur de survol */
}

.dark [class*="footer_"] [class*="webcamLink_"]:hover {
    fill: var(--dark-secondary-light);
    /* Couleur de survol */
}

.dark [class*="ContactPage_"] [class*="metaContainer"] {
    background-color: var(--neutral-dark);
}

.redGreen [class*="ContactPage_"] [class*="metaContainer"] h4 {
    color: var(--red-green-secondary);
}

.blueYellow [class*="ContactPage_"] [class*="metaContainer"] h4 {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="ContactPage_"] [class*="metaContainer"] h4 {
    color: var(--black-white-secondary);
}

.dark [class*="ContactPage_"] [class*="metaContainer"] h4 {
    color: var(--dark-secondary-light);
}

.redGreen [class*="ContactPage_"] [class*="listBlock"] a:not([class*="link"]) {
    color: var(--red-green-secondary);
}

.blueYellow [class*="ContactPage_"] [class*="listBlock"] a:not([class*="link"]) {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="ContactPage_"] [class*="listBlock"] a:not([class*="link"]) {
    color: var(--black-white-secondary);
}

.dark [class*="ContactPage_"] [class*="listBlock"] a:not([class*="link"]) {
    color: var(--dark-secondary);
}



.redGreen [class*="footer_"] [class*="block"] a:hover,
.redGreen [class*="ContactPage_"] [class*="listBlock"] a:not([class*="link"]):hover,
.redGreen [class*="comingSoonPage_"] [class*="block"] a:hover,
.redGreen [class*="comingSoonPage_"] [class*="listBlock"] a:not([class*="link"]):hover {
    background-color: var(--red-green-secondary-light);
}

.blueYellow [class*="footer_"] [class*="block"] a:hover,
.blueYellow [class*="ContactPage_"] [class*="listBlock"] a:not([class*="link"]):hover,
.blueYellow [class*="comingSoonPage_"] [class*="block"] a:hover,
.blueYellow [class*="comingSoonPage_"] [class*="listBlock"] a:not([class*="link"]):hover {
    background-color: var(--blue-yellow-secondary-light);
}

.blackWhite [class*="footer_"] [class*="block"] a:hover,
.blackWhite [class*="ContactPage_"] [class*="listBlock"] a:not([class*="link"]):hover,
.blackWhite [class*="comingSoonPage_"] [class*="block"] a:hover,
.blackWhite [class*="comingSoonPage_"] [class*="listBlock"] a:not([class*="link"]):hover {
    background-color: var(--black-white-secondary-light);
}

.dark [class*="footer_"] [class*="block"] a:hover,
.dark [class*="ContactPage_"] [class*="listBlock"] a:not([class*="link"]):hover,
.dark [class*="comingSoonPage_"] [class*="block"] a:hover,
.dark [class*="comingSoonPage_"] [class*="listBlock"] a:not([class*="link"]):hover {
    background-color: var(--dark-secondary-light);
}

.redGreen [class*="footer_"] [class*="block"] [class*="_freePhone_"],
.redGreen [class*="ContactPage_"] [class*="listBlock"] [class*="_freePhone_"],
.redGreen [class*="comingSoonPage_"] [class*="block"] [class*="_freePhone_"] {
    background-color: var(--red-green-secondary);
}

.blueYellow [class*="footer_"] [class*="block"] [class*="_freePhone_"],
.blueYellow [class*="ContactPage_"] [class*="listBlock"] [class*="_freePhone_"],
.blueYellow [class*="comingSoonPage_"] [class*="block"] [class*="_freePhone_"] {
    background-color: var(--blue-yellow-secondary);
}

.blackWhite [class*="footer_"] [class*="block"] [class*="_freePhone_"],
.blackWhite [class*="ContactPage_"] [class*="listBlock"] [class*="_freePhone_"],
.blackWhite [class*="comingSoonPage_"] [class*="block"] [class*="_freePhone_"] {
    background-color: var(--black-white-secondary);
}

.dark [class*="footer_"] [class*="block"] [class*="_freePhone_"],
.dark [class*="comingSoonPage_"] [class*="block"] [class*="_freePhone_"] {
    background-color: var(--white);
    color: var(--neutral);
}

.redGreen [class*="ContactPage_"] [class*="listBlock"] [class*="_freePhone_"],
.blueYellow [class*="ContactPage_"] [class*="listBlock"] [class*="_freePhone_"],
.blackWhite [class*="ContactPage_"] [class*="listBlock"] [class*="_freePhone_"] {
    color: var(--white);
}

.dark [class*="ContactPage_"] [class*="listBlock"] [class*="_freePhone_"] {
    background-color: var(--dark-secondary);
    color: var(--white);
}

.redGreen [class*="footer_"] [class*="list_"] [class*="link_"],
.blueYellow [class*="footer_"] [class*="list_"] [class*="link_"],
.blackWhite [class*="footer_"] [class*="list_"] [class*="link_"],
.dark [class*="footer_"] [class*="list_"] [class*="link_"],
.redGreen [class*="footer_"] [class*="legalItem_"],
.blueYellow [class*="footer_"] [class*="legalItem_"],
.blackWhite [class*="footer_"] [class*="legalItem_"],
.dark [class*="footer_"] [class*="legalItem_"],
.redGreen [class*="comingSoonPage_"] [class*="list_"] [class*="link_"],
.blueYellow [class*="comingSoonPage_"] [class*="list_"] [class*="link_"],
.blackWhite [class*="comingSoonPage_"] [class*="list_"] [class*="link_"],
.dark [class*="comingSoonPage_"] [class*="list_"] [class*="link_"],
.redGreen [class*="comingSoonPage_"] [class*="legalItem_"],
.blueYellow [class*="comingSoonPage_"] [class*="legalItem_"],
.blackWhite [class*="comingSoonPage_"] [class*="legalItem_"],
.dark [class*="comingSoonPage_"] [class*="legalItem_"] {
    color: var(--white);
}

.dark [class*="ContactPage_formContainer_"] {
    background-color: var(--neutral-dark);
}

.dark [class*="ContactPage_formContainer_"] input,
.dark [class*="ContactPage_formContainer_"] textarea,
.dark [class*="ContactPage_formContainer_"] select {
    background-color: var(--neutral);
}

.dark [class*="ContactPage_formContainer_"] select {
    border-color: var(--white);
}

.redGreen [class*="ContactPage_formContainer_"] [class*="danger_"],
.redGreen [class*="ContactPage_formContainer_"] [class*="required_"]::after {
    color: var(--red-green-inclusive-ultra-dark);
}

.blueYellow [class*="ContactPage_formContainer_"] [class*="danger_"],
.blueYellow [class*="ContactPage_formContainer_"] [class*="required_"]::after {
    color: var(--blue-yellow-inclusive);
}

.blackWhite [class*="ContactPage_formContainer_"] [class*="danger_"],
.blackWhite [class*="ContactPage_formContainer_"] [class*="required_"]::after {
    color: var(--black-white-inclusive-ultra-dark);
}

.dark [class*="ContactPage_formContainer_"] [class*="danger_"],
.dark [class*="ContactPage_formContainer_"] [class*="required_"]::after {
    color: var(--dark-inclusive);
}

/* FC */

.dark .fc {
    --fc-today-bg-color: var(--neutral-ultra-dark);
    --fc-neutral-bg-color: var(--neutral-ultra-dark);
    background-color: var(--neutral);
}

.dark .fcContainer .fc-button:hover {
    background-color: var(--neutral-ultra-dark);
    color: currentColor;
}

.redGreen .fcContainer .fc .fc-button-primary:not(:disabled).fc-button-active,
.redGreen .fcContainer .fc .fc-button-primary:not(:disabled):active {
    background-color: var(--red-green-secondary);
}

.blueYellow .fcContainer .fc .fc-button-primary:not(:disabled).fc-button-active,
.blueYellow .fcContainer .fc .fc-button-primary:not(:disabled):active {
    background-color: var(--blue-yellow-secondary);
}

.blackWhite .fcContainer .fc .fc-button-primary:not(:disabled).fc-button-active,
.blackWhite .fcContainer .fc .fc-button-primary:not(:disabled):active {
    background-color: var(--black-white-secondary);
}

.dark .fcContainer .fc .fc-button-primary:not(:disabled).fc-button-active,
.dark .fcContainer .fc .fc-button-primary:not(:disabled):active {
    background-color: var(--dark-secondary);
}

.redGreen .fcContainer .fc .fc-button-primary:not(:disabled).fc-button-active:hover,
.redGreen .fcContainer .fc .fc-button-primary:not(:disabled):active:hover {
    background-color: var(--red-green-secondary-light);
}

.blueYellow .fcContainer .fc .fc-button-primary:not(:disabled).fc-button-active:hover,
.blueYellow .fcContainer .fc .fc-button-primary:not(:disabled):active:hover {
    background-color: var(--blue-yellow-secondary-light);
}

.blackWhite .fcContainer .fc .fc-button-primary:not(:disabled).fc-button-active:hover,
.blackWhite .fcContainer .fc .fc-button-primary:not(:disabled):active:hover {
    background-color: var(--black-white-secondary-light);
}

.dark .fcContainer .fc .fc-button-primary:not(:disabled).fc-button-active:hover,
.dark .fcContainer .fc .fc-button-primary:not(:disabled):active:hover {
    background-color: var(--dark-secondary-light);
}

.redGreen .fcContainer .fc-prev-button,
.redGreen .fcContainer .fc-next-button {
    color: var(--red-green-secondary);
}

.blueYellow .fcContainer .fc-prev-button,
.blueYellow .fcContainer .fc-next-button {
    color: var(--blue-yellow-secondary);
}

.blackWhite .fcContainer .fc-prev-button,
.blackWhite .fcContainer .fc-next-button {
    color: var(--black-white-secondary);
}

.dark .fcContainer .fc-prev-button,
.dark .fcContainer .fc-next-button {
    color: var(--dark-secondary);
}

.redGreen .fcContainer .fc-toolbar-chunk h2 {
    color: var(--red-green-primary);
}

.blueYellow .fcContainer .fc-toolbar-chunk h2 {
    color: var(--blue-yellow-primary);
}

.blackWhite .fcContainer .fc-toolbar-chunk h2 {
    color: var(--black-white-primary);
}

.dark .fcContainer .fc-toolbar-chunk h2 {
    color: var(--dark-primary);
}

.dark .fc-daygrid-day {
    background-color: var(--neutral-ultra-dark);
}

.dark .fcContainer .fc .fc-daygrid-day.fc-day-today {
    background-color: var(--neutral-ultra-dark);
}

.redGreen .fc-more-link {
    color: var(--red-green-secondary);
}

.blueYellow .fc-more-link {
    color: var(--blue-yellow-secondary);
}

.blackWhite .fc-more-link {
    color: var(--black-white-secondary);
}

.dark .fc-more-link {
    color: var(--dark-secondary);
}

.redGreen .fc .fc-daygrid-more-link:hover {
    color: var(--red-green-secondary-light);
}

.blueYellow .fc .fc-daygrid-more-link:hover {
    color: var(--blue-yellow-secondary-light);
}

.black-white .fc .fc-daygrid-more-link:hover {
    color: var(--black-white-secondary-light);
}

.dark .fc .fc-daygrid-more-link:hover {
    color: var(--dark-secondary-light);
}

.dark [class*="RenderEventContent_eventTitle_"] {
    color: var(--white);
}

.dark .fcContainer .fc .fc-timegrid-slot-lane {
    border-bottom: 1px solid var(--neutral);
}

/* Modals */

/* Map */

.dark [class*="Modal_modalContent_"],
.dark [class*="Modal_modalContent_"] [class*="toggleContainer_"] {
    background-color: var(--neutral);
}

.redGreen [class*="Modal_modalContent_"] h4 {
    color: var(--red-green-carte);
}

.blueYellow [class*="Modal_modalContent_"] h4 {
    color: var(--blue-yellow-carte);
}

.blackWhite [class*="Modal_modalContent_"] h4 {
    color: var(--black-white-carte-dark);
}

.dark [class*="Modal_modalContent_"] h4 {
    color: var(--dark-carte);
}

.redGreen [class*="Modal_modalContent_"] [class*="greenButton"]:not([class*="buttonOutline"]),
.redGreen [class*="Button_map_"] {
    --btn-background: var(--red-green-carte);
    --btn-background-hover: var(--red-green-carte-light);
    --btn-text-color: var(--neutral);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--red-green-carte);
    --btn-border-color-hover: var(--red-green-carte-light);
    --focus-color: var(--red-green-carte-dark);
}

.blueYellow [class*="Modal_modalContent_"] [class*="greenButton"]:not([class*="buttonOutline"]),
.blueYellow [class*="Button_map_"] {
    --btn-background: var(--blue-yellow-carte);
    --btn-background-hover: var(--blue-yellow-carte-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--blue-yellow-carte);
    --btn-border-color-hover: var(--blue-yellow-carte-light);
    --focus-color: var(--blue-yellow-carte-dark);
}

.blackWhite [class*="Modal_modalContent_"] [class*="greenButton"]:not([class*="buttonOutline"]),
.blackWhite [class*="Button_map_"] {
    --btn-background: var(--black-white-carte);
    --btn-background-hover: var(--black-white-carte-light);
    --btn-text-color: var(--neutral);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--black-white-carte);
    --btn-border-color-hover: var(--black-white-carte-light);
    --focus-color: var(--black-white-carte-dark);
}

.dark [class*="Modal_modalContent_"] [class*="greenButton"]:not([class*="buttonOutline"]),
.dark [class*="Button_map_"] {
    --btn-background: var(--dark-carte);
    --btn-background-hover: var(--dark-carte-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--dark-carte);
    --btn-border-color-hover: var(--dark-carte-light);
    --focus-color: var(--dark-carte-dark);
}

.redGreen [class*="Modal_modalContent_"] [class*="greenButton"][class*="buttonOutline"] {
    --btn-background: transparent;
    --btn-background-hover: var(--red-green-carte-light);
    --btn-text-color: var(--neutral);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--neutral);
    --btn-border-color-hover: var(--red-green-carte-light);
    --focus-color: var(--red-green-carte-dark);
}

.blueYellow [class*="Modal_modalContent_"] [class*="greenButton"][class*="buttonOutline"] {
    --btn-background: transparent;
    --btn-background-hover: var(--blue-yellow-carte-light);
    --btn-text-color: var(--blue-yellow-carte);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--blue-yellow-carte);
    --btn-border-color-hover: var(--blue-yellow-carte-light);
    --focus-color: var(--blue-yellow-carte-dark);
}

.blackWhite [class*="Modal_modalContent_"] [class*="greenButton"][class*="buttonOutline"] {
    --btn-background: transparent;
    --btn-background-hover: var(--black-white-carte-light);
    --btn-text-color: var(--neutral);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--neutral);
    --btn-border-color-hover: var(--black-white-carte-light);
    --focus-color: var(--black-white-carte-dark);
}

.dark [class*="Modal_modalContent_"] [class*="greenButton"][class*="buttonOutline"] {
    --btn-background: transparent;
    --btn-background-hover: var(--dark-carte-light);
    --btn-text-color: var(--dark-carte);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--dark-carte);
    --btn-border-color-hover: var(--dark-carte-light);
    --focus-color: var(--dark-carte-dark);
}

.dark [class*="Modal_modalContent_"] [class*="toggleContainer_"]:hover {
    background-color: var(--neutral-ultra-dark);
}

.dark [class*="Modal_modalContent_"] [class*="toggleContainer_"] {
    border-color: var(--neutral-ultra-dark);
}

.redGreen [class*="Modal_modalContent_"] [class*="mapMetaInfo_"] {
    border-top: 1px solid var(--red-green-carte);
}

.blueYellow [class*="Modal_modalContent_"] [class*="mapMetaInfo_"] {
    border-top: 1px solid var(--blue-yellow-carte);
}

.blackWhite [class*="Modal_modalContent_"] [class*="mapMetaInfo_"] {
    border-top: 1px solid var(--black-white-carte);
}

.dark [class*="Modal_modalContent_"] [class*="mapMetaInfo_"] {
    border-top: 1px solid var(--dark-carte);
}

.redGreen #mapPage [class*="FilterControls_content_"] {
    --focus-color: var(--red-green-carte);
}

.blueYellow #mapPage [class*="FilterControls_content_"] {
    --focus-color: var(--blue-yellow-carte);
}

.blackWhite #mapPage [class*="FilterControls_content_"] {
    --focus-color: var(--black-white-carte);
}

.dark #mapPage [class*="FilterControls_content_"] {
    --focus-color: var(--dark-carte);
    background-color: var(--neutral);
}

.dark #mapPage [class*="FilterControls_"] [class*="toggleContainer_"] {
    background-color: var(--neutral);
    border: 1px solid var(--neutral-ultra-dark);
}

.dark #mapPage [class*="FilterControls_"] [class*="toggleContainer_"]:hover {
    background-color: var(--neutral-ultra-dark);
}

.dark #mapPage [class*="FilterControls_"] [class*="dropdown_"] {
    border: 1px solid var(--white);
    background-color: var(--neutral);
}

.dark #mapPage [class*="FilterControls_"] [class*="dropdown_"] {
    border: 1px solid var(--white);
    background-color: var(--neutral);
}

.dark #mapPage [class*="FilterControls_"] [class*="filters_"] li {
    border-top: 1px solid var(--neutral-ultra-dark);
}

.dark #mapPage [class*="FilterControls_"] [class*="filters_"] li:last-child {
    border-bottom: 1px solid var(--neutral-ultra-dark);
}

.dark #mapPage [class*="FilterControls_"] [class*="filters_"] [class*="listItem_"]:hover {
    background-color: var(--neutral-ultra-dark);
}

.dark #mapPage [class*="searchForm_"] {
    background-color: var(--neutral);
    border: var(--btn-border-width) solid var(--dark-carte);
}

.redGreen #mapPage [class*="searchForm_"] svg {
    color: var(--red-green-carte);
}

.blueYellow #mapPage [class*="searchForm_"] svg {
    color: var(--blue-yellow-carte);
}

.blackWhite #mapPage [class*="searchForm_"] svg {
    color: var(--black-white-carte);
}

.dark #mapPage [class*="searchForm_"] svg {
    color: var(--dark-carte);
}

.dark #mapPage [class*="FilterControls_subCategoryContainer_"] {
    background-color: var(--neutral-ultra-dark);
}

.dark #mapPage [class*="FilterControls_subCategoryContainer_"] [class*="subCatItem_"]:hover {
    background-color: var(--neutral-dark);
}

.redGreen #mapPage [class*="FilterControls_noFiltersMsg"] {
    color: var(--red-green-carte);
}

.blueYellow #mapPage [class*="FilterControls_noFiltersMsg"] {
    color: var(--blue-yellow-carte);
}

.blackWhite #mapPage [class*="FilterControls_noFiltersMsg"] {
    color: var(--black-white-carte-dark);
}

.dark #mapPage [class*="FilterControls_noFiltersMsg"] {
    color: var(--dark-carte);
}

.redGreen #mapPage [class*="FilterControls_"] [class*="switchInput"]:checked+[class*="switchSlider"] {
    background-color: var(--red-green-carte);
}

.redGreen #mapPage [class*="FilterControls_"] [class*="switchInput"]:checked+[class*="switchSlider"] {
    background-color: var(--red-green-carte);
}

.blueYellow #mapPage [class*="FilterControls_"] [class*="switchInput"]:checked+[class*="switchSlider"] {
    background-color: var(--blue-yellow-carte);
}

.blackWhite #mapPage [class*="FilterControls_"] [class*="switchInput"]:checked+[class*="switchSlider"] {
    background-color: var(--black-white-primary);
}

.dark #mapPage [class*="FilterControls_"] [class*="switchInput"]:checked+[class*="switchSlider"] {
    background-color: var(--dark-carte);
}

.dark #mapPage [class*="FilterControls_"] [class*="switchSlider"] {
    background-color: var(--neutral-dark);
}

.dark #mapPage [class*="FilterControls_searchForm_"] [class*="suggestions_"] {
    background-color: var(--neutral-dark);
    border: 1px solid var(--white);
}

.dark #mapPage [class*="FilterControls_searchForm_"] [class*="suggestionItem_"]:hover,
.dark #mapPage [class*="FilterControls_searchForm_"] [class*="suggestionItem_"]:focus {
    background-color: var(--neutral-ultra-dark);
}

/* Search results */

.dark [class*="resultsHeader_"] [class*="headerContainer_"] {
    background-color: var(--neutral);
}

.dark [class*="filterSection_"] .select__control {
    border-color: var(--white);
}

.dark [class*="filterSection_"] .select__multi-value[class*="css-"][class*="-multiValue"] {
    background-color: var(--neutral-ultra-dark);
}

.dark [class*="filterSection_"] .select__multi-value .select__multi-value__label {
    background-color: var(--neutral-dark);
}

.dark [class*="filterSection_"] .select__multi-value .select__multi-value__remove:hover {
    color: var(--dark-secondary-light);
}

.redGreen [class*="filterSection_"] .select__control--is-focused {
    border-color: var(--red-green-secondary);
}

.blueYellow [class*="filterSection_"] .select__control--is-focused {
    border-color: var(--blue-yellow-secondary);
}

.blackWhite [class*="filterSection_"] .select__control--is-focused {
    border-color: var(--black-white-secondary);
}

.dark [class*="filterSection_"] .select__control--is-focused {
    border-color: var(--dark-secondary);
}

/* Bandeau défilant Live Info */

.redGreen [class*="LiveInfo_section_"] {
    background-color: var(--red-green-services);
}

.blueYellow [class*="LiveInfo_section_"] {
    background-color: var(--blue-yellow-services);
}

.blackWhite [class*="LiveInfo_section_"] {
    background-color: var(--black-white-services);
}

.dark [class*="LiveInfo_section_"] {
    background-color: var(--dark-services);
}

/* Error page */

.dark [class*="ErrorPage_"][class*="error_"],
.dark [class*="ForbiddenPage_"][class*="error_"] {
    background-color: var(--neutral-ultra-dark);
}

/* Dropdown */

.redGreen [class*="Archives_dropdownContainer"] svg,
.redGreen [class*="SubCategory_dropdown_"] svg {
    color: var(--red-green-secondary);
}

.blueYellow [class*="Archives_dropdownContainer"] svg,
.blueYellow [class*="SubCategory_dropdown_"] svg {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="Archives_dropdownContainer"] svg,
.blackWhite [class*="SubCategory_dropdown_"] svg {
    color: var(--black-white-secondary);
}

.dark [class*="Archives_dropdownContainer"] [class*="viewSelect"],
.dark [class*="SubCategory_dropdown_"] [class*="select_"] {
    background-color: var(--neutral);
    border-color: var(--white);
    color: currentColor;
}

.dark [class*="Archives_dropdownContainer"] svg,
.dark [class*="SubCategory_dropdown_"] svg {
    color: var(--dark-secondary);
}

/* Legals */

.dark [class*="legalsPage"][class*="content_"] ul {
    background-color: var(--neutral-dark);
}

.redGreen [class*="legalsPage"][class*="content_"] a {
    color: var(--red-green-secondary);
}

.blueYellow [class*="legalsPage"][class*="content_"] a {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="legalsPage"][class*="content_"] a {
    color: var(--black-white-secondary);
}

.dark [class*="legalsPage"][class*="content_"] a {
    color: var(--dark-secondary-light);
}

/* Webcams */

.dark [class*="WebcamPage_intro_"] {
    background-color: var(--neutral-dark);
}

/* Coming Soon */

.dark [class*="comingSoonPage__"] {
    background-color: var(--neutral);
}

.dark [class*="ComingSoonPage_countdownSection_"] {
    background-color: var(--neutral-dark);
}

.dark [class*="ComingSoonPage_countdownItem"] {
    background-color: var(--neutral-ultra-dark);
}

.redGreen [class*="ComingSoonPage_countdownItem"]:not(:last-of-type) [class*="ComingSoonPage_countdownNumber"] {
    color: var(--red-green-secondary);
}

.blueYellow [class*="ComingSoonPage_countdownItem"]:not(:last-of-type) [class*="ComingSoonPage_countdownNumber"] {
    color: var(--blue-yellow-secondary);
}

.blackWhite [class*="ComingSoonPage_countdownItem"]:not(:last-of-type) [class*="ComingSoonPage_countdownNumber"] {
    color: var(--black-white-secondary);
}

.dark [class*="ComingSoonPage_countdownItem"]:not(:last-of-type) [class*="ComingSoonPage_countdownNumber"] {
    color: currentColor;
}

.redGreen [class*="ComingSoonPage_countdownItem"]:last-of-type [class*="ComingSoonPage_countdownNumber"] {
    color: var(--red-green-secondary-light);
}

.blueYellow [class*="ComingSoonPage_countdownItem"]:last-of-type [class*="ComingSoonPage_countdownNumber"] {
    color: var(--blue-yellow-secondary-light);
}

.blackWhite [class*="ComingSoonPage_countdownItem"]:last-of-type [class*="ComingSoonPage_countdownNumber"] {
    color: var(--black-white-secondary-ultra-light);
}

.dark [class*="ComingSoonPage_countdownItem"]:last-of-type [class*="ComingSoonPage_countdownNumber"] {
    color: var(--dark-secondary-light);
}

.redGreen [class*="comingSoonPage"] [class*="metaSection"] {
    background-color: var(--red-green-primary);
}

.blueYellow [class*="comingSoonPage"] [class*="metaSection"] {
    background-color: var(--blue-yellow-primary);
}

.blackWhite [class*="comingSoonPage"] [class*="metaSection"] {
    background-color: var(--black-white-primary);
}

.dark [class*="comingSoonPage"] [class*="metaSection"] {
    background-color: var(--dark-primary-dark);
}

/* Subsite */

.redGreen [class*="MapCta_"] [class*="subsiteButton_"] {
    --btn-background: var(--red-green-carte-ultra-dark);
    --btn-background-hover: var(--red-green-carte-dark);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--red-green-carte);
    --btn-border-color-hover: var(--red-green-carte-dark);
    --focus-color: var(--red-green-carte);
}

.blueYellow [class*="MapCta_"] [class*="subsiteButton_"] {
    --btn-background: var(--blue-yellow-carte-ultra-dark);
    --btn-background-hover: var(--blue-yellow-carte-dark);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--blue-yellow-carte-ultra-dark);
    --btn-border-color-hover: var(--blue-yellow-carte-dark);
    --focus-color: var(--blue-yellow-carte);
}

.blackWhite [class*="MapCta_"] [class*="subsiteButton_"] {
    --btn-background: var(--black-white-carte-ultra-dark);
    --btn-background-hover: var(--black-white-carte-dark);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--black-white-carte-ultra-dark);
    --btn-border-color-hover: var(--black-white-carte-dark);
    --focus-color: var(--black-white-carte);
}

.dark [class*="MapCta_"] [class*="subsiteButton_"] {
    --btn-background: var(--dark-carte);
    --btn-background-hover: var(--dark-carte-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--dark-carte);
    --btn-border-color-hover: var(--dark-carte-light);
    --focus-color: var(--dark-carte-light);
}

@media screen and (max-width: 1024px) {
    .redGreen [class*="MapCta_"] [class*="subsiteButton_"] {
        --btn-background: var(--white);
        --btn-background-hover: var(--neutral-ultra-light);
        --btn-text-color: var(--neutral);
        --btn-text-color-hover: var(--neutral);
        --btn-border-color: var(--white);
        --btn-border-color-hover: var(--neutral-ultra-light);
        --focus-color: var(--white);
    }

    .blueYellow [class*="MapCta_"] [class*="subsiteButton_"] {
        --btn-background: var(--white);
        --btn-background-hover: var(--neutral-ultra-light);
        --btn-text-color: var(--neutral);
        --btn-text-color-hover: var(--neutral);
        --btn-border-color: var(--white);
        --btn-border-color-hover: var(--neutral-ultra-light);
        --focus-color: var(--white);
    }

    .blackWhite [class*="MapCta_"] [class*="subsiteButton_"] {
        --btn-background: var(--white);
        --btn-background-hover: var(--neutral-ultra-light);
        --btn-text-color: var(--neutral);
        --btn-text-color-hover: var(--neutral);
        --btn-border-color: var(--white);
        --btn-border-color-hover: var(--neutral-ultra-light);
        --focus-color: var(--white);
    }

    .dark [class*="MapCta_"] [class*="subsiteButton_"] {
        --btn-background: var(--white);
        --btn-background-hover: var(--neutral-ultra-light);
        --btn-text-color: var(--neutral);
        --btn-text-color-hover: var(--neutral);
        --btn-border-color: var(--white);
        --btn-border-color-hover: var(--neutral-ultra-light);
        --focus-color: var(--white);
    }

    .redGreen [class*="MapCta_"] [class*="subsiteCtaContainer_"] [class*="icon_"],
    .blueYellow [class*="MapCta_"] [class*="subsiteCtaContainer_"] [class*="icon_"],
    .blackWhite [class*="MapCta_"] [class*="subsiteCtaContainer_"] [class*="icon_"],
    .dark [class*="MapCta_"] [class*="subsiteCtaContainer_"] [class*="icon_"] {
        fill: var(--white);
    }

    .redGreen [class*="MapCta_"] [class*="subsiteCtaContainer_"] [class*="content_"] {
        background-color: var(--red-green-carte);
        color: var(--white);
    }

    .blueYellow [class*="MapCta_"] [class*="subsiteCtaContainer_"] [class*="content_"] {
        background-color: var(--blue-yellow-carte);
        color: var(--white);
    }

    .blackWhite [class*="MapCta_"] [class*="subsiteCtaContainer_"] [class*="content_"] {
        background-color: var(--black-white-carte);
        color: var(--white);
    }

    .dark [class*="MapCta_"] [class*="subsiteCtaContainer_"] [class*="content_"] {
        background-color: var(--dark-carte);
        color: var(--white);
    }

}

.redGreen [class*="InfoCard_generic_"] {
    background-color: var(--red-green-primary);
}

.blueYellow [class*="InfoCard_generic_"] {
    background-color: var(--blue-yellow-primary);
}

.blackWhite [class*="InfoCard_generic_"] {
    background-color: var(--black-white-primary);
}

.dark [class*="InfoCard_generic_"] {
    background-color: var(--dark-primary);
}

.redGreen [class*="altFooter_"] {
    background-color: var(--red-green-primary-ultra-dark);
    color: var(--white);
}

.blueYellow [class*="altFooter_"] {
    background-color: var(--blue-yellow-primary-ultra-dark);
    color: var(--white);
}

.blackWhite [class*="altFooter_"] {
    background-color: var(--black-white-primary-ultra-dark);
    color: var(--white);
}

.dark [class*="altFooter_"] {
    background-color: var(--dark-primary-ultra-dark);
    color: var(--white);
}

.redGreen [class*="altFooter_"] [class*="socialLink_"]:hover {
    color: var(--red-green-secondary-light);
    /* Couleur de survol */
    fill: var(--red-green-secondary-light);
    /* Couleur de survol */
}

.blueYellow [class*="altFooter_"] [class*="socialLink_"]:hover {
    color: var(--blue-yellow-secondary-light);
    /* Couleur de survol */
    fill: var(--blue-yellow-secondary-light);
    /* Couleur de survol */
}

.blackWhite [class*="altFooter_"] [class*="socialLink_"]:hover {
    color: var(--black-white-secondary-ultra-light);
    /* Couleur de survol */
    fill: var(--black-white-secondary-ultra-light);
    /* Couleur de survol */
}

.dark [class*="altFooter_"] [class*="socialLink_"]:hover {
    color: var(--dark-secondary-light);
    /* Couleur de survol */
    fill: var(--dark-secondary-light);
    /* Couleur de survol */
}

.redGreen [class*="altFooter_"] [class*="webcamLink_"]:hover {
    fill: var(--red-green-secondary-light);
}

.blueYellow [class*="altFooter_"] [class*="webcamLink_"]:hover {
    fill: var(--blue-yellow-secondary-light);
    /* Couleur de survol */
}

.blackWhite [class*="altFooter_"] [class*="webcamLink_"]:hover {
    fill: var(--black-white-secondary-ultra-light);
    /* Couleur de survol */
}

.dark [class*="altFooter_"] [class*="webcamLink_"]:hover {
    fill: var(--dark-secondary-light);
}

.redGreen [class*="altFooter_"] [class*="block"] a:hover {
    color: var(--red-green-secondary-light);
}

.blueYellow [class*="altFooter_"] [class*="block"] a:hover {
    color: var(--blue-yellow-secondary-light);
}

.blackWhite [class*="altFooter_"] [class*="block"] a:hover {
    color: var(--black-white-secondary-ultra-light);
}

.dark [class*="altFooter_"] [class*="block"] a:hover {
    color: var(--dark-secondary-ultra-light);
}

.redGreen [class*="altFooter_"] [class*="block"] span {
    background-color: var(--red-green-secondary);
}

.blueYellow [class*="altFooter_"] [class*="block"] span {
    background-color: var(--blue-yellow-secondary);
}

.blackWhite [class*="altFooter_"] [class*="block"] span {
    background-color: var(--black-white-secondary);
}

.dark [class*="altFooter_"] [class*="block"] span {
    background-color: var(--white);
    color: var(--neutral);
}

.redGreen [class*="altFooter_"] [class*="list_"] [class*="link_"],
.blueYellow [class*="altFooter_"] [class*="list_"] [class*="link_"],
.blackWhite [class*="altFooter_"] [class*="list_"] [class*="link_"],
.dark [class*="altFooter_"] [class*="list_"] [class*="link_"],
.redGreen [class*="altFooter_"] [class*="legalItem_"],
.blueYellow [class*="altFooter_"] [class*="legalItem_"],
.blackWhite [class*="altFooter_"] [class*="legalItem_"],
.dark [class*="altFooter_"] [class*="legalItem_"] {
    color: var(--white);
}

.redGreen [class*="Header_brandSubSite"] {
    color: var(--red-green-primary);
}

.blueYellow [class*="Header_brandSubSite"] {
    color: var(--blue-yellow-primary);
}

.blackWhite [class*="Header_brandSubSite"] {
    color: var(--black-white-primary);
}

.dark [class*="Header_brandSubSite"] {
    color: var(--dark-primary);
}

.redGreen [class*="about_"] [class*="aboutSection_content_"] {
    background-color: var(--red-green-primary-ultra-dark);
}

.blueYellow [class*="about_"] [class*="aboutSection_content_"] {
    background-color: var(--blue-yellow-primary-ultra-dark);
}

.blackWhite [class*="about_"] [class*="aboutSection_content_"] {
    background-color: var(--black-white-primary-ultra-dark);
}

.dark [class*="about_"] [class*="aboutSection_content_"] {
    background-color: var(--dark-primary-ultra-dark);
}

.redGreen [class*="zoomIcon_"] {
    fill: var(--red-green-secondary);
}

.blueYellow [class*="zoomIcon_"] {
    fill: var(--blue-yellow-secondary);
}

.blackWhite [class*="zoomIcon_"] {
    fill: var(--black-white-secondary);
}

.dark [class*="zoomIcon_"] {
    fill: var(--dark-secondary);
}
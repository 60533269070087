.zoom {
    --focus-color: var(--secondary-light);
    position: absolute;
    width: 5rem;
    height: auto;
    aspect-ratio: 1;
    bottom: var(--gutter);
    right: var(--gutter);
    z-index: 10;
    background-color: var(--white);
    border-radius: var(--radius-circle);
    cursor: pointer;
}

.zoomIcon {
    fill: var(--secondary);
    width: 3rem;
}

.about .aboutContainer {
    display: grid;
    grid-template-columns: var(--grid-2);
    align-items: stretch;
    gap: 0;
}

.content {
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
    padding: var(--container-gap);
    background-color: var(--primary-ultra-dark);
    color: var(--white);
    flex: 1;
}

.buttonContainer {
    display: flex;
    gap: var(--content-gap);
    flex-wrap: wrap;
}

.slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    max-width: 800px;
  margin: 0 auto;
}

.slide {
    position: absolute;
    inset: 0; /* top: 0; right: 0; bottom: 0; left: 0; */
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    /* Pour éviter que les slides cachées ne bloquent le clic */
    pointer-events: none;
}

/* Slide active */
.slide.active {
    opacity: 1;
    z-index: 1;
    /* Rendre la diapositive "cliquable" si nécessaire */
    pointer-events: auto;}
  
/* Slide précédente (pour glissement gauche) */
.slide.previous {
    transform: translateX(-100%);
}

/* Slide suivante (pour glissement droite) */
.slide.next {
    transform: translateX(100%);
}

.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Adaptation de l'image au conteneur */
}

/* Contrôles de navigation */
.sliderControl {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2; 
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    padding: 0.5rem;
    border: none;
    cursor: pointer;
}

.controlLeft {
    left: var(--content-gap);
}

.controlRight {
    right: var(--content-gap);
}

@media screen and (max-width: 1024px) {
    .about .aboutContainer {
        grid-template-columns: var(--grid-1);
    }

    .content {
        padding: var(--content-gap);
    }
}
.actesPage .main {
    padding: var(--content-gap);
    background-color: var(--white);
    flex-direction: row;
}

.actesPage .intro {
    margin-bottom: var(--content-gap);
}

.actesPage .toggles {
    display: block;
    border-bottom: 1px solid var(--collectivite);
}

.actesPage .catToggle {
    padding-inline: var(--btn-padding-inline);
    padding-block: var(--btn-padding-block);
    font-size: var(--text-l);
    cursor: pointer;
    min-width: 14rem;
}

.actesPage .catToggle.isActive {
    background-color: var(--collectivite);
    color: var(--white);
}

.actesPage .hidden {
    display: none;
}

.actesPage button {
    transition: var(--transition);
}

.actesPage button:focus {
    outline: solid;
    outline-offset: var(--focus-offset);
    outline-color: var(--focus-color);
    outline-width: var(--focus-width);
}

.actesPage .mainColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--content-gap);
}

.block {
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
    width: 100%;
}

.actesPage h2 {
    font-size: var(--featured-heading);
}

.actesPage h3 {
    font-size: var(--h4);
}

.listContainer,
.listContent {
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
}

.listItem {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs); 
}

.block .link {
    border-bottom: 1px dotted currentColor;
    margin-inline: var(--space-xs);
    color: currentColor;
}

.yearContent,
.yearItem {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
}

.yearToggle,
.monthToggle {
    display: flex;
    gap: var(--space-xs);
    justify-content: space-between;
    align-items: center;
    padding-inline: var(--btn-padding-inline);
    padding-block: var(--btn-padding-block);
    cursor: pointer;
}

.yearToggle {
    background-color: var(--collectivite);
    color: var(--white);
}

.monthToggle {
    background-color: var(--neutral-ultra-light);
    color: var(--collectivite);
}

.monthTable {
    width: 100%;
    display: table;
    border-collapse: collapse;
    border: 1px solid var(--neutral-light);
}

.monthTable tr:not(:last-child),
.monthTable thead {
    border-bottom: 1px solid var(--neutral-light);
}

.monthTable tr td:not(:last-child),
.monthTable thead th:not(:last-child) {
    border-right: 1px solid var(--neutral-light);
}

.monthTable tr:nth-child(odd) {
    background-color: var(--neutral-ultra-light);
}

.monthTable th,
.monthTable td {
    padding-inline: var(--btn-padding-inline);
    padding-block: var(--btn-padding-block);
}

.monthTable td a {
    color: var(--secondary);
    overflow-wrap: anywhere;
}

.monthTable .icon {
    margin-right: 4px;
}



@media screen and (max-width: 1024px) {
    .actesPage .main  {
        flex-wrap: wrap;
        gap: var(--container-gap);
    }
}

@font-face {
    font-family: 'Atkinson Hyperlegible';
    src: url('/src/fonts/AtkinsonHyperlegible-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

@font-face {
    font-family: 'Atkinson Hyperlegible';
    src: url('/src/fonts/AtkinsonHyperlegible-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }

@font-face {
    font-family: 'Atkinson Hyperlegible';
    src: url('/src/fonts/AtkinsonHyperlegible-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

@font-face {
    font-family: 'Atkinson Hyperlegible';
    src: url('/src/fonts/AtkinsonHyperlegible-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  

/* Reset style du navigateur */
* {
    padding: 0;
    margin: 0;
    font-family: "Atkinson Hyperlegible", Arial, sans-serif;
}

*,
:after,
:before {
    box-sizing: border-box;
    outline: none;
}

html {
    font-size: var(--root-font-size);
    height: 100%;
    box-sizing: border-box;
    
}

button {
    background-color: initial;
    border: none;
}

@media screen and (max-width: 1024px) {
    body {
        margin-bottom: var(--responsive-navbar);
    }
}

.cls-1 { /* Conflit SVG */
    fill: inherit !important;
}

/* Style global */

body,
body .main-wrapper.standard {
    color: var(--neutral);
    background-color: var(--neutral-ultra-light);
}

footer {
    position: relative;
    z-index: 501 !important; /* Permet de passer par dessus le bandeau de Live Info */
}

article,
aside,
figcaption,
figure,
footer,
header,
main,
nav,
section,
i {
    display: block
}

figure {
    overflow: hidden;
}

img {
    height: auto;
    max-width: 100%;
    outline: none;
    vertical-align: middle;
    border-style: none;
}

input {
    background-color: transparent;
    border: none;
    color: var(--neutral);
}

a:focus {
    outline-style: solid;
    outline-color: var(--focus-color);
    outline-width: var(--focus-width);
    outline-offset: var(--focus-offset);
    transition: var(--transition);
}

[class*="global-"] {
    max-width: 100%;
}

:where(section:not(section section)) {
    padding-block: var(--section-padding-block);
    padding-inline: var(--gutter);
}

:where(a) {
    border-color: currentcolor;
    color: currentcolor;
    cursor: pointer;
    text-decoration: none
}

/* Text */

body,
input,
select {
    font-size: var(--text-m);
}

input:focus-visible,
select:focus-visible {
    transition: var(--transition);
    outline-style: solid;
    outline-color: var(--focus-color);
    outline-width: var(--focus-width);
    outline-offset: var(--focus-offset);
}

p,
li,
input {
    line-height: var(--text-line-height);
}

p {
    text-wrap: var(--text-text-wrap);
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: var(--heading-line-height);
    font-weight: 700;
}

h1 {
    font-size: var(--h1);
}

h2 {
    font-size: var(--h2);
}

h3 {
    font-size: var(--h3);
}

h4 {
    font-size: var(--h4);
}

h5 {
    font-size: var(--h5);
}

h6 {
    font-size: var(--h6);
}

:where(p) {
    margin: 0 0 1.2em
}

:where(p:last-of-type) {
    margin-bottom: 0
}

:where(p:last-of-type)+h1,
:where(p:last-of-type)+h2,
:where(p:last-of-type)+h3,
:where(p:last-of-type)+h4,
:where(p:last-of-type)+h5,
:where(p:last-of-type)+h6 {
    margin-top: 1.2em
}

.global-heading {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
}

h3.featured {
    --featured-heading: calc(var(--h3)*1.25);
    font-size: var(--featured-heading);
}

/* Containers */

.global-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    gap: var(--container-gap);
    position: relative;
    z-index: 1;
}

.page-content {
    padding-block: var(--section-padding-block);
    display: flex;
    flex-direction: column;
    gap: var(--section-padding-block);
}

.page-content .global-section {
    padding-block: 0;
}

@media (max-width:767px) {
    .global-section {
        flex-wrap: wrap
    }
}

.global-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 1440px;
    gap: var(--content-gap);
    position: relative;
}

@media (max-width:767px) {
    .global-container {
        flex-wrap: wrap
    }
}

.global-block {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media (max-width:767px) {
    .global-block {
        flex-wrap: wrap
    }
}

/* Utilitaires */

.content-gap {
    gap: var(--content-gap);
}

.container-gap {
    gap: var(--container-gap);
}

.grid-gap {
    gap: var(--content-gap);
}

ul.list--none,
ol.list--none,
.list--none>ul,
.list--none>ol {
    list-style: none;
    padding-inline-start: 0;
    list-style-type: none;
    -webkit-margin-before: 0em;
    margin-block-start: 0em;
    margin-block-end: 0;
}

.display--none {
    display: none !important;
}

.open-left {
    transform: translateX(0);
    transition: var(--transition);
}

.outline {
    outline-style: solid;
    outline-color: var(--focus-color);
    outline-width: calc(var(--focus-width)*2);
    outline-offset: calc(var(--focus-offset)*2);
}

.arrow-link {
    color: var(--secondary);
    display: flex;
    gap: calc(var(--space-xs)/2);
    align-items: center;
    line-height: var(--text-line-height);
    transition: var(--transition);
    cursor: pointer;
    position: relative;
}

.arrow-link:hover {
    color: var(--secondary-light);
}

.arrow-link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid currentColor;
    width: 0;
    transition: var(--transition);
}

.arrow-link:hover::before {
    width: 100%;
}

.separatorLine {
    width: 100%;
    height: var(--btn-border-width);
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    background-color: var(--secondary);
    z-index: -1;
    transform: translateY(-50%);
}

.no-scroll {
    overflow: hidden;
    /*height: 100%;*/
}

.grecaptcha-badge {
    z-index: 100;
}

/* Utilitaires radius */

.radius {
    border-radius: var(--radius);
}

.radius--circle {
    border-radius: var(--radius-circle);
}

.radius--xs {
    border-radius: var(--radius-xs);
}

.radius--s {
    border-radius: var(--radius-s);
}

.radius--m {
    border-radius: var(--radius-m);
}

.radius--l {
    border-radius: var(--radius-l);
}

.radius--xl {
    border-radius: var(--radius-xl);
}

.radius--xxl {
    border-radius: var(--radius-xxl);
}

/* Utilitaires Spacing */

.padding--xs {
    padding: var(--space-xs);
}

.padding--s {
    padding: var(--space-s);
}

.padding--m {
    padding: var(--space-m);
}

.padding--l {
    padding: var(--space-l);
}

.padding--xl {
    padding: var(--space-xl);
}

.padding--xxl {
    padding: var(--space-xxl);
}

.margin--xs {
    margin: var(--space-xs);
}

.margin--s {
    margin: var(--space-s);
}

.margin--m {
    margin: var(--space-m);
}

.margin--l {
    margin: var(--space-l);
}

.margin--xl {
    margin: var(--space-xl);
}

.margin--xxl {
    margin: var(--space-xxl);
}

/* Print */

@media print {
    header,
    footer,
    [class*="LiensPratiques_"],
    [class*="MapCta_"],
    [class*="nearestEvents"],
    [class*="ContentPage_actions_"],
    [class*="SocialIcons_container"],
    [class*="ContentPage_meta_"]:first-of-type,
    .grecaptcha-badge {
        display: none !important;
    }

    [class*="ContentPage_pageWrapper__"] [class*="ContentPage_pageContent__"] {
        margin-top: 0 !important;
    }

    [class*="ContentPage_headerContainer_"] {
        width: 100% !important;
        gap: var(--content-gap) !important;
        padding-bottom: 0 !important;
    }

    [class*="ContentPage_header_"] {
        flex-direction: column !important;
    }

    body {
        font-size: 12pt !important;
    }

    .global-container,
    .global-section,
    .main-wrapper,
    [class*="ContentPage_pageWrapper_"] {
        width: 100% !important;
    }

    [class*="ContentPage_"] h1 {
        font-size: 32pt !important;
    }

    [class*="ContentPage_"] h2 {
        font-size: 20pt !important;
    }

    [class*="ContentPage_"] h3,
    [class*="ContentPage_date_"] {
        font-size: 16pt !important;
    }

    [class*="ContentPage_"] h4 {
        font-size: 14pt !important;
    }

    [class*="ContentPage_main_"] {
        grid-template-columns: var(--grid-1) !important;
    }

    [class*="Badge_badge_"] {
        padding-block: 0 !important;
        padding-inline: 0 !important;
    }

    [class*="ContentPage_badgeList_"] {
        gap: var(--content-gap) !important;
    }
}
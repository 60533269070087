/* src/components/EventFilter.module.css */

.filterWrap {
  display: flex;
  flex-direction: column;
  gap: var(--content-gap);
}

.filterContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: var(--space-xs);
  }

  .filters {
    display: flex;
    gap: var(--space-xs);
    align-items: center;
    flex-wrap: wrap;
  }

  .label {
    font-weight: 700;
  }
  
  .nameFilter {
    padding-block: var(--btn-padding-block);
    padding-inline: var(--btn-padding-inline);
  }

  .dropdown {
    display: flex;
    align-items: center;
    position: relative;
  }

  .dropdown svg {
    position: absolute;
    top: 50%;
    right: calc(var(--btn-padding-inline) - (var(--text-m)/4));
    transform: translateY(-50%);
    pointer-events: none;
}

  .categoryFilter {
    background-color: var(--neutral-ultra-light);
    padding-block: var(--btn-padding-block);
    padding-inline: var(--btn-padding-inline) calc(var(--btn-padding-inline)*2);
    cursor: pointer;
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: currentColor;
}
  
  .nameFilter,
  .categoryFilter {
    min-width: var(--btn-min-width);
    min-height: calc(6px + 5ex);
    border: var(--btn-border-width) solid var(--neutral-light);
  }
  
  @media (max-width: 600px) {
    .nameFilter,
    .categoryFilter,
    .dropdown,
    .filterWrap,
    .filters {
      width: 100%;
    }

    .filterContainer {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }
  .switchWrapper {
    display: inline-flex;
    align-items: center;
    position: relative;
    gap: 0.5rem;
    cursor: pointer;
  }
  
  .toggleLabel {
    font-weight: bold;
    margin-right: 0.5rem;
  }
  
  /* On rend l'input invisible, le visuel est géré par .switchSlider */
  .switchInput {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }
  
  /* Barre grise */
  .switchSlider {
    width: 50px;
    height: 26px;
    background-color: #ccc;
    border-radius: 26px;
    position: relative;
    transition: background-color 0.3s;
  }
  
  /* Le "cercle" */
  .switchSlider::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s;
  }
  
  /* État coché */
  .switchInput:checked + .switchSlider {
    background-color: #0099ff;
  }
  
  .switchInput:checked + .switchSlider::before {
    transform: translateX(24px);
  }
  
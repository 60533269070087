/* src/components/common/SocialIcons.module.css */

.container {
    position: absolute;
    z-index: 50;
    right: var(--gutter);
    top: 50%;
    transform: translateY(-50%);
  }
  
  .socialIconsContainer {
    background-color: var(--white);
    padding: var(--space-xs) !important;
    border-radius: var(--radius-circle);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-xs);
    box-shadow: 0 0 var(--gutter) rgba(0,0,0,.2);
  }

  .socialIconsContainer li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--content-gap);
    height: var(--content-gap);
  }
  
  .socialLink {
    color: var(--neutral);
    font-size: var(--text-l);
    transition: var(--transition);
  }
  
  .socialLink:hover {
    color: var(--secondary); /* Couleur de survol */
    fill: var(--secondary); /* Couleur de survol */
  }

  .webcamLink {
    fill: var(--neutral);
    width: var(--text-l);
    height: var(--text-l);
    transition: var(--transition);
  }

  .webcamLink:hover {
    fill: var(--secondary);
  }
  
  .icon {
    display: block;
  }
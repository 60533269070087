/* Styles généraux de la carte */
.card {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  flex-grow: 1;
  position: relative;
}

.voirPlus {
  margin-left: auto;
  font-size: var(--text-m);
  transition: var(--transition);
}

.voirPlus:focus{
  outline: solid;
  outline-offset: var(--focus-offset);
  outline-color: var(--focus-color);
  outline-width: var(--focus-width);
}

/* Partie supérieure : Image */
.thumbnailContainer,
.placeholder {
  width: 100%;
  height: auto;
  aspect-ratio: 4/3;
  position: relative;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--neutral-light);
  color: var(--white);
  font-style: italic;
  font-size: var(--text-l);
}

/* Partie inférieure : Contenu */
.content {
  background-color: var(--white);
  color: var(--neutral);
  display: flex;
  flex-direction: column;
  gap: var(--content-gap);
  padding: var(--content-gap);
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
  flex: 1;
}

/* Liste de points pour les catégories */
.dotList {
  display: flex;
  gap: var(--space-xs);
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.dotList .dotItem {
  position: relative;
}

.dotList .dotItem:nth-child(n+4) {
  display: none;
}

.dotList .dotItem:nth-child(3)
{
--contextual-spacing: calc(var(--space-xs) + var(--h3));
margin-right: calc(var(--contextual-spacing) + var(--space-xs));
}

.dotList .dotItem:nth-child(3)::after {
  content: '+';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: var(--contextual-spacing);
  width: var(--h3);
  height: var(--h3);
  aspect-ratio: 1;
  border-radius: var(--radius-circle);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary);
  color: var(--primary);
  line-height: 0;
}

/* Styles du texte */
.headingContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  align-items: flex-start;
}

.title {
  margin: 0;
  font-size: calc(var(--h3)*.9);
}

.date {
  opacity: .5;
}

/* Button */

.content .buttons {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

@media screen and (max-width: 1440px) {
  .dotList {
    gap: calc(var(--space-xs)*0.75);
    flex-wrap: nowrap;
  }

  .dotList .dotItem:nth-child(3) {
    --contextual-spacing: calc((var(--space-xs)*.75) + (var(--h3)*.9));
    margin-right: calc(var(--contextual-spacing) + (var(--space-xs)*.75));
  }

  .dotList .dotItem:nth-child(3)::after {
    
    width: calc(var(--h3)*.9);
    height: calc(var(--h3)*.9);
  }
}

@media screen and (max-width: 1280px) {
  .dotList {
    gap: var(--space-xs);
    flex-wrap: wrap;
  }

  .dotList .dotItem:nth-child(3) {
    --contextual-spacing: calc(var(--space-xs) + var(--h3));
    margin-right: calc(var(--contextual-spacing) + var(--space-xs));
  }

  .dotList .dotItem:nth-child(3)::after {
    
    width: var(--h3);
    height: var(--h3);
  }
}

@media screen and (max-width: 1024px) {
  .thumbnailContainer {
    display: none;
  }
}

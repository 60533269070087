.contactPage .main {
    padding: var(--content-gap);
    background-color: var(--white);
    flex-direction: row;
}

.block {
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
    width: 100%;
}

.block h2 {
    font-size: var(--featured-heading);
}

.block form {
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
}

.formContainer {
    padding: var(--content-gap);
    background-color: var(--neutral-ultra-light);
}

.listContainer,
.listContent {
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
}

.listBlock {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    align-items: flex-start;
}

.mayorContainer {
    display: flex;
}

.orderShift h2 {
    order: 1;
}

.coordonnees {
    order: 2;
}

.figure {
    flex: 1;
}

.figure img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.listContent .metaContainer {
    background-color: var(--neutral-ultra-light);
    padding: var(--content-gap);
    flex: 1;
}

.metaContainer h4 {
    font-weight: 400;
    color: var(--secondary);
}

.block .link {
    border-bottom: 1px dotted currentColor;
    margin-inline: var(--space-xs);
    color: currentColor;
}

.listBlock p {
    margin-bottom: 0;
}

.listBlock .freePhone {
    color: var(--white);
    font-size: var(--text-l);
    line-height: var(--heading-line-height);
    display: flex;
    gap: var(--space-s);
    align-items: center;
    transition: var(--transition);
    background-color: var(--secondary);
    border-radius: var(--radius-circle);
    padding-inline: var(--btn-padding-inline);
    padding-block: calc(var(--btn-padding-block)/2);
    margin-top: var(--space-xs);
}

.listBlock .freePhone .icon {
    font-size: var(--h3);
}

.listBlock .freePhone:hover {
    background-color: var(--secondary-light);
}

.listBlock .freePhone span {
    display: block;
    letter-spacing: .5px;
    font-size: var(--text-s);
    line-height: var(--text-line-height);
}

.formGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: calc(var(--space-xs)/2);
}

.formGroup label {
    font-weight: 700;
}

.formGroup input {
    background-color: var(--white);
    width: 100%;
    padding-block: var(--btn-padding-block);
    padding-inline: var(--btn-padding-inline);
}

.formGroup textarea {
    width: 100%;
    padding-block: var(--btn-padding-block);
    padding-inline: var(--btn-padding-inline);
    border: none;
    transition: var(--transition);
    color: currentColor;
}

.formGroup textarea:focus {
    outline: solid;
    outline-color: var(--focus-color);
    outline-width: var(--focus-width);
    outline-offset: var(--focus-offset);
}

.danger {
    color: var(--inclusive);
}

.required::after {
    content: " *";
    color: var(--inclusive);
}

.dropdown {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .dropdown svg {
    position: absolute;
    top: 50%;
    right: calc(var(--btn-padding-inline) - (var(--text-m)/4));
    transform: translateY(-50%);
    pointer-events: none;
}

.dropdown select {
    background-color: var(--white);
    padding-block: var(--btn-padding-block);
    padding-inline: var(--btn-padding-inline) calc(var(--btn-padding-inline)*2);
    cursor: pointer;
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: currentColor;
    min-width: var(--btn-min-width);
    width: 100%;
    border: var(--btn-border-width) solid var(--neutral-light);
}

@media screen and (max-width: 1024px) {
    .contactPage .main  {
        flex-wrap: wrap;
        gap: var(--container-gap);
    }
}

@media screen and (max-width: 480px) {
    .mayorContainer {
        flex-direction: column;
    }
}
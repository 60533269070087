.kiosqueSection .featured {
    gap: var(--content-gap);
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
}

.kiosqueSection .featuredItem {
    flex-direction: row;
    flex: 1;
    align-items: center;
    background-color: var(--white);
}

.kiosqueSection .figure {
    display: flex;
    justify-content: center;
    height: auto;
    width: 100%;
    aspect-ratio: 3/4;
    position: relative;
    padding: var(--space-xs);
}

.kiosqueSection .figure img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.kiosqueSection .featuredContent {
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
    justify-content: center;
    padding: var(--content-gap);
    height: 100%;
}

.kiosqueSection .headingBlock {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
}

.kiosqueSection .meta {
    opacity: .5;
}

.kiosqueSection .featuredButtons {
    flex-direction: row;
    gap: var(--space-xs);
}

.kiosqueSection .miscButtons {
    justify-content: center;
    align-items: flex-end;
}

.kiosqueSection .miscItem {
    padding: var(--content-gap);
    flex-direction: row;
    gap: var(--content-gap);
    align-items: center;
    flex: 1;
}

.kiosqueSection .misc {
    align-items: stretch;
    gap: var(--content-gap);
    flex: 1;
}

.kiosqueSection .actu {
    background-color: var(--white);
}

.kiosqueSection .autres {
    background-color: var(--collectivite);
    color: var(--white);
}

@media screen and (max-width: 1440px) {
    .kiosqueSection .featured {
        flex-direction: column;
    }

    .kiosqueSection .kiosqueItem {
        flex-wrap: nowrap;
    }

    .kiosqueSection .figure {
        max-height: 50rem;
        width: initial;
    }
}

@media screen and (max-width: 767px) {
    .kiosqueSection .miscButtons {
        align-items: flex-start;
    }
}
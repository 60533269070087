/* CardBlock.module.css */
.cardBlock {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  }
  
  .blockTitle {
    font-size: var(--featured-heading);
  }

  .cardBlock + .cardBlock {
    margin-top: var(--content-gap);
  }

  .figure {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 2/3;
  }
  
  .cardsContainer {
    display: grid;
    grid-template-columns: var(--grid-4);
    gap: var(--content-gap);
  }
  
  .card {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .cardImage {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .cardContent {
    display: flex;
    flex-direction: column;
    gap: calc(var(--space-xs)/2);
    padding-block: var(--space-xs);
  }
  
  .cardTitle {
    font-size: var(--h4);
  }
  
  .cardText {
    font-size: var(--text-s);
  }
  
  @media screen and (max-width: 1280px) {
    .cardsContainer {
      grid-template-columns: var(--grid-3);
    }
  }

  @media screen and (max-width: 767px) {
    .cardsContainer {
      grid-template-columns: var(--grid-2);
    }
  }

  @media screen and (max-width: 480px) {
    .cardsContainer {
      grid-template-columns: var(--grid-1);
    }
  }
.container {
    display: flex;
    gap: var(--content-gap);
    width: 100%;
    flex-direction: column;
}

.label {
    font-size: var(--h2);
}

.li {
    display: flex;
    flex: 1;
}

.list {
    display: grid;
    grid-template-columns: var(--grid-4);
    gap: var(--space-xs);
}

.listItem {
    background-color: var(--neutral-ultra-light);
    border-radius: var(--radius-m);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--content-gap);
    flex: 1;
    padding: var(--content-gap);
    border: 1px solid var(--neutral-ultra-light);
    transition: var(--transition);
}

.listItem:hover {
    background-color: transparent;
    border: 1px solid var(--secondary);
}

.itemContent {
    display: flex;
    flex-direction: column;
}

.icon {
    margin-left: auto;
    font-size: var(--text-l);
    color: var(--secondary);
}

.cardButton {
    margin-top: auto;
}

@media screen and (max-width: 1280px) {
    .list {
        grid-template-columns: var(--grid-3);
    }
}

@media screen and (max-width: 1024px) {
    .container {
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    
    .list {
        grid-template-columns: var(--grid-2);
    }
}

@media screen and (max-width: 450px) {
    .list {
        grid-template-columns: var(--grid-1);
    }
    .listItem {
        padding: var(--space-xs);
    }
}
.burgerButton {
    height: var(--responsive-navbar);
    width: auto;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: calc(var(--space-xs)/2);
    position: relative;
    transition: var(--transition);
}

.burgerButton.isActive {
    background-color: var(--neutral-ultra-light);
}

.burgerButton:focus {
    outline: solid;
    outline-offset: calc(var(--focus-offset) * -1);
    outline-color: var(--focus-color);
    outline-width: var(--focus-width);
}

.icon {
    height: 2rem;
    width: 100%;
    fill: var(--primary);
}

.navSeparator {
    width: .25rem;
    height: 2rem;
    background-color: var(--primary);
    position: absolute;
    right: -.25rem;
}

.burger {
    height: 100%;
    width: auto;
    aspect-ratio: 1;
    position: absolute;
    display: none;
}

.line {
    height: .25rem;
    width: 2rem;
    background-color: var(--neutral);
    position: absolute;
}

.line:first-child {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.line:last-child {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
}

.isActive .icon {
    display: none;
}

.isActive .navSeparator {
    display: none;
}

.isActive .burger {
    display: flex;
}
.error {
  background-color: var(--white);
  color: currentColor;
  height: calc(100vh - var(--navbar));
  min-height: 60rem;
}

.error .container {
  margin-block: auto;
  display: flex;
  flex-direction: column;
  gap: var(--container-gap);
  align-items: center;
  justify-content: center;
}

.error .container h1,
.error .container p {
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 1024px) {
.error {
  height: calc(100vh - var(--responsive-navbar));
  min-height: 40rem;
}

.error .container {
  align-items: flex-start;
}

.error .container h1,
.error .container p {
  text-align: left;
}
}
:root {

  --black: hsla(0, 0%, 0%, 1);
  --white: hsla(0, 0%, 100%, 1);
  --transparent: hsla(0, 0, 0, 0);

  --neutral: hsla(0, 0%, 10%, 1);
  --neutral-light: hsla(0, 0%, 65%, 1);
  --neutral-medium: hsla(0, 0%, 85%, 1);
  --neutral-ultra-light: hsla(0, 0%, 95%, 1);
  --neutral-dark: hsla(0, 0%, 5%, 1);
  --neutral-ultra-dark: hsla(0, 0%, 2%, 1);

  --pharos-color: #c11b20;

  /** === DEFAULT COLORS === **/

  /** Main colors **/
  --primary: hsla(201, 29%, 30%, 1);
  --primary-light: hsla(201, 29%, 45%, 1);
  --primary-ultra-light: hsla(201, 29%, 60%, 1);
  --primary-dark: hsla(201, 29%, 20%, 1);
  --primary-ultra-dark: hsla(201, 29%, 15%, 1);

  --secondary: hsla(193, 79%, 38%, 1);
  --secondary-light: hsla(193, 79%, 50%, 1);
  --secondary-ultra-light: hsla(193, 79%, 70%, 1);
  --secondary-dark: hsla(193, 79%, 30%, 1);
  --secondary-ultra-dark: hsla(193, 79%, 15%, 1);

  /** Services **/
  --services: hsla(201, 29%, 30%, 1);
  --services-light: hsla(201, 29%, 45%, 1);
  --services-ultra-light: hsla(201, 29%, 60%, 1);
  --services-dark: hsla(201, 29%, 20%, 1);
  --services-ultra-dark: hsla(201, 29%, 15%, 1);

  /** Jeunesse **/
  --jeunesse: hsla(31, 100%, 47%, 1);
  --jeunesse-light: hsla(31, 100%, 57%, 1);
  --jeunesse-ultra-light: hsla(31, 100%, 70%, 1);
  --jeunesse-dark: hsla(31, 100%, 37%, 1);
  --jeunesse-ultra-dark: hsla(31, 100%, 25%, 1);

  /** Seniors **/
  --seniors: hsla(164, 100%, 21%, 1);
  --seniors-light: hsla(164, 100%, 31%, 1);
  --seniors-ultra-light: hsla(164, 100%, 40%, 1);
  --seniors-dark: hsla(164, 100%, 11%, 1);
  --seniors-ultra-dark: hsla(164, 100%, 5%, 1);

  /** Mobilité **/
  --mobilite: hsla(279, 61%, 22%, 1);
  --mobilite-light: hsla(279, 61%, 32%, 1);
  --mobilite-ultra-light: hsla(279, 61%, 45%, 1);
  --mobilite-dark: hsla(279, 61%, 12%, 1);
  --mobilite-ultra-dark: hsla(279, 61%, 5%, 1);

  /** Événements **/
  --events: hsla(327, 100%, 45%, 1);
  --events-light: hsla(327, 100%, 55%, 1);
  --events-ultra-light: hsla(327, 100%, 75%, 1);
  --events-dark: hsla(327, 100%, 35%, 1);
  --events-ultra-dark: hsla(327, 100%, 25%, 1);

  /** Visiter **/
  --visiter: hsla(193, 79%, 38%, 1);
  --visiter-light: hsla(193, 79%, 50%, 1);
  --visiter-ultra-light: hsla(193, 79%, 70%, 1);
  --visiter-dark: hsla(193, 79%, 28%, 1);
  --visiter-ultra-dark: hsla(193, 79%, 15%, 1);

  /** Collectivité **/
  --collectivite: hsla(210, 91%, 26%, 1);
  --collectivite-light: hsla(210, 91%, 36%, 1);
  --collectivite-ultra-light: hsla(210, 91%, 50%, 1);
  --collectivite-dark: hsla(210, 91%, 16%, 1);
  --collectivite-ultra-dark: hsla(210, 91%, 10%, 1);

  /** Inclusive **/
  --inclusive: hsla(16, 100%, 50%, 1);
  --inclusive-light: hsla(16, 100%, 60%, 1);
  --inclusive-ultra-light: hsla(16, 100%, 75%, 1);
  --inclusive-dark: hsla(16, 100%, 40%, 1);
  --inclusive-ultra-dark: hsla(16, 100%, 30%, 1);

  /** Urgence **/
  --urgence: hsla(356, 95%, 46%, 1);
  --urgence-light: hsla(356, 95%, 56%, 1);
  --urgence-ultra-light: hsla(356, 95%, 75%, 1);
  --urgence-dark: hsla(356, 95%, 36%, 1);
  --urgence-ultra-dark: hsla(356, 95%, 25%, 1);

  /** Carte **/
  --carte: hsla(80, 100%, 37%, 1);
  --carte-light: hsla(80, 100%, 47%, 1);
  --carte-ultra-light: hsla(80, 100%, 70%, 1);
  --carte-dark: hsla(80, 100%, 27%, 1);
  --carte-ultra-dark: hsla(80, 100%, 15%, 1);

  /** Contact **/
  --contact: hsla(201, 45%, 68%, 1);
  --contact-light: hsla(201, 45%, 78%, 1);
  --contact-ultra-light: hsla(201, 45%, 90%, 1);
  --contact-dark: hsla(201, 45%, 58%, 1);
  --contact-ultra-dark: hsla(201, 45%, 48%, 1);

  /** Partenaires **/
  --partenaires: hsla(43, 89%, 38%, 1);
  --partenaires-light: hsla(43, 89%, 48%, 1);
  --partenaires-ultra-light: hsla(43, 89%, 60%, 1);
  --partenaires-dark: hsla(43, 89%, 28%, 1);
  --partenaires-ultra-dark: hsla(43, 89%, 18%, 1);

  /** Pinned **/
  --pinned: hsla(45, 90%, 60%, 1);
  --pinned-light: hsla(45, 90%, 70%, 1);
  --pinned-ultra-light: hsla(45, 90%, 85%, 1);
  --pinned-dark: hsla(45, 90%, 35%, 1);
  --pinned-ultra-dark: hsla(45, 90%, 15%, 1);

  /** === RED GREEN === **/

  /** Main colors **/
  --red-green-primary: hsla(210, 20%, 33%, 1);
  --red-green-primary-light: hsla(210, 20%, 40%, 1);
  --red-green-primary-ultra-light: hsla(210, 20%, 55%, 1);
  --red-green-primary-dark: hsla(210, 20%, 25%, 1);
  --red-green-primary-ultra-dark: hsla(210, 20%, 15%, 1);

  --red-green-secondary: hsla(215, 60%, 52%, 1);
  --red-green-secondary-light: hsla(215, 60%, 65%, 1);
  --red-green-secondary-ultra-light: hsla(215, 60%, 80%, 1);
  --red-green-secondary-dark: hsla(215, 60%, 45%, 1);
  --red-green-secondary-ultra-dark: hsla(215, 60%, 30%, 1);

  /** Services **/
  --red-green-services: hsla(210, 20%, 33%, 1);
  --red-green-services-light: hsla(210, 20%, 40%, 1);
  --red-green-services-ultra-light: hsla(210, 20%, 55%, 1);
  --red-green-services-dark: hsla(210, 20%, 25%, 1);
  --red-green-services-ultra-dark: hsla(210, 20%, 15%, 1);

  /** Jeunesse **/
  --red-green-jeunesse: hsla(51, 100%, 40%, 1);
  --red-green-jeunesse-light: hsla(51, 100%, 50%, 1);
  --red-green-jeunesse-ultra-light: hsla(51, 100%, 65%, 1);
  --red-green-jeunesse-dark: hsla(51, 100%, 30%, 1);
  --red-green-jeunesse-ultra-dark: hsla(51, 100%, 20%, 1);

  /** Seniors **/
  --red-green-seniors: hsla(146, 50%, 36%, 1);
  --red-green-seniors-light: hsla(146, 50%, 46%, 1);
  --red-green-seniors-ultra-light: hsla(146, 50%, 55%, 1);
  --red-green-seniors-dark: hsla(146, 50%, 26%, 1);
  --red-green-seniors-ultra-dark: hsla(146, 50%, 16%, 1);

  /** Mobilité **/
  --red-green-mobilite: hsla(248, 72%, 72%, 1);
  --red-green-mobilite-light: hsla(248, 72%, 82%, 1);
  --red-green-mobilite-ultra-light: hsla(248, 72%, 90%, 1);
  --red-green-mobilite-dark: hsla(248, 72%, 62%, 1);
  --red-green-mobilite-ultra-dark: hsla(248, 72%, 52%, 1);

  /** Événements **/
  --red-green-events: hsla(303, 60%, 64%, 1);
  --red-green-events-light: hsla(303, 60%, 74%, 1);
  --red-green-events-ultra-light: hsla(303, 60%, 84%, 1);
  --red-green-events-dark: hsla(303, 60%, 54%, 1);
  --red-green-events-ultra-dark: hsla(303, 60%, 44%, 1);

  /** Visiter **/
  --red-green-visiter: hsla(215, 60%, 52%, 1);
  --red-green-visiter-light: hsla(215, 60%, 62%, 1);
  --red-green-visiter-ultra-light: hsla(215, 60%, 72%, 1);
  --red-green-visiter-dark: hsla(215, 60%, 42%, 1);
  --red-green-visiter-ultra-dark: hsla(215, 60%, 32%, 1);

  /** Collectivité **/
  --red-green-collectivite: hsla(210, 70%, 59%, 1);
  --red-green-collectivite-light: hsla(210, 70%, 69%, 1);
  --red-green-collectivite-ultra-light: hsla(210, 70%, 80%, 1);
  --red-green-collectivite-dark: hsla(210, 70%, 49%, 1);
  --red-green-collectivite-ultra-dark: hsla(210, 70%, 39%, 1);

  /** Inclusive **/
  --red-green-inclusive: hsla(17, 100%, 74%, 1);
  --red-green-inclusive-light: hsla(17, 100%, 84%, 1);
  --red-green-inclusive-ultra-light: hsla(17, 100%, 92%, 1);
  --red-green-inclusive-dark: hsla(17, 100%, 64%, 1);
  --red-green-inclusive-ultra-dark: hsla(17, 100%, 54%, 1);

  /** Urgence **/
  --red-green-urgence: hsla(0, 100%, 27%, 1);
  --red-green-urgence-light: hsla(0, 100%, 37%, 1);
  --red-green-urgence-ultra-light: hsla(0, 100%, 50%, 1);
  --red-green-urgence-dark: hsla(0, 100%, 17%, 1);
  --red-green-urgence-ultra-dark: hsla(0, 100%, 10%, 1);

  /** Carte **/
  --red-green-carte: hsla(84, 100%, 59%, 1);
  --red-green-carte-light: hsla(84, 100%, 69%, 1);
  --red-green-carte-ultra-light: hsla(84, 100%, 80%, 1);
  --red-green-carte-dark: hsla(84, 100%, 49%, 1);
  --red-green-carte-ultra-dark: hsla(84, 100%, 39%, 1);

  /** Contact **/
  --red-green-contact: hsla(214, 28%, 82%, 1);
  --red-green-contact-light: hsla(214, 28%, 90%, 1);
  --red-green-contact-ultra-light: hsla(214, 28%, 95%, 1);
  --red-green-contact-dark: hsla(214, 28%, 72%, 1);
  --red-green-contact-ultra-dark: hsla(214, 28%, 62%, 1);

  /** Partenaires **/
  --red-green-partenaires: hsla(43, 74%, 50%, 1);
  --red-green-partenaires-light: hsla(43, 74%, 60%, 1);
  --red-green-partenaires-ultra-light: hsla(43, 74%, 72%, 1);
  --red-green-partenaires-dark: hsla(43, 74%, 40%, 1);
  --red-green-partenaires-ultra-dark: hsla(43, 74%, 30%, 1);

  /** Pinned **/
  --red-green-pinned: hsla(48, 85%, 58%, 1);
  --red-green-pinned-light: hsla(48, 85%, 70%, 1);
  --red-green-pinned-ultra-light: hsla(48, 85%, 85%, 1);
  --red-green-pinned-dark: hsla(48, 85%, 35%, 1);
  --red-green-pinned-ultra-dark: hsla(48, 85%, 15%, 1);

  /** === BLUE YELLOW === **/

  /** Main colors **/
  --blue-yellow-primary: hsla(210, 50%, 42%, 1);
  --blue-yellow-primary-light: hsla(210, 50%, 55%, 1);
  --blue-yellow-primary-ultra-light: hsla(210, 50%, 70%, 1);
  --blue-yellow-primary-dark: hsla(210, 50%, 30%, 1);
  --blue-yellow-primary-ultra-dark: hsla(210, 50%, 20%, 1);

  --blue-yellow-secondary: hsla(198, 60%, 45%, 1);
  --blue-yellow-secondary-light: hsla(198, 60%, 55%, 1);
  --blue-yellow-secondary-ultra-light: hsla(198, 60%, 70%, 1);
  --blue-yellow-secondary-dark: hsla(198, 60%, 35%, 1);
  --blue-yellow-secondary-ultra-dark: hsla(198, 60%, 20%, 1);

  /** Services **/
  --blue-yellow-services: hsla(210, 50%, 42%, 1);
  --blue-yellow-services-light: hsla(210, 50%, 55%, 1);
  --blue-yellow-services-ultra-light: hsla(210, 50%, 70%, 1);
  --blue-yellow-services-dark: hsla(210, 50%, 30%, 1);
  --blue-yellow-services-ultra-dark: hsla(210, 50%, 20%, 1);

  /** Jeunesse **/
  --blue-yellow-jeunesse: hsla(0, 53%, 58%, 1);
  --blue-yellow-jeunesse-light: hsla(0, 53%, 68%, 1);
  --blue-yellow-jeunesse-ultra-light: hsla(0, 53%, 80%, 1);
  --blue-yellow-jeunesse-dark: hsla(0, 53%, 45%, 1);
  --blue-yellow-jeunesse-ultra-dark: hsla(0, 53%, 35%, 1);

  /** Seniors **/
  --blue-yellow-seniors: hsla(82, 39%, 30%, 1);
  --blue-yellow-seniors-light: hsla(82, 39%, 45%, 1);
  --blue-yellow-seniors-ultra-light: hsla(82, 39%, 60%, 1);
  --blue-yellow-seniors-dark: hsla(82, 39%, 20%, 1);
  --blue-yellow-seniors-ultra-dark: hsla(82, 39%, 15%, 1);

  /** Mobilité **/
  --blue-yellow-mobilite: hsla(248, 57%, 58%, 1);
  --blue-yellow-mobilite-light: hsla(248, 57%, 68%, 1);
  --blue-yellow-mobilite-ultra-light: hsla(248, 57%, 80%, 1);
  --blue-yellow-mobilite-dark: hsla(248, 57%, 45%, 1);
  --blue-yellow-mobilite-ultra-dark: hsla(248, 57%, 35%, 1);

  /** Événements **/
  --blue-yellow-events: hsla(322, 79%, 44%, 1);
  --blue-yellow-events-light: hsla(322, 79%, 54%, 1);
  --blue-yellow-events-ultra-light: hsla(322, 79%, 70%, 1);
  --blue-yellow-events-dark: hsla(322, 79%, 34%, 1);
  --blue-yellow-events-ultra-dark: hsla(322, 79%, 20%, 1);

  /** Visiter **/
  --blue-yellow-visiter: hsla(198, 60%, 45%, 1);
  --blue-yellow-visiter-light: hsla(198, 60%, 55%, 1);
  --blue-yellow-visiter-ultra-light: hsla(198, 60%, 70%, 1);
  --blue-yellow-visiter-dark: hsla(198, 60%, 35%, 1);
  --blue-yellow-visiter-ultra-dark: hsla(198, 60%, 20%, 1);

  /** Collectivité **/
  --blue-yellow-collectivite: hsla(210, 70%, 50%, 1);
  --blue-yellow-collectivite-light: hsla(210, 70%, 60%, 1);
  --blue-yellow-collectivite-ultra-light: hsla(210, 70%, 75%, 1);
  --blue-yellow-collectivite-dark: hsla(210, 70%, 40%, 1);
  --blue-yellow-collectivite-ultra-dark: hsla(210, 70%, 25%, 1);

  /** Inclusive **/
  --blue-yellow-inclusive: hsla(348, 83%, 47%, 1);
  --blue-yellow-inclusive-light: hsla(348, 83%, 57%, 1);
  --blue-yellow-inclusive-ultra-light: hsla(348, 83%, 70%, 1);
  --blue-yellow-inclusive-dark: hsla(348, 83%, 37%, 1);
  --blue-yellow-inclusive-ultra-dark: hsla(348, 83%, 25%, 1);

  /** Urgence **/
  --blue-yellow-urgence: hsla(0, 100%, 27%, 1);
  --blue-yellow-urgence-light: hsla(0, 100%, 37%, 1);
  --blue-yellow-urgence-ultra-light: hsla(0, 100%, 60%, 1);
  --blue-yellow-urgence-dark: hsla(0, 100%, 18%, 1);
  --blue-yellow-urgence-ultra-dark: hsla(0, 100%, 10%, 1);

  /** Carte **/
  --blue-yellow-carte: hsla(120, 61%, 35%, 1);
  --blue-yellow-carte-light: hsla(120, 61%, 45%, 1);
  --blue-yellow-carte-ultra-light: hsla(120, 61%, 70%, 1);
  --blue-yellow-carte-dark: hsla(120, 61%, 25%, 1);
  --blue-yellow-carte-ultra-dark: hsla(120, 61%, 15%, 1);

  /** Contact **/
  --blue-yellow-contact: hsla(0, 0%, 83%, 1);
  --blue-yellow-contact-light: hsla(0, 0%, 90%, 1);
  --blue-yellow-contact-ultra-light: hsla(0, 0%, 95%, 1);
  --blue-yellow-contact-dark: hsla(0, 0%, 75%, 1);
  --blue-yellow-contact-ultra-dark: hsla(0, 0%, 60%, 1);

  /** Partenaires **/
  --blue-yellow-partenaires: hsla(25, 76%, 31%, 1);
  --blue-yellow-partenaires-light: hsla(25, 76%, 45%, 1);
  --blue-yellow-partenaires-ultra-light: hsla(25, 76%, 60%, 1);
  --blue-yellow-partenaires-dark: hsla(25, 76%, 20%, 1);
  --blue-yellow-partenaires-ultra-dark: hsla(25, 76%, 10%, 1);

  /** Pinned **/
  --blue-yellow-pinned: hsla(40, 80%, 58%, 1);
  --blue-yellow-pinned-light: hsla(40, 80%, 70%, 1);
  --blue-yellow-pinned-ultra-light: hsla(40, 80%, 85%, 1);
  --blue-yellow-pinned-dark: hsla(40, 80%, 40%, 1);
  --blue-yellow-pinned-ultra-dark: hsla(40, 80%, 25%, 1);

  /** === BLACK AND WHITE === **/

  /** Main colors **/
  --black-white-primary: hsla(0, 0%, 30%, 1);
  --black-white-primary-light: hsla(0, 0%, 35%, 1);
  --black-white-primary-ultra-light: hsla(0, 0%, 50%, 1);
  --black-white-primary-dark: hsla(0, 0%, 20%, 1);
  --black-white-primary-ultra-dark: hsla(0, 0%, 10%, 1);

  --black-white-secondary: hsla(0, 0%, 40%, 1);
  --black-white-secondary-light: hsla(0, 0%, 50%, 1);
  --black-white-secondary-ultra-light: hsla(0, 0%, 70%, 1);
  --black-white-secondary-dark: hsla(0, 0%, 25%, 1);
  --black-white-secondary-ultra-dark: hsla(0, 0%, 15%, 1);

  /** Services **/
  --black-white-services: hsla(0, 0%, 30%, 1);
  --black-white-services-light: hsla(0, 0%, 35%, 1);
  --black-white-services-ultra-light: hsla(0, 0%, 50%, 1);
  --black-white-services-dark: hsla(0, 0%, 20%, 1);
  --black-white-services-ultra-dark: hsla(0, 0%, 10%, 1);

  /** Jeunesse **/
  --black-white-jeunesse: hsla(0, 0%, 70%, 1);
  --black-white-jeunesse-light: hsla(0, 0%, 75%, 1);
  --black-white-jeunesse-ultra-light: hsla(0, 0%, 85%, 1);
  --black-white-jeunesse-dark: hsla(0, 0%, 60%, 1);
  --black-white-jeunesse-ultra-dark: hsla(0, 0%, 50%, 1);

  /** Seniors **/
  --black-white-seniors: hsla(0, 0%, 50%, 1);
  --black-white-seniors-light: hsla(0, 0%, 60%, 1);
  --black-white-seniors-ultra-light: hsla(0, 0%, 70%, 1);
  --black-white-seniors-dark: hsla(0, 0%, 40%, 1);
  --black-white-seniors-ultra-dark: hsla(0, 0%, 30%, 1);

  /** Mobilité **/
  --black-white-mobilite: hsla(0, 0%, 45%, 1);
  --black-white-mobilite-light: hsla(0, 0%, 55%, 1);
  --black-white-mobilite-ultra-light: hsla(0, 0%, 65%, 1);
  --black-white-mobilite-dark: hsla(0, 0%, 35%, 1);
  --black-white-mobilite-ultra-dark: hsla(0, 0%, 25%, 1);

  /** Événements **/
  --black-white-events: hsla(0, 0%, 90%, 1);
  --black-white-events-light: hsla(0, 0%, 92%, 1);
  --black-white-events-ultra-light: hsla(0, 0%, 92%, 1);
  --black-white-events-dark: hsla(0, 0%, 80%, 1);
  --black-white-events-ultra-dark: hsla(0, 0%, 70%, 1);

  /** Visiter **/
  --black-white-visiter: hsla(0, 0%, 40%, 1);
  --black-white-visiter-light: hsla(0, 0%, 50%, 1);
  --black-white-visiter-ultra-light: hsla(0, 0%, 60%, 1);
  --black-white-visiter-dark: hsla(0, 0%, 30%, 1);
  --black-white-visiter-ultra-dark: hsla(0, 0%, 20%, 1);

  /** Collectivité **/
  --black-white-collectivite: hsla(0, 0%, 15%, 1);
  --black-white-collectivite-light: hsla(0, 0%, 25%, 1);
  --black-white-collectivite-ultra-light: hsla(0, 0%, 35%, 1);
  --black-white-collectivite-dark: hsla(0, 0%, 10%, 1);
  --black-white-collectivite-ultra-dark: hsla(0, 0%, 5%, 1);

  /** Inclusive **/
  --black-white-inclusive: hsla(0, 0%, 80%, 1);
  --black-white-inclusive-light: hsla(0, 0%, 85%, 1);
  --black-white-inclusive-ultra-light: hsla(0, 0%, 90%, 1);
  --black-white-inclusive-dark: hsla(0, 0%, 70%, 1);
  --black-white-inclusive-ultra-dark: hsla(0, 0%, 60%, 1);

  /** Urgence **/
  --black-white-urgence: hsla(0, 0%, 20%, 1);
  --black-white-urgence-light: hsla(0, 0%, 30%, 1);
  --black-white-urgence-ultra-light: hsla(0, 0%, 40%, 1);
  --black-white-urgence-dark: hsla(0, 0%, 15%, 1);
  --black-white-urgence-ultra-dark: hsla(0, 0%, 10%, 1);

  /** Carte **/
  --black-white-carte: hsla(0, 0%, 85%, 1);
  --black-white-carte-light: hsla(0, 0%, 90%, 1);
  --black-white-carte-ultra-light: hsla(0, 0%, 90%, 1);
  --black-white-carte-dark: hsla(0, 0%, 75%, 1);
  --black-white-carte-ultra-dark: hsla(0, 0%, 65%, 1);

  /** Contact **/
  --black-white-contact: hsla(0, 0%, 90%, 1);
  --black-white-contact-light: hsla(0, 0%, 92%, 1);
  --black-white-contact-ultra-light: hsla(0, 0%, 92%, 1);
  --black-white-contact-dark: hsla(0, 0%, 80%, 1);
  --black-white-contact-ultra-dark: hsla(0, 0%, 70%, 1);

  /** Partenaires **/
  --black-white-partenaires: hsla(0, 0%, 55%, 1);
  --black-white-partenaires-light: hsla(0, 0%, 65%, 1);
  --black-white-partenaires-ultra-light: hsla(0, 0%, 75%, 1);
  --black-white-partenaires-dark: hsla(0, 0%, 45%, 1);
  --black-white-partenaires-ultra-dark: hsla(0, 0%, 35%, 1);

  /** Pinned **/
  --black-white-pinned: hsla(0, 0%, 75%, 1);
  --black-white-pinned-light: hsla(0, 0%, 85%, 1);
  --black-white-pinned-ultra-light: hsla(0, 0%, 92%, 1);
  --black-white-pinned-dark: hsla(0, 0%, 55%, 1);
  --black-white-pinned-ultra-dark: hsla(0, 0%, 45%, 1);

  /** === DARK THEME === **/

  /** Main colors **/
  --dark-primary: hsla(193, 79%, 50%, 1);
  --dark-primary-light: hsla(193, 79%, 65%, 1);
  --dark-primary-ultra-light: hsla(193, 79%, 75%, 1);
  --dark-primary-dark: hsla(193, 79%, 35%, 1);
  --dark-primary-ultra-dark: hsla(193, 79%, 20%, 1);

  --dark-secondary: hsla(193, 79%, 38%, 1);
  --dark-secondary-light: hsla(193, 79%, 50%, 1);
  --dark-secondary-ultra-light: hsla(193, 79%, 65%, 1);
  --dark-secondary-dark: hsla(193, 79%, 23%, 1);
  --dark-secondary-ultra-dark: hsla(193, 79%, 8%, 1);

  /** Services **/
  --dark-services: hsla(201, 29%, 30%, 1);
  --dark-services-light: hsla(201, 29%, 45%, 1);
  --dark-services-ultra-light: hsla(201, 29%, 60%, 1);
  --dark-services-dark: hsla(201, 29%, 20%, 1);
  --dark-services-ultra-dark: hsla(201, 29%, 15%, 1);

  /** Jeunesse **/
  --dark-jeunesse: hsla(31, 100%, 47%, 1);
  --dark-jeunesse-light: hsla(31, 100%, 57%, 1);
  --dark-jeunesse-ultra-light: hsla(31, 100%, 70%, 1);
  --dark-jeunesse-dark: hsla(31, 100%, 37%, 1);
  --dark-jeunesse-ultra-dark: hsla(31, 100%, 25%, 1);

  /** Seniors **/
  --dark-seniors: hsla(164, 100%, 21%, 1);
  --dark-seniors-light: hsla(164, 100%, 31%, 1);
  --dark-seniors-ultra-light: hsla(164, 100%, 40%, 1);
  --dark-seniors-dark: hsla(164, 100%, 11%, 1);
  --dark-seniors-ultra-dark: hsla(164, 100%, 5%, 1);

  /** Mobilité **/
  --dark-mobilite: hsla(279, 61%, 30%, 1);
  --dark-mobilite-light: hsla(279, 61%, 40%, 1);
  --dark-mobilite-ultra-light: hsla(279, 61%, 53%, 1);
  --dark-mobilite-dark: hsla(279, 61%, 20%, 1);
  --dark-mobilite-ultra-dark: hsla(279, 61%, 13%, 1);

  /** Événements **/
  --dark-events: hsla(327, 100%, 45%, 1);
  --dark-events-light: hsla(327, 100%, 55%, 1);
  --dark-events-ultra-light: hsla(327, 100%, 75%, 1);
  --dark-events-dark: hsla(327, 100%, 35%, 1);
  --dark-events-ultra-dark: hsla(327, 100%, 25%, 1);

  /** Visiter **/
  --dark-visiter: hsla(193, 79%, 38%, 1);
  --dark-visiter-light: hsla(193, 79%, 50%, 1);
  --dark-visiter-ultra-light: hsla(193, 79%, 70%, 1);
  --dark-visiter-dark: hsla(193, 79%, 28%, 1);
  --dark-visiter-ultra-dark: hsla(193, 79%, 15%, 1);

  /** Collectivité **/
  --dark-collectivite: hsla(210, 91%, 26%, 1);
  --dark-collectivite-light: hsla(210, 91%, 36%, 1);
  --dark-collectivite-ultra-light: hsla(210, 91%, 50%, 1);
  --dark-collectivite-dark: hsla(210, 91%, 16%, 1);
  --dark-collectivite-ultra-dark: hsla(210, 91%, 10%, 1);

  /** Inclusive **/
  --dark-inclusive: hsla(16, 100%, 50%, 1);
  --dark-inclusive-light: hsla(16, 100%, 60%, 1);
  --dark-inclusive-ultra-light: hsla(16, 100%, 75%, 1);
  --dark-inclusive-dark: hsla(16, 100%, 40%, 1);
  --dark-inclusive-ultra-dark: hsla(16, 100%, 30%, 1);

  /** Urgence **/
  --dark-urgence: hsla(356, 95%, 46%, 1);
  --dark-urgence-light: hsla(356, 95%, 56%, 1);
  --dark-urgence-ultra-light: hsla(356, 95%, 75%, 1);
  --dark-urgence-dark: hsla(356, 95%, 36%, 1);
  --dark-urgence-ultra-dark: hsla(356, 95%, 25%, 1);

  /** Carte **/
  --dark-carte: hsla(80, 100%, 37%, 1);
  --dark-carte-light: hsla(80, 100%, 47%, 1);
  --dark-carte-ultra-light: hsla(80, 100%, 70%, 1);
  --dark-carte-dark: hsla(80, 100%, 27%, 1);
  --dark-carte-ultra-dark: hsla(80, 100%, 15%, 1);

  /** Contact **/
  --dark-contact: hsla(201, 45%, 68%, 1);
  --dark-contact-light: hsla(201, 45%, 78%, 1);
  --dark-contact-ultra-light: hsla(201, 45%, 90%, 1);
  --dark-contact-dark: hsla(201, 45%, 58%, 1);
  --dark-contact-ultra-dark: hsla(201, 45%, 48%, 1);

  /** Partenaires **/
  --dark-partenaires: hsla(43, 89%, 38%, 1);
  --dark-partenaires-light: hsla(43, 89%, 48%, 1);
  --dark-partenaires-ultra-light: hsla(43, 89%, 60%, 1);
  --dark-partenaires-dark: hsla(43, 89%, 28%, 1);
  --dark-partenaires-ultra-dark: hsla(43, 89%, 18%, 1);

  /** Pinned **/
  --dark-pinned: hsla(45, 90%, 60%, 1);
  --dark-pinned-light: hsla(45, 90%, 70%, 1);
  --dark-pinned-ultra-light: hsla(45, 90%, 85%, 1);
  --dark-pinned-dark: hsla(45, 90%, 35%, 1);
  --dark-pinned-ultra-dark: hsla(45, 90%, 15%, 1);

  /** === FC === **/

  --fc-today-bg-color: var(--neutral-medium);
  --fc-neutral-bg-color: var(--neutral-medium);
}

/** === PHAROS === **/

:root {
  --pharos: #c11b20;
  --red-green-pharos: #d35a3b;
  --blue-yellow-pharos: #b7282c;
  --black-white-pharos: var(--black-white-events);
  --dark-pharos : #c11b20;
}



/** === AUTRES === **/

.sr-only {
  position: absolute;
  /* Positionnement hors du flux normal */
  width: 1px;
  /* Largeur et hauteur minimales pour cacher l'élément visuellement */
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  /* Masquer tout débordement */
  clip: rect(0, 0, 0, 0);
  /* Clipper l'élément pour qu'il ne soit pas visible */
  border: 0;
}
.evenements {
    background-color: var(--events);
    color: var(--white);
    fill: currentColor;
}

.enfance-et-jeunesse {
    background-color: var(--jeunesse);
    color: var(--white);
    fill: currentColor;
}

.votre-collectivite {
    background-color: var(--collectivite);
    color: var(--white);
    fill: currentColor;
}

.seniors {
    background-color: var(--seniors);
    color: var(--white);
    fill: currentColor;
}

.mobilite {
    background-color: var(--mobilite);
    color: var(--white);
    fill: currentColor;
}

.services-et-demarches {
    background-color: var(--services);
    color: var(--white);
    fill: currentColor;
}

.carte {
    background-color: var(--carte);
    color: var(--white);
    fill: currentColor;
}

.visiter-arras {
    background-color: var(--visiter);
    color: var(--white);
    fill: currentColor;
}

.arras-ville-dynamique {
    background-color: var(--inclusive);
    color: var(--white);
    fill: currentColor;
}

.partenaires {
    background-color: var(--partenaires);
    color: var(--white);
    fill: currentColor;
}

.partenaires {
    background-color: var(--partenaires);
    color: var(--white);
    fill: currentColor;
}

.numeros-d-urgence {
    background-color: var(--urgence);
    color: var(--white);
    fill: currentColor;
}

.contact {
    background-color: var(--contact);
    color: var(--white);
    fill: currentColor;
}
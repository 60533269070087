/* src/components/calendar/ListView.module.css */
.listView {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  width: 100%;
}

.tagList {
  display: flex;
  gap: var(--space-xs);
  flex-wrap: wrap;
}

.eventLocation {
  font-weight: 700;
}
/* Vous pouvez supprimer les styles du bouton "Voir plus" si vous en aviez */
  
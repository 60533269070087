.container [class*="_grid_"] {
    display: grid;
    grid-template-columns: var(--grid-1);
    gap: var(--space-xs);
}

.container .categoryList {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
}

.container .categoryList li {
    font-weight: 700;
    position: relative;
    display: flex;
    align-items: center;
    padding-right: calc(var(--content-gap) / 2);
    margin-right: calc(var(--content-gap) / 2);
}

.container .categoryList li:last-child {
    padding-right: 0;
    margin-right: 0;
}

.container .categoryList li:not(:last-child)::after {
    content: '';
    position: absolute;
    width: var(--btn-border-width);
    height: calc(var(--text-line-height) / 2);
    background-color: currentColor;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.eventContent {
  display: flex;
  align-items: center;
}

.dot {
  width: var(--text-s);
  height: var(--text-s);
  aspect-ratio: 1;
  border-radius: var(--radius-circle);
  display: inline-block;
  margin-right: calc(var(--space-xs)/2);
}

.defaultDot {
  background-color: var(--events);
}

.core {
  background-color: var(--urgence);
}

.eventTitle {
  font-size: var(--text-s);
  color: var(--neutral);
  overflow: hidden;
  text-overflow: ellipsis;
}


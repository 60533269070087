/* Modal.module.css */
.modalOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - var(--navbar));
    background-color: rgba(0,0,0,0.35);
    /* Fond semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 998;
    /* S'assurer que le modal est au-dessus des autres éléments */
  }
  
  .modalContainer {
    position: absolute;
    top: 0;
    left: 0;
  }

  .modalContent {
    background-color: var(--white);
    padding-block: var(--gutter);
    width: 450px;
    height: calc(100vh - var(--navbar));
    outline: none;
    z-index: 995;
    display: flex;
    flex-direction: column;
    gap: var(--gutter);
    /* Supprimer le contour par défaut */
    overflow-y: auto;/* Scrollbar Firefox */
    scrollbar-width: var(--firefox-width);
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
  }
  
  .modalContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px; /* hauteur de l'ombre */
    pointer-events: none; 
    /* Gradient du noir semi-transparent vers transparent pour un effet "ombre" */
    background: linear-gradient(to bottom, rgba(0,0,0,0.1), transparent);
  }
  
  .modalContent h4 {
    color: var(--carte);
  }
  
  .heading,
  .mapMetaInfo,
  .mapModalBlock,
  .figure {
    padding-inline: var(--gutter);
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .mapModalInfo,
  .mapMetaInfo,
  .mapModalBlock {
    display: flex;
    flex-direction: column;
  }
  
  .mapModalInfo {
    gap: var(--content-gap);
    height: 100%;
  }
  
  /* Scrollbar base Chromium */
  
  .modalContent::-webkit-scrollbar {
    width: var(--chromium-width);
    height: var(--chromium-height);
  }
  
  .modalContent::-webkit-scrollbar-track {
    background: var(--scrollbar-track);
  }
  
  .modalContent::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb);
    border: 2px solid transparent;
  }
  
  .mapMetaInfo,
  .mapModalBlock {
    gap: var(--space-xs);
  }
  
  .mapMetaInfo {
    border-top: 1px solid var(--carte);
  }
  
  .mapMetaInfo p:first-child {
    padding-top: var(--gutter);
  }
  
  .mapMetaInfo em {
    opacity: .5;
  }
  
  .modalContent .mapModalInfo p {
    margin: 0;
  }
  
  .toggleContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 5rem;
    width: 3rem;
    right: -3rem;
    padding-inline: .5rem;
    z-index: 1000;
    background-color: var(--white);
    border: 1px solid var(--neutral-light);
    cursor: pointer;
    transition: var(--transition);
    color: currentColor;
  }
  
  .heading {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
  }
  
  .figure {
    position: relative;
    width: 100%;
    height: 30rem;
    min-height: 30rem;
    overflow: hidden;
  }
  
  .figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .toggleContainer:hover {
    background-color: var(--neutral-ultra-light);
  }
  
  .toggleContainer:focus {
    outline: solid;
    outline-offset: var(--focus-offset);
    outline-color: var(--focus-color);
    outline-width: var(--focus-width);
  }
  
  .toggle {
    margin: auto;
    display: flex;
    align-items: center;
  }
  
  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-xs);
    padding-inline: var(--gutter);
  }
  
  .greenButton {
    --btn-background: var(--carte);
    --btn-background-hover: var(--carte-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--carte);
    --btn-border-color-hover: var(--carte-light);
    --focus-color: var(--carte-dark);
    --btn-font-size: var(--text-m);
    --btn-min-width: auto;
  }
  
  .greenButton.buttonOutline {
    --btn-background: transparent;
    --btn-background-hover: var(--carte-light);
    --btn-text-color: var(--carte);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--carte);
    --btn-border-color-hover: var(--carte-light);
    --focus-color: var(--carte-dark);
  }
  
  @media screen and (max-width: 1024px) {
    .modalOverlay {
      top: 0;
      height: calc(100vh - var(--responsive-navbar));
      width: 100%;
    }

    .modalContainer {
      width: 100%;
    }
  
    .modalContent {
      width: calc(100% - 4rem);
      height: calc(100vh - var(--responsive-navbar));
    }

    .toggleContainer {
      right: 1rem;
    }
  }
  
  @media screen and (max-width: 400px) {
    .greenButton {
      --btn-font-size: var(--text-s);
    }
  
    .figure {
      height: 20rem;
      min-height: 20rem;
    }
  }
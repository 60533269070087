.smartSection .container {
    flex-direction: row;
    gap: var(--content-gap);
}

.smartSection .item {
    aspect-ratio: 1;
    position: relative;
    flex: 1;
    overflow: hidden;
}

.smartSection .content {
    --focus-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
    padding-inline: var(--section-space-l) var(--content-gap);
    padding-block: var(--content-gap);
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    flex: 1;
    position: relative;
    z-index: 1;
}

.smartSection .content svg {
    width: var(--h1);
}

.smartSection .content svg {
    fill: var(--white);
}

.smartSection .figure {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.smartSection .figure img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.smartSection .buttons {
    display: flex;
    gap: var(--space-xs);
    flex-wrap: wrap;
}

.smartSection .appBadge {
    height: calc(var(--text-line-height) + (var(--btn-padding-block)*3));
    width: auto;
    object-fit: contain;
}

.smartSection .figurePharos {
    transform: rotate(-90deg);
}

.smartSection .figurePharos img {
    padding: var(--content-gap);
    height: var(--section-space-l);
    margin-bottom: auto;
}

.smartSection .figurePhone {
    width: var(--section-space-l);
    align-self: center;
}

.smartSection .figurePhone img {
    width: initial;
    height: initial;
}

.smartSection .bgPharos {
    background-color: var(--pharos);
    color: var(--white);
}

.smartSection .bgApp {
    background-color: var(--services);
    color: var(--white);
}

.smartSection .bgThelma {
    background-color: var(--visiter);
    color: var(--white);
}

.comingSoon {
    font-size: var(--h3);
    filter: drop-shadow(0 0 5px rgba(0,0,0,.5));
}

.smartSection .vivreArrasIcon {
    height: 8rem;
    width: auto;
    object-fit: contain;
}

@media screen and (max-width: 1440px) {
    .smartSection .content [class*="featured"] {
        font-size: calc(var(--h3)*1.1);
    }
}

@media screen and (max-width: 1240px) {
    .smartSection .container {
        flex-direction: column;
    }

    .smartSection .content [class*="featured"] {
        font-size: var(--featured-heading);
    }

    .smartSection .item {
        aspect-ratio: initial;
    }

    .smartSection .item.bgApp {
        min-height: 25rem;
    }

    .smartSection .content {
        padding-inline: var(--content-gap) var(--section-space-l);
    }

    .smartSection .figurePharos {
        position: relative;
        transform: rotate(0);
        order: -1;
        padding-top: var(--content-gap);
        padding-inline: var(--content-gap) var(--section-space-l);
        justify-content: flex-start;
    }

    .smartSection .figurePharos img {
        height: var(--h2);
        width: auto;
        padding: 0;
    }

    .smartSection .figurePhone {
        margin-left: auto;
    }

    .smartSection .responsiveHidden {
        position: absolute;
        left: -99999px;
    }
}

@media screen and (max-width: 600px) {
    .smartSection .figure:not(.figurePharos) {
        display: none !important;
    }

    .smartSection .item.bgApp {
        min-height: initial;
    }

    .smartSection .content {
        padding-inline: var(--content-gap);
    }

    .smartSection .figurePharos {
        padding-inline: var(--content-gap);
    }
}
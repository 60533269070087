/* styles/modules/Gallery.module.css */
.gallery {
    display: grid;
    grid-template-columns: var(--grid-3);
    flex-wrap: wrap;
    gap: var(--content-gap);
  }
  
  .galleryItem {
    display: flex;
    flex-direction: column;
  }
  
  .galleryImage {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    object-fit: cover;
  }
  
  .caption {
    font-size: var(--text-m);
    line-height: var(--text-line-height);
  }
  
  @media screen and (max-width: 1024px) {
    .gallery {
      grid-template-columns: var(--grid-2);
    }
  }

  @media screen and (max-width: 600px) {
    .gallery {
      grid-template-columns: var(--grid-1);
    }

    .galleryImage {
      width: 100%;
      height: 100%;
      aspect-ratio: initial;
      object-fit: contain;
    }
  }
.badge {
  padding-block: var(--btn-padding-block);
  padding-inline: var(--btn-padding-inline);
  border-radius: var(--radius-circle);
  }
  
  .primary {
    background-color: #007bff;
    color: #fff;
  }
  
  .secondary {
    background-color: #6c757d;
    color: #fff;
  }


  
  .badge:hover {
    opacity: 0.9;
  }
  
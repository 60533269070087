.fileList {
  margin-block: var(--content-gap) !important;
}

.fileItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: var(--space-xs);
  gap: var(--content-gap);
}

.fileItem:not(:last-child) {
  border-bottom: 1px solid var(--secondary);
}

.fileInfo {
  flex: 1;
}

.fileName {
  font-weight: bold;
  margin: 0;
}

.fileDescription {
  font-style: italic;
  opacity: .5;
}

.fileActions {
  display: flex;
  align-items: center;
  gap: var(--space-xs);
}

.fileIcon {
  color: var(--secondary);
  font-size: var(--text-xl);
}

.icon {
  width: var(--space-l);
  height: var(--space-l);
  border-radius: var(--radius-circle);
  background-color: var(--secondary);
  color: var(--white);
  cursor: pointer;
  font-size: var(--text-m);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}

.icon:hover {
  background-color: var(--secondary-light);
}

.icon:focus {
  outline: solid;
  outline-offset: var(--focus-offset);
  outline-color: var(--focus-color);
  outline-width: var(--focus-width);
}
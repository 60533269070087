.container {
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
}

.container .list,
.container .block {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    align-items: flex-start;
}

.container .list li {
    display: flex;
}

.list .link {
    color: var(--white);
}

.container .content {
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
}

.block {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
    align-items: flex-start;
}

.block p {
    margin-bottom: 0;
}

.block .freePhone {
    font-size: var(--text-l);
    line-height: var(--heading-line-height);
    display: flex;
    gap: var(--space-s);
    align-items: center;
    transition: var(--transition);
    background-color: var(--secondary);
    border-radius: var(--radius-circle);
    padding-inline: var(--btn-padding-inline);
    padding-block: calc(var(--btn-padding-block)/2);
    margin-top: var(--space-xs);
}

.block .freePhone .icon {
    font-size: var(--h3);
}

.block .freePhone:hover {
    background-color: var(--secondary-light);
}

.block .freePhone span {
    display: block;
    letter-spacing: .5px;
    font-size: var(--text-s);
    line-height: var(--text-line-height);
}

/* Numéros d'urgence */

.urgencyItem {
    gap: var(--space-xs);
    
}

.urgencyIcon {
    font-size: var(--text-l);
}

.urgencyNumber {
    font-weight: 700;
    font-size: var(--text-l);
}
/* Ajoutez ou modifiez ces styles */

.modal {
  width: 1024px;
  margin: auto;
  border:none;
    position: fixed; /* Utilisez 'fixed' pour positionner le modal par rapport à la fenêtre */
  top: 50%;        /* Centrage vertical */
  left: 50%;       /* Centrage horizontal */
  transform: translate(-50%, -50%); /* Ajustement pour centrer */
  z-index: 10000;  /* Z-index élevé pour s'assurer que le modal est au-dessus */
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed; /* Couvre toute la fenêtre */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: var(--gutter);
  z-index: 9999;   /* Doit être juste en dessous du modal */
}

.closeButton:focus {
  outline: solid;
  outline-offset: var(--focus-offset);
  outline-color: var(--focus-color);
  outline-width: var(--focus-width);
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  min-width: auto;
  position: absolute;
  top: calc(var(--gutter)/2);
  right: calc(var(--gutter)/2);
  transition: var(--transition);
  background-color: var(--white);
}

.closeButton:hover {
  background-color: var(--neutral-ultra-light);
}

.lineContainer {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: var(--text-line-height);
    aspect-ratio: 2/3;
}

.line {
  position: absolute;
  width: 100%;
  height: .2rem;
  background-color: currentColor;
}

.line:first-child {
  transform: rotate(45deg);
}

.line:last-child {
  transform: rotate(-45deg);
}

@media screen and (max-width: 1024px) {
  .modal {
    width: auto;
    transform: initial;
    top: var(--gutter);
    left: var(--gutter);
    right: var(--gutter);
    bottom: var(--gutter);
    overflow-y: auto;
  }
}
.primary {
  --btn-background: var(--primary);
  --btn-background-hover: var(--primary-light);
  --btn-text-color: var(--white);
  --btn-text-color-hover: var(--white);
  --btn-border-color: var(--primary);
  --btn-border-color-hover: var(--primary-light);
  --focus-color: var(--primary-light);
}

.primary.btnOutline {
  --btn-background: transparent;
  --btn-background-hover: var(--primary-light);
  --btn-text-color: var(--primary);
  --btn-text-color-hover: var(--white);
  --btn-border-color: var(--primary);
  --btn-border-color-hover: var(--primary-light);
  --focus-color: var(--primary-light);
}

.secondary {
  --btn-background: var(--secondary);
  --btn-background-hover: var(--secondary-light);
  --btn-text-color: var(--white);
  --btn-text-color-hover: var(--white);
  --btn-border-color: var(--secondary);
  --btn-border-color-hover: var(--secondary-light);
  --focus-color: var(--secondary-light);
}

.secondary.btnOutline {
  --btn-background: transparent;
  --btn-background-hover: var(--secondary-light);
  --btn-text-color: var(--secondary);
  --btn-text-color-hover: var(--white);
  --btn-border-color: var(--secondary);
  --btn-border-color-hover: var(--secondary-light);
  --focus-color: var(--secondary-light);
}

.neutral {
  --btn-background: var(--neutral);
  --btn-background-hover: var(--neutral-hover);
  --btn-text-color: var(--white);
  --btn-text-color-hover: var(--white);
  --btn-border-color: var(--neutral);
  --btn-border-color-hover: var(--neutral-hover);
  --focus-color: var(--primary);
}

.white {
  --btn-background: var(--white);
  --btn-background-hover: var(--neutral-ultra-light);
  --btn-text-color: var(--neutral);
  --btn-text-color-hover: var(--neutral);
  --btn-border-color: var(--white);
  --btn-border-color-hover: var(--neutral-ultra-light);
  --focus-color: var(--white);
}

.map {
  --btn-background: var(--carte);
  --btn-background-hover: var(--carte-light);
  --btn-text-color: var(--white);
  --btn-text-color-hover: var(--neutral);
  --btn-border-color: var(--carte);
  --btn-border-color-hover: var(--carte-light);
  --focus-color: var(--carte-dark);
  --btn-font-size: var(--text-m);
  --btn-min-width: auto;
}

.map.btnOutline {
  --btn-background: transparent;
  --btn-background-hover: var(--carte-light);
  --btn-text-color: var(--carte);
  --btn-text-color-hover: var(--neutral);
  --btn-border-color: var(--carte);
  --btn-border-color-hover: var(--carte-light);
  --focus-color: var(--carte-dark);
}

.white.btnOutline {
  --btn-background: transparent;
  --btn-background-hover: var(--neutral-ultra-light);
  --btn-text-color: var(--white);
  --btn-text-color-hover: var(--neutral);
  --btn-border-color: var(--white);
  --btn-border-color-hover: var(--neutral-ultra-light);
  --focus-color: var(--white);
}

.neutral.btnOutline {
  --btn-background: transparent;
  --btn-background-hover: var(--neutral-hover);
  --btn-text-color: var(--neutral);
  --btn-text-color-hover: var(--white);
  --btn-border-color: var(--neutral);
  --btn-border-color-hover: var(--neutral-hover);
  --focus-color: var(--primary);
}

.neutral-ultra-light {
  --btn-background: var(--neutral-ultra-light);
  --btn-background-hover: var(--white);
  --btn-text-color: var(--neutral);
  --btn-text-color-hover: var(--neutral);
  --btn-border-color: var(--neutral-ultra-light);
  --btn-border-color-hover: var(--white);
  --focus-color: var(--primary);
}

.neutral-ultra-light.btnOutline {
  --btn-background: transparent;
  --btn-background-hover: var(--neutral-ultra-light);
  --btn-text-color: var(--neutral);
  --btn-text-color-hover: var(--neutral);
  --btn-border-color: var(--neultra-ultra-light);
  --btn-border-color-hover: var(--neutral-ultra-light);
  --focus-color: var(--primary);
}

[class*="Button_"]:where(:not([class*="BurgerMenuMobile_"])) {
  background: var(--btn-background);
  color: var(--btn-text-color);
  padding-block: var(--btn-padding-block);
  padding-inline: var(--btn-padding-inline);
  min-inline-size: var(--btn-min-width);
  line-height: var(--btn-line-height);
  font-size: var(--btn-font-size, var(--text-m));
  font-weight: var(--btn-font-weight);
  font-style: var(--btn-font-style);
  text-transform: var(--btn-text-transform);
  letter-spacing: var(--btn-letter-spacing);
  text-decoration: var(--btn-text-decoration);
  border-width: var(--btn-border-width);
  border-style: var(--btn-border-style);
  border-radius: var(--btn-radius);
  border-color: var(--btn-border-color);
  transition: var(--btn-transition, var(--transition));
  justify-content: var(--btn-justify-content, center);
  text-align: var(--btn-text-align, center);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--space-xs);
}

@media (hover: hover) and (pointer: fine) {
  [class*="Button_"]:where(:not([class*="BurgerMenuMobile_"])):hover {
    background: var(--btn-background-hover);
    color: var(--btn-text-color-hover);
    border-color: var(--btn-border-color-hover);
    text-decoration: var(--btn-text-decoration-hover);
  }
}

[class*="Button_"]:where(:not([class*="BurgerMenuMobile_"])):focus {
  outline-style: solid;
  outline-color: var(--focus-color);
  outline-width: var(--focus-width);
  outline-offset: var(--focus-offset);
}

.btn--xs {
  font-size: var(--text-xs);
}

.btn--s {
  font-size: var(--text-s);
}

.btn--m {
  font-size: var(--text-m);
}

.btn--l {
  font-size: var(--text-l);
}

.btn--xl {
  font-size: var(--text-xl);
}

.btn--xxl {
  font-size: var(--text-xxl);
}
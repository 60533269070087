/* src/components/SearchResults.module.css */
.resultsPage {
    position: relative;
  }

  .resultsPage .resultsHeader {
    padding-inline: calc(var(--gutter)/2);
    padding-block: 0;
  }

  .resultsHeader .headerContainer {
    padding-inline: var(--content-gap);
    padding-block: var(--section-padding-block);
    background-color: var(--white);
    width: 100%;
  }

  .headerContainer [class*="searchBarContainer_"] {
    width: 1440px;
    max-width: 100%;
    margin: auto;
  }

  .resultsTitle {
    font-size: var(--h2);
    width: 1440px;
    max-width: 100%;
    margin: auto;
  }

.filterSection {
  width: 1440px;
  max-width: 100%;
  margin: auto;
  display: flex;
  gap: var(--space-xs);
  align-items: center;
}

.filterLabel {
  font-weight: 700;
  line-height: var(--text-line-height);
  margin: 0;
}


.filterSection [class*="css-"][class*="-container"] {
  width: 100%;
  flex: 1;
}

.filterSection [class*="select__control"] {
  border-color: var(--neutral-light);
  border-radius: 0;
  padding: calc(var(--space-xs)/2);
  background-color: transparent;
}

.filterSection [class*="select__control--is-focused"] {
  border-color: var(--secondary);
  box-shadow: none;
}

.filterSection [class*="select__multi-value"][class*="css-"][class*="-multiValue"] {
  background-color: var(--neutral-medium);
  align-items: center;
}

.filterSection [class*="select__multi-value"][class*="multi-value__label"] {
  background-color: var(--neutral-ultra-light);
  color: currentColor;
  font-size: var(--text-m);
  padding-inline: var(--btn-padding-inline);
  padding-block: var(--btn-padding-block);
}

.filterSection [class*="select__multi-value"][class*="multi-value__remove"] {
  border-radius: 0;
  padding: var(--btn-padding-block);
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: var(--transition);
  color: currentColor;
}

.filterSection [class*="select__multi-value"][class*="multi-value__remove"]:hover {
  background-color: transparent;
  color: var(--white);
}


.filterSection [class*="select__value-container"] {
  flex: 1;
  width: 100%;
  padding: 0;
  gap: calc(var(--space-xs)/2);
  flex-wrap: wrap;
}

.filterSection [class*="select__value-container"] [class*="css-"][class*="-multiValue"] {
  margin: 0;
  border-radius: 0;
}

@media screen and (max-width: 1024px) {
  .filterSection {
    flex-wrap: wrap;
  }

  .filterLabel {
    width: 100%;
  }
}


.loading {
  text-align: center;
  font-size: var(--text-m);
  color: currentColor;
}

.noResults {
  text-align: center;
  font-size: var(--text-m);
  color: currentColor;
  margin-top: var(--space-xs);
}

.error {
  color: var(--urgence);
  text-align: center;
  margin-top: var(--space-xs);
}

.refreshIcon {
  margin-left: 8px;
  cursor: pointer;
  vertical-align: middle;
}

/* components/news/NewsList.js*/

.listItem {
    width: 100%;
    background-color: var(--white);
    padding: var(--space-s);
}

.content {
    display: flex;
    gap: var(--content-gap);
    align-items: center;
    justify-content: space-between;
}

.infos {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
}

.meta {
    display: flex;
    gap: var(--space-xs);
    flex-wrap: wrap;
}

.tagList {
    display: flex;
    gap: var(--space-xs);
    flex-wrap: wrap;
}

.metaItem {
    opacity: .5;
}

.listItem .meta p {
    margin-bottom: 0;
}

/*p {
    margin-bottom: 0;
}*/

/* Couleurs */

.tagList .services-et-demarches {
    color: var(--services);
}

.tagList [class*="votre-collectivite"] {
    color: var(--collectivite);
}

.tagList [class*="enfance-et-jeunesse"] {
    color: var(--jeunesse);
}

.tagList [class*="seniors"] {
    color: var(--seniors);
}

.tagList [class*="mobilite"] {
    color: var(--mobilite);
}

.tagList [class*="evenements"] {
    color: var(--events);
}

.tagList [class*="visiter-arras"] {
    color: var(--visiter);
}

.tagList [class*="arras-ville-dynamique"] {
    color: var(--inclusive);
}

.tagList [class*="partenaires"] {
    color: var(--partenaires);
}

.tagList [class*="numeros-d-urgence"] {
    color: var(--urgence);
}

.tagList [class*="contact"] {
    color: var(--contact);
}

@media screen and (max-width: 600px) {
    .content {
        flex-direction: column;
        align-items: flex-start;
    }

    .meta {
        flex-direction: column;
    }
}
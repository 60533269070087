/* src/components/common/InfoCard.module.css */

.card {
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
    padding: var(--content-gap);
    justify-content: center;
    align-items: flex-start;
}

.icon {
    font-size: calc(var(--h3)*3.5);
}

.card button,
.generic.card button {
    --btn-background : var(--white);
    --btn-background-hover : var(--neutral-ultra-light);
    --btn-border-color: var(--white);
    --btn-border-color-hover: var(--neutral-ultra-light);
    --btn-text-color: var(--neutral);
    --btn-text-color-hover: var(--neutral);
    --focus-color: var(--white);
}

@media screen and (max-width: 1024px) {
    .infoCard {
        grid-column: 1;
        margin-top: var(--content-gap);
    }
}

.blog {
    background-color: var(--services);
    color: var(--white);
}

.agenda {
    background-color: var(--events);
    color: var(--white);
}

.generic {
    background-color: var(--primary);
    color: var(--white);
}
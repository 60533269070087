/* NavItem.module.css */

.navItem {
  padding: var(--space-xs) var(--gutter);
    display: flex;
    flex-wrap: nowrap;
    gap: var(--space-xs);
    align-items: center;
    transition: var(--transition);
    height: 6rem;
    width: 100%;
    text-align: left;
    font-size: var(--text-m);
    line-height: var(--text-line-height);
    cursor: pointer;
}

.navItem:focus {
  --focus-offset: -.5rem;
  outline-style: solid;
    outline-color: var(--focus-color);
    outline-width: var(--focus-width);
    outline-offset: var(--focus-offset);
    transition: var(--transition);
}

.navItem:hover {
  background-color: var(--neutral-ultra-light);
}

.arrow {
  font-weight: 700;
  margin-left: auto;
}


.subsidebarNavItem  {
color: currentColor;  }

.label {
  flex: 1;
}

.selectedCategory {
  background-color: var(--neutral-ultra-light); /* Couleur de fond pour la catégorie sélectionnée */ 
}

.icon {
  width: 4rem;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.icon svg {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  width: 100%;
  height: auto;
}

/* Définir des variables CSS pour les couleurs actives basées sur le slug */

.evenements {
  fill: var(--events);
}

.enfance-et-jeunesse {
  fill: var(--jeunesse);
}

.votre-collectivite {
  fill: var(--collectivite);
}

.seniors {
  fill: var(--seniors);
}

.mobilite {
  fill: var(--mobilite);
}

.services-et-demarches {
  fill: var(--services);
}

.visiter-arras {
  fill: var(--visiter);
}

.arras-ville-dynamique {
  fill: var(--inclusive);
}

.partenaires,
.navItem[href*="associations"] {
  fill: var(--partenaires);
}

.numeros-d-urgence,
.navItem[href*="urgence"] {
  fill: var(--urgence);
}

.contact,
.navItem[href*="contact"] {
  fill: var(--contact);
}

.carte-interactive,
.navItem[href*="carte"] {
  fill: var(--carte);
}

.evenements.selectedCategory {
  --focus-color: var(--events);
  background-color: var(--events);
  color: var(--white);
  fill: var(--white);
}

.enfance-et-jeunesse.selectedCategory {
  --focus-color: var(--jeunesse);
  background-color: var(--jeunesse);
  color: var(--white);
  fill: var(--white);
}

.votre-collectivite.selectedCategory {
  --focus-color: var(--collectivite);
  background-color: var(--collectivite);
  color: var(--white);
  fill: var(--white);
}

.seniors.selectedCategory {
  --focus-color: var(--seniors);
  background-color: var(--seniors);
  color: var(--white);
  fill: var(--white);
}

.mobilite.selectedCategory {
  --focus-color: var(--mobilite);
  background-color: var(--mobilite);
  color: var(--white);
  fill: var(--white);
}

.services-et-demarches.selectedCategory {
  --focus-color: var(--services);
  background-color: var(--services);
  color: var(--white);
  fill: var(--white);
}

.visiter-arras.selectedCategory {
  --focus-color: var(--visiter);
  background-color: var(--visiter);
  color: var(--white);
  fill: var(--white);
}

.arras-ville-dynamique.selectedCategory {
  --focus-color: var(--inclusive);
  background-color: var(--inclusive);
  color: var(--white);
  fill: var(--white);
}

.partenaires.selectedCategory {
  --focus-color: var(--partenaires);
  background-color: var(--partenaires);
  color: var(--white);
  fill: var(--white);
}

.numeros-d-urgence.selectedCategory {
  --focus-color: var(--urgence);
  background-color: var(--urgence);
  color: var(--white);
  fill: var(--white);
}

.contact.selectedCategory {
  --focus-color: var(--contact);
  background-color: var(--contact);
  color: var(--white);
  fill: var(--white);
}

.anotherSlug.selectedCategory {
  --focus-color: var(--secondary-light); /* Exemple pour un autre slug */
}

@media screen and (max-width: 1024px) {
  .navItem {
    height: var(--responsive-navbar);
  }

  .icon {
    display: none;
  }
}
/* Tooltip */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--neutral);
  opacity: .3;
  z-index: 10;
}

.tooltip {
  background-color: var(--white);
  position: absolute;
  padding: var(--content-gap);
  bottom: calc(100% + var(--space-xs));
  right: 0;
  width: 35rem;
  z-index: 99;
}

.tooltipList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-s);
}

.tooltipItem {
  position: relative;
}

.tooltip::after {
  content: '';
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--white);
  bottom: -.75rem;
  transform: rotate(45deg);
  right: var(--content-gap);
  z-index: 98;
}

.tooltipLabel {
  padding-block: var(--btn-padding-block);
  padding-inline: var(--btn-padding-inline);
  border-radius: var(--radius-circle);

}

.evenements {
  color: var(--events);
}

.enfance-et-jeunesse {
  color: var(--jeunesse);
}

.votre-collectivite {
  color: var(--collectivite);
}

.seniors {
  color: var(--seniors);
}

.mobilite {
  color: var(--mobilite);
}

.services-et-demarches {
  color: var(--services);
}

.visiter-arras {
  color: var(--visiter);
}

.arras-ville-dynamique {
  color: var(--inclusive);
}

.partenaires {
  color: var(--partenaires);
}

.partenaires {
  color: var(--partenaires);
}

.numeros-d-urgence {
  color: var(--urgence);
}

.contact {
  color: var(--contact);
}

@media screen and (max-width: 400px) {
  .tooltip {
    width: calc(100vw - (var(--gutter)*2));
    right: calc(var(--content-gap) * -1);
  }

  .tooltip::after {
    right: calc(var(--content-gap) * 2);
  }
}
.customize {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customize .customizeButton {
    --focus-offset: 0;
    align-items: center;
    background-color: var(--white);
    border-color: var(--white);
    color: var(--secondary);
    font-size: var(--text-l);
}

.customize .customizeButton:hover {
    background-color: var(--neutral-ultra-light);
    border-color: var(--neutral-ultra-light);
}

.icon {
    display: flex;
    align-items: center;
    color: var(--secondary);
}

.icon svg {
    transition: var(--transition);
    height: var(--text-xl);
    width: var(--text-xl);
}

.customize .customizeButton:hover .icon svg {
    color: var(--secondary) !important;
    fill: var(--secondary) !important;
}

.separator {
    position: absolute;
    width: 100%;
    height: var(--btn-border-width);
    background-color: var(--secondary);
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: -1;
}

@media screen and (max-width: 600px) {
    .customize .customizeButton {
        font-size: var(--text-m);
    }

    .icon svg {
        width: var(--text-l) !important;
        height: var(--text-l) !important;
    }
}

@media screen and (max-width: 400px) {
    .icon {
        display: none;
    }
}
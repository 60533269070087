.grid {
  display: grid;
  grid-template-columns: var(--grid-4);
  gap: var(--content-gap);
  align-items: stretch;
  width: 100%;
  max-width: 100%;
}

@media screen and (max-width: 1280px) {
  .grid {
    grid-template-columns: var(--grid-2);
  }
}

@media screen and (max-width: 1024px) {
  .grid {
    grid-template-columns: var(--grid-1);
    gap: 0;
  }

  .grid > li:not(:last-of-type)::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: var(--content-gap);
    right: var(--content-gap);
    border-bottom: var(--secondary) 1px solid;
  }
}
.dot {
  width: var(--h3);
  height: var(--h3);
  aspect-ratio: 1;
  border-radius: var(--radius-circle);
  display: block;
}

.default {
  background-color: var(--neutral-ultra-light);
}

@media screen and (max-width: 1440px) {
  .dot {
    width: calc(var(--h3)*0.9);
    height: calc(var(--h3)*0.9);
  }
}

@media screen and (max-width: 1280px) {
  .dot {
    width: var(--h3);
    height: var(--h3);
  }
}
.main {
    background-color: var(--white);
    padding: var(--content-gap);
}

.block {
    display: grid;
    grid-template-columns: var(--grid-2-1);
    gap: var(--content-gap);
    width: 100%;
    align-items: flex-start;
}

.intro {
    background-color: var(--neutral-ultra-light);
    padding: var(--content-gap);
    display: flex;
    align-items: center;
    gap: var(--content-gap);
}

.introText {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs);
}

.introText h2 {
    font-size: var(--h3);
}

.intro svg {
    width: var(--h1);
    fill: var(--white);
}

.content {
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
    order: -1;
}

@media screen and (max-width: 1024px) {
    .block {
        grid-template-columns: var(--grid-1);
    }

    .content {
        order: initial;
    }

    .content iframe {
        height: 400px;
    }
}
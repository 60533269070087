/* Full Calendar */
.fcContainer {
  align-items: initial;
}

.fc {
  font-size: var(--text-m);
  background-color: var(--white);
}

/* Header bar */

.fcContainer .fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: initial;
  padding: var(--content-gap);
  gap: var(--content-gap);
}

.fcContainer .fc-toolbar-chunk {
  display: flex;
  align-items: center;
  gap: var(--content-gap);
  width: 100%;
}

.fcContainer .fc-toolbar-chunk:first-child {
  min-width: 550px;
  justify-content: space-between;
}

@media screen and (max-width: 1440px) {
  .fcContainer .fc-toolbar-chunk:first-child {
    min-width: initial;
    justify-content: space-between;
  }

  .fcContainer .fc-toolbar-chunk:first-child h2 {
    white-space: nowrap;
  }
}

@media screen and (max-width: 1024px) {
  .fcContainer .fc-toolbar {
    display: grid;
    grid-template-columns: var(--grid-2);
  }

  .fcContainer .fc-toolbar-chunk:first-child {
    grid-column: span 2;
  }

  .fcContainer .fc-toolbar-chunk:not(:first-child) .fc-button {
    margin-left: initial;
  }
}

@media screen and (max-width: 600px) {
  .fcContainer .fc-toolbar {
    grid-template-columns: var(--grid-1);
  }

  .fcContainer .fc-toolbar-chunk {
    display: none;
  }

  .fcContainer .fc-toolbar-chunk:first-child,
  .fcContainer .fc-toolbar-chunk:last-child {
    display: flex;
    grid-column: 1;
  }

  .fcContainer .fc-toolbar-chunk:first-child h2 {
    white-space: wrap;
  }
}

.fcContainer .fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
  margin-left: 0;
}

.fcContainer .fc-button {
  transition: var(--transition);
  padding: calc(var(--space-xs)/2);
  background-color: initial;
  color: currentColor;
  border: initial;
}

.fcContainer .fc-button:hover {
  background-color: var(--neutral-ultra-light);
  color: var(--neutral);
}

.fcContainer .fc .fc-button-primary:not(:disabled).fc-button-active,
.fcContainer .fc .fc-button-primary:not(:disabled):active {
  background-color: var(--secondary);
  border: initial;
  color: var(--white);
}

.fcContainer .fc .fc-button-primary:not(:disabled).fc-button-active:hover,
.fcContainer .fc .fc-button-primary:not(:disabled):active:hover {
  background-color: var(--secondary-light);
  border: initial;
  color: var(--white);
}

.fcContainer .fc-direction-ltr .fc-button-group>.fc-button {
  border-radius: var(--radius-s) !important;
  margin-left: 0;
}

.fcContainer .fc-button-group {
  gap: var(--space-xs);
  margin-left: auto;
}

.fcContainer .fc-button:focus {
  box-shadow: initial;
  outline: solid;
  outline-offset: var(--focus-offset);
  outline-color: var(--focus-color);
  outline-width: var(--focus-width);
}

.fcContainer .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: initial;
}

.fcContainer .fc-prev-button,
.fcContainer .fc-next-button {
  background-color: initial;
  border: initial;
  color: var(--secondary);
  font-weight: 700;
  border-radius: 0;
}

.fcContainer .fc-prev-button .fc-icon,
.fcContainer .fc-next-button .fc-icon {
  font-size: var(--text-xxl);
  transform: rotate(90deg);
}

.fcContainer .fc-today-button {
  background-color: initial;
  color: currentColor;
  margin-left: auto;
}

.fcContainer .fc-today-button:disabled {
  background-color: initial;
  color: currentColor;
  opacity: .25;
}

.fcContainer .fc-toolbar-chunk h2 {
  font-size: var(--h2);
  color: var(--primary);
}

/* Grid */

.fcContainer .fc table {
  font-size: var(--text-m);

}

.fcContainer .fc-scrollgrid-sync-table tr {
  font-size: var(--text-m);
}

.fcContainer .fc-scrollgrid-sync-table {
  border-collapse: separate;
  border-spacing: var(--space-xs);
}

.fcContainer .fc-theme-standard td,
.fcContainer .fc-theme-standard th,
.fcContainer .fc-theme-standard .fc-scrollgrid {
  border: initial;
}

.fcContainer .fc-scrollgrid-section-header .fc-col-header-cell-cushion {
  padding-inline: var(--space-xs);
  padding-block: var(--gutter);
}

.fcContainer .fc-day {
  padding: calc(var(--space-xs)/2);
}

.fc-daygrid-day {
  /* Hauteur fixe des cases */
  height: 12rem;
  overflow: hidden;
  background-color: var(--neutral-ultra-light);
  border-radius: var(--radius-s);
}

.fcContainer .fc-day-other {
  opacity: .3;
}

.fcContainer .fc .fc-day-other .fc-daygrid-day-top {
  opacity: 1;
}

.fcContainer .fc .fc-daygrid-day.fc-day-today {
  background-color: var(--neutral-ultra-light);
  outline: solid;
  outline-width: var(--focus-width);
  outline-color: var(--focus-color);
  outline-offset: var(--focus-offset);
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin: 0;
}

.fc-more-link {
  font-weight: bold;
  color: var(--secondary);
  cursor: pointer;
}

.fc-h-event {
  background-color: transparent;
  border-color: transparent;
}

.fc .fc-daygrid-event {
  display: flex;
  align-items: center;
  font-size: inherit;
}

.fc-event-selected::after,
.fc-event:focus::after {
  background: initial;
}

.fc-popover {
  opacity: 0;
}

.fc-toolbar-title {
  text-transform: capitalize;
}

.fc-event-main {
  width: 100%;
}

.fc-event-selected,
.fc-event:focus {
  box-shadow: initial;
}

.fc .fc-daygrid-more-link {
  border-radius: var(--radius-circle);
  cursor: pointer;
  line-height: 1;
  margin-top: 1rem;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;
  white-space: nowrap;
  z-index: 4;
  transition: var(--transition);
}

.fc .fc-daygrid-more-link:hover {
  background-color: initial;
  color: var(--secondary-light);
}

.fc .fc-timegrid-slot {
  height: auto;
}

.fc .fc-timegrid-slot-label-frame {
  display: flex;
  justify-content: center;
}

.fc .fc-timegrid-slot-label-cushion {
  padding-inline: calc(var(--space-xs)/2);
}

.fcContainer .fc .fc-timegrid-slot-lane {
  border-bottom: 1px solid var(--neutral-ultra-light);
  padding-block: var(--btn-padding-block);
  padding-inline: var(--btn-padding-inline);
}

@media screen and (max-width: 1440px) {
  .fc-daygrid-day {
    /* Hauteur fixe des cases */
    height: 10rem;
  }
}

@media screen and (max-width: 1024px) {
  .fc-daygrid-day {
    /* Hauteur fixe des cases */
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .fcContainer .fc-scrollgrid-section-header .fc-col-header-cell-cushion {
    padding-inline: 0;
    padding-block: 0;
  }

  .fc-daygrid-day [class*="RenderEventContent_eventTitle"] {
    display: none;
  }

  .fc-daygrid-day [class*="RenderEventContent_dot"] {
    margin-right: 0;
  }

  .fc-daygrid-day-events {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
  }

  .fc-daygrid-day-events::before {
    display: none;
  }
}

.fc-daygrid-day-events::after, .fc-daygrid-day-events::before, .fc-daygrid-day-frame::after, .fc-daygrid-day-frame::before, .fc-daygrid-event-harness::after, .fc-daygrid-event-harness::before {
  display:none !important;
}

.fc-daygrid-event-harness {
  margin-top: 10px !important;
}

.fc-daygrid-event .fc-event-main:hover {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5%;
}

 .fc-v-event {
  padding:5px;
  background-color: transparent;
  border: solid 2px var(--events);
}
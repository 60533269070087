.featuredEvent {
  width: 100%;
  display: grid;
  grid-template-columns: var(--grid-4-3);
  align-items: stretch;
  min-height: 50rem;
}

.imageContainer {
  position: relative;
  overflow: hidden;
}

.thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder { /* Prévoir un remplacement par une image par défaut à la place */
  width: 100%;
  height: 100%;
  background-color: var(--neutral-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-style: italic;
  color: #666;
}

.badgeList {
  z-index: 1;
  position: absolute;
  display: flex;
  gap: var(--space-xs);
  max-width: 100%;
  bottom: var(--content-gap);
  left: var(--content-gap);
  flex-wrap: wrap;
}

.eventInfo {
  padding: var(--container-gap);
  display: flex;
  flex-direction: column;
  gap: var(--content-gap);
  justify-content: center;
  background-color: var(--white);
}

.dotList {
  display: flex;
  gap: var(--space-xs);
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.heading {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.date {
  opacity: .5;
}

.excerpt {
  position: relative;
}

.voirPlus {
  margin-left: auto;
  font-size: var(--text-m);
  transition: var(--transition);
}

.voirPlus:focus{
  outline: solid;
  outline-offset: var(--focus-offset);
  outline-color: var(--focus-color);
  outline-width: var(--focus-width);
}

@media screen and (max-width: 1024px) {
  .featuredEvent {
    grid-template-columns: var(--grid-1);
  }

  .imageContainer {
    min-height: 50rem;
  }

  .eventInfo {
    padding: var(--content-gap);
  }
}

@media screen and (max-width: 600px) {
  .imageContainer {
    min-height: 30rem;
  }

  .badgeList,
  .excerpt {
    display: none;
  }
}
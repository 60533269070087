.cta .ctaContainer,
.cta .subsiteCtaContainer {
    display: grid;
    grid-template-columns: var(--grid-3-2);
    gap: 0;
    align-items: stretch;
}

.mediaContainer {
    display: flex;
    position: relative;
    height: auto;
    background-color: var(--carte);
    overflow: hidden;
}

.cta .mediaContainer img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.cta .mediaContainer::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--carte);
    z-index: 1;
    mix-blend-mode: color;
    opacity: .5;
}

.content {
    display: flex;
    flex-direction: column;
    gap: var(--content-gap);
    justify-content: center;
    align-items: flex-start;
    padding: var(--container-gap);
    background-color: var(--white);
}

.ctaContainer .icon,
.subsiteCtaContainer .icon {
    fill: var(--carte);
    width: calc(var(--h1)*1.5);
}

.content h2 {
    font-weight: 400;
}

.content h2 span {
    font-weight: 700;
}

.greenButton {
    --btn-background: var(--carte);
    --btn-background-hover: var(--carte-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--neutral);
    --btn-border-color: var(--carte);
    --btn-border-color-hover: var(--carte-light);
    --focus-color: var(--carte-dark);
}

.subsiteButton {
    --btn-background: var(--primary-ultra-dark);
    --btn-background-hover: var(--primary-dark);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--primary-ultra-dark);
    --btn-border-color-hover: var(--primary-dark);
    --focus-color: var(--primary);
}

@media screen and (max-width: 1024px) {

    .cta .ctaContainer,
    .cta .subsiteCtaContainer {
        grid-template-columns: var(--grid-1);
    }

    .mediaContainer {
        display: none;
    }

    .content {
        color: var(--white);
        background-color: var(--carte);
        padding: var(--content-gap);
    }

    .content h2 {
        --featured-heading: calc(var(--h3)*1.25);
        font-size: var(--featured-heading);
        font-weight: 700;
    }

    .ctaContainer .icon,
    .subsiteCtaContainer .icon {
        fill: var(--white);
        width: var(--space-xl);
    }

    .greenButton,
    .subsiteButton {
        --btn-background: var(--white);
        --btn-background-hover: var(--neutral-ultra-light);
        --btn-text-color: var(--neutral);
        --btn-text-color-hover: var(--neutral);
        --btn-border-color: var(--white);
        --btn-border-color-hover: var(--neutral-ultra-light);
        --focus-color: var(--white);
    }
}
.spinner {
    margin: 1rem auto;
    border: 8px solid var(--neutral-light); /* couleur grise pour le cercle */
    border-top: 8px solid var(--neutral-medium); /* couleur bleue pour le segment “qui tourne” */
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    animation: spin 1s linear infinite;
  }
  
  /* Animation de rotation */
  @keyframes spin {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
.container {
  transition: var(--transition);
  color: currentColor;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: var(--content-gap);
  background-color: var(--white);
  z-index: 10000000;

  /* Transition pour transformer et opacité */
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;

  /* Par défaut (fermée) */
  transform: translateX(100%);
  opacity: 0;
}

/* Quand on ajoute la classe .sidebarOpen, on ramène la sidebar en place */
.sidebarOpen {
  transform: translateX(0);
  opacity: 1;
}

.closeContainer {
  height: var(--navbar);
  min-height: var(--navbar);
  display: flex;
  align-items: center;
  padding-inline: var(--gutter);
  background-color: var(--white);
}

.sidebar .closeButton {
  background: none;
  border: none;
  cursor: pointer;
  min-width: auto;
  margin-left: auto;
}

.sidebar .closeButton:hover {
  background-color: var(--neutral-ultra-light);
}

.lineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: var(--text-line-height);
  aspect-ratio: 2/3;
}

.line {
  position: absolute;
  width: 100%;
  height: .2rem;
  background-color: currentColor;
}

.line:first-child {
  transform: rotate(45deg);
}

.line:last-child {
  transform: rotate(-45deg);
}

.headingContainer {
  display: flex;
  gap: var(--space-xs);
  align-items: center;
  color: var(--primary);
}

.icon {
  display: flex;
  align-items: center;
}

.icon svg {
  height: var(--text-xxl);
}

.heading {
  font-size: var(--text-xxl);
  font-weight: 700;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--content-gap);
  padding-inline: var(--gutter);
  overflow-y: auto;
  /* Scrollbar Firefox */
  scrollbar-width: var(--firefox-width);
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

/* Scrollbar base Chromium */

.content::-webkit-scrollbar {
  width: var(--chromium-width);
  height: var(--chromium-height);
}

.content::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

.content::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border: 2px solid transparent;
}

.categoryContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--space-xs);
}

.zoomContainer {
  width: 50%;
  flex-direction: row;
  align-items: center;
  gap: var(--content-gap);
}

.headingCategoryContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.headingCategory {
  font-size: var(--h3);
  font-weight: 700;
}

.textContainer {
  line-height: var(--text-line-height);
  display: flex;
  align-items: center;
}

.percent {
  color: var(--secondary);
  font-weight: 700;
  font-size: var(--text-l);
  margin-left: .5rem;
}

.adjustContainer {
  display: flex;
  align-items: center;
  gap: var(--space-xs);
  margin-left: auto;
}

.adjustContainer button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-xl);
  font-weight: 700;
  color: var(--white);
  background-color: var(--secondary);
  height: var(--text-xxl);
  width: var(--text-xxl);
  border-radius: var(--radius-circle);
  cursor: pointer;
  transition: var(--transition);
}

.adjustContainer button:focus {
  outline: solid;
  outline-width: var(--focus-width);
  outline-offset: var(--focus-offset);
  outline-color: var(--focus-color);
}

.zoomIcon {
  width: var(--text-l);
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: var(--grid-2);
  gap: var(--space-xs);
}

.gridItem {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  padding: var(--content-gap);
  background-color: var(--neutral-ultra-light);
  border-radius: var(--radius-m);
  border: var(--btn-border-width) solid var(--neutral-ultra-light);
  cursor: pointer;
  transition: var(--transition);
  align-items: flex-start;
  font-size: var(--text-m);
  text-align: left;
  color: currentColor;
}

.gridItem [type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
}

.gridItem:hover {
  background-color: var(--transparent);
}

.gridItem:focus {
  outline: solid;
  outline-width: var(--focus-width);
  outline-offset: var(--focus-offset);
  outline-color: var(--focus-color);
}

.gridItem.select {
  /* Style d'un élément sélectionné */
  outline: solid;
  outline-width: var(--focus-width);
  outline-offset: var(--focus-offset);
  outline-color: var(--focus-color);
}

.gridHeading {
  font-size: var(--text-l);
  font-weight: 700;
}

.buttons {
  display: flex;
  gap: var(--content-gap);
  flex-wrap: wrap;
  padding: var(--gutter);
  padding-top: 0;
  background-color: var(--white);
  position: sticky;
  bottom: 0;
}

.saveButton,
.resetButton {
  position: relative;
}

.resetButton {
  border-color: transparent;
}

.resetButton:hover {
  background-color: var(--neutral-ultra-light);
  border-color: var(--neutral-ultra-light);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
}

.selected {
  box-shadow: inset 0 0 0 var(--btn-border-width) var(--secondary);
}

/* Toggle */

.toggleContainer {
  padding-block: var(--content-gap);
}

.switchLabel {
  display: inline-flex;
  align-items: center;
  gap: var(--space-xs);
  cursor: pointer;
  font-weight: 700;
  font-size: var(--text-l);
  line-height: var(--text-line-height);
}

.switchInput {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: none;
}

.switchSlider {
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: var(--radius-circle);
  background-color: var(--neutral-ultra-light);
  transition: var(--transition);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.switchSlider::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  background-color: var(--white);
  border-radius: var(--radius-circle);
  transition: var(--transition);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.switchInput:checked+.switchSlider {
  background-color: var(--primary);
}

.switchInput:checked+.switchSlider::before {
  transform: translateX(25px);
}

.switchText {
  color: currentColor;
}


@media screen and (max-width: 1024px) {
  .sidebar {
    width: 100%;
  }

  .overlay {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .grid {
    grid-template-columns: var(--grid-1);
  }

  .closeContainer {
    height: auto;
    min-height: auto;
    padding-block: var(--gutter);
  }

  .closeContainer .icon {
    display: none;
  }

  .zoomContainer {
    width: 100%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;

  /* On part de transparent au départ */
  background-color: rgba(0, 0, 0, 0);

  /* Transition pour le fondu */
  transition: background-color 0.3s ease-out;

  /* eventuellement, pointer-events: none; si on veut désactiver les clics en mode fermé */
  pointer-events: none;
}

/* Quand la sidebar est ouverte, on veut voir l’overlay en opacité 0.5 + clics */
.overlayOpen {
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: auto;
}

/* Facultatif : si vous voulez un conteneur .container
   qui ne fasse rien de spécial, c'est OK. Juste assurez-vous
   qu'il n'empêche pas la sidebar de bien s'afficher */
.container {
  /* Ici vous pouvez aussi mettre un "position: relative;" 
     si besoin, ou laisser vide si ça fonctionne tel quel. */
  transition: var(--transition);
  color: currentColor;
}
.footer {
  background-color: var(--primary);
  color: var(--white);
  gap: 0 !important;
  padding-inline: var(--gutter);
}

.altFooter {
  background-color: var(--primary-ultra-dark);
  color: var(--white);
  gap: 0 !important;
}

.footer .container,
.altFooter .container {
  padding-block: var(--section-padding-block);
  display: grid;
  grid-template-columns: var(--grid-4);
  gap: var(--content-gap);
}

.socials {
  display: flex;
  flex-direction: column;
  gap: var(--content-gap);
}

.socials img {
  height: var(--section-space-l);
  width: auto;
  object-fit: contain;

}

.footer .legalsContainer,
.altFooter .legalsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--content-gap);
  align-items: center;
  padding-block: var(--gutter);
}

.footer .legalItem,
.altFooter .legalItem {
  color: var(--white);
}

.legalsContainer p {
  margin-right: auto;
}

.legals {
  display: flex;
  gap: var(--content-gap);
  flex-wrap: wrap;
}



@media screen and (max-width: 1240px) {
  .footer .container,
  .altFooter .container {
    grid-template-columns: var(--grid-2);
    gap: var(--container-gap);
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    padding-bottom: var(--responsive-navbar);
  }
}

@media screen and (max-width: 600px) {

  .footer .legalsContainer,
  .altFooter .legalsContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .legals {
    flex-direction: column;
  }

  .footer .container,
  .altFooter .container {
    grid-template-columns: var(--grid-1);
  }

  .socials {
    grid-column: auto;
  }
}
/* Sidebar.module.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Inférieur au BurgerMenu (1100) */
}

.sidebar {
  position: fixed;
  left: -450px; /* Cache la sidebar hors de l'écran */
  top: 0;
  height: 100%;
  width: 450px;
  background-color: var(--white);
  transition: var(--transition);
  z-index: 1005; /* Supérieur à l'overlay (1000), inférieur au BurgerMenu (1100) */
  display: flex;
  flex-direction: column;
}

.sidebar.open {
  left: 0; /* Fait glisser la sidebar dans l'écran */
}

.mainMenu {
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* Scrollbar Firefox */
  scrollbar-width: var(--firefox-width);
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

/* Scrollbar base Chromium */

.mainMenu::-webkit-scrollbar {
  width: var(--chromium-width);
  height: var(--chromium-height);
}

.mainMenu::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

.mainMenu::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border: 2px solid transparent;
}

.selectedCategory {
  background-color: var(--neutral-ultra-light); /* Couleur de fond pour la catégorie sélectionnée */
}

.error {
  color: var(--inclusive);
}

.closeContainer {
  height: var(--navbar);
  min-height: var(--navbar);
  display: flex;
  align-items: center;
  padding-inline: var(--gutter);
  background-color: var(--white);
}

.sidebar .closeButton {
  background: none;
  border: none;
  cursor: pointer;
  min-width: auto;
}

.sidebar .closeButton:hover {
  background-color: var(--neutral-ultra-light);
}

.lineContainer {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: var(--text-line-height);
    aspect-ratio: 2/3;
}

.line {
  position: absolute;
  width: 100%;
  height: .2rem;
  background-color: currentColor;
}

.line:first-child {
  transform: rotate(45deg);
}

.line:last-child {
  transform: rotate(-45deg);
}

.navList {
  position: relative;
}

.navList li {
  border-top: 1px solid var(--neutral-ultra-light);
}

.navList li:last-child {
  border-bottom: 1px solid var(--neutral-ultra-light);
}

.socialsContainer {
  margin-top: auto;
  padding: var(--gutter);
  gap: var(--space-xs);
}

.socialsLabel {
  position: relative;
  margin: 0;
  font-size: var(--text-m);
}

.socials {
  display: flex;
  justify-content: flex-start;
}

.socialsIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;
  aspect-ratio: 1;  
  padding: var(--space-xs);
  color: currentColor;
  transition: var(--transition);
}

.socialsIcon:hover {
  color: var(--secondary);
}

.socialsIcon svg {
  height: var(--text-line-height);
  fill: currentColor;
  transition: var(--transition);
}
/* Styles pour le bouton "Voir plus" */

.loadMoreButton {
    background-color: var(--neutral-ultra-light);
    border-color: var(--neutral-ultra-light);
    color: var(--secondary);
    border-radius: var(--radius-circle);
    cursor: pointer;
    margin: auto;
    font-size: var(--text-l);
    position: relative;
  }

  .loadMoreButton .icon {
    height: var(--text-xxl);
    width: var(--text-xxl);
    padding: calc(var(--space-xs)/4);
    border: none;
    min-width: auto;
    color: var(--white);
    border-radius: var(--radius-circle);
    background-color: var(--secondary);
  }
  
  .loadMoreButton:hover {
    background-color: var(--secondary);
    color: var(--white);
    border-color: var(--secondary);
  }
  
.quickLinks {
    display: flex;
    justify-content: center;
  }
  
  .iconsContainer {
    display: flex;
    gap: var(--content-gap);
    padding-inline: var(--container-gap) !important;
    width: 100%;
    justify-content: center;
  }

  .iconsContainer li {
    width: 16rem;
  }
  
  .linkItem {
    display: flex;
    flex-direction: column;
    padding: var(--space-xs);
    gap: var(--space-xs);
  }

  .iconWrapper {
    border-radius: var(--radius-circle);
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    display: flex;
    position: relative;
    padding: 20%;
  }

  .iconWrapper:not([class*="Categories_"]),
  .responsiveItem:not([class*="Categories_"]) {
    background-color: var(--events);
    color: var(--white);
    fill: currentColor;
  }

  /* TEMP */
  .linkItem[aria-label*="urgence"] .iconWrapper {
    background-color: var(--urgence);
  }

  .linkItem[aria-label*="bbaye"] .iconWrapper {
    background-color: var(--visiter);
  }
  /* FIN TEMP */

  .icon {
    width: 100%;
  }

  .iconWrapper svg {
    margin: auto;
  }

  .arrow {
    display: none;
    font-size: var(--text-m);
    margin-left: auto;
  }
  
  .linkItem .label {
    font-weight: 700;
  font-size: var(--h4);
  line-height: var(--heading-line-height);
  text-align: center;
  }

  .quickLinks .responsiveItem {
    display: none;
  }

  @media screen and (max-width: 1440px) {
    .iconsContainer {
      padding-inline: 0 !important;
    }
  }

  @media screen and (max-width: 1024px) {
    .iconsContainer {
      display: flex;
      flex-direction: column;
      gap: var(--space-xs);
    }

    .iconsContainer li {
      width: 100%;
    }

    .linkItem {
      padding-inline: var(--btn-padding-inline);
      padding-block: var(--btn-padding-block);
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .linkItem .label {
      text-align: left;
    }

    .quickLinks .desktopItem {
      display: none;
    }

    .quickLinks .responsiveItem {
      display: flex;
    }

    .arrow {
      display: block;
    }
  }
.pageContent .main {
    background-color: var(--white);
    padding: var(--content-gap);
    display: flex;
    flex-direction: row;
}

.pageContent .mainColumn {
    height: calc(100vh - var(--navbar) - var(--section-space-l));
    width: 100%;
}

.download {
    margin-top: var(--space-xs);
}
.socialsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
}

.socialLinks {
  display: flex;
  gap: var(--space-s);
  align-items: center;
}

.socialLink {
  color: var(--white);
  font-size: var(--text-xl);
  transition: var(--transition);
}

.socialLink:hover {
  color: var(--secondary-light); /* Couleur de survol */
  fill: var(--secondary-light); /* Couleur de survol */
}

.webcamLink {
  fill: var(--white);
  width: var(--text-xl);
  height: var(--text-xl);
  transition: var(--transition);
  display: block;
}

.webcamLink:hover {
  fill: var(--secondary-light);
}

.icon {
  display: block;
}
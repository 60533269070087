/* Header Styles */
.header {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  box-shadow: 0 -.5rem .5rem rgba(0,0,0,.05);
}

.headerContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: var(--responsive-navbar);
  background-color: var(--white);
  color: currentColor;
  z-index: 999;
}

.heading {
  display: flex;
  align-items: center;
  padding-right: var(--gutter);
  width: 100%;
}

.brandContainer {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.brand,
.brandSubSite {
  white-space: nowrap;
  font-size: 2rem;
  font-weight: 700;
  margin-inline: var(--space-xs);

}

.brandSubSite {
  color: var(--primary);
  margin-left: var(--space-xs);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


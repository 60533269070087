.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* overlay sombre */
    background-color: rgba(0, 0, 0, 0.8);
  
    /* assurez-vous que c’est bien au-dessus de tout */
    z-index: 9999;
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* 
    On sépare le conteneur principal (.overlay) 
    du contenu (.content), pour gérer le clic "extérieur"
  */
  .content {
    position: relative;
    width: 1440px;
    padding: var(--gutter);
  }
  
  /* Bouton de fermeture (croix) */
  .closeBtn {
    position: absolute;
    top: var(--gutter);
    right: var(--gutter);
    background: none;
    border: none;
    font-size: 3rem;
    color: var(--white);
    cursor: pointer;
    z-index: 10;
  }
  
  /* L'image et ses flèches sont dans .imageContainer */
  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .imageContainer img {
    max-width: calc(1440px - (var(--gutter)*2));
    max-height: 80vh;
    object-fit: contain;
  }

  .icon {
    font-size: var(--gutter);
  }
  
  /* Flèches de navigation */
  .prevBtn,
  .nextBtn {
    border: none;
    border-radius: var(--radius-circle);
    color: var(--white);
    cursor: pointer;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: var(--space-xs);
  }
  /* Style pour le contenu de l'événement */
  .eventContent {
    display: flex;
    align-items: center;
  }
  
  /* Style pour la pastille */
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
  }
  
  /* Couleur par défaut de la pastille */
  .defaultDot {
    background-color: var(--events);
  }
  
  /* Exemples de styles pour les pastilles de catégories */
  .core {
    background-color: #ff0000;
  }
  
  .evenements {
    background-color: var(--events);
  }

  .enfance-et-jeunesse  {
    background-color: var(--jeunesse);
  }

  
  /* Style pour le titre de l'événement */
  .eventTitle {
    font-size: 0.9rem;
    color: #333;
  }
  
/* Ajoutez ou modifiez ces styles */

.modal {
  min-width: 900px;
  margin: auto;
  border:none;
    position: fixed; /* Utilisez 'fixed' pour positionner le modal par rapport à la fenêtre */
  top: 50%;        /* Centrage vertical */
  left: 50%;       /* Centrage horizontal */
  transform: translate(-50%, -50%); /* Ajustement pour centrer */
  z-index: 10000;  /* Z-index élevé pour s'assurer que le modal est au-dessus */
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed; /* Couvre toute la fenêtre */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;   /* Doit être juste en dessous du modal */
}

.close {
  position: absolute;
  top: 0;
  right: 0;
}

/* src/pages/AgendaPage.module.css */

.tabContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabButton {
  background-color: #f1f1f1;
  border: none;
  padding: 14px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.tabButton:not(:last-child) {
  border-right: 1px solid #ccc;
}

.tabButton:hover {
  background-color: #ddd;
}

.activeTab {
  background-color: #ccc;
}

.topSection {
  text-align: center;
  padding: 20px;
}


/* Sidebar.module.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  z-index: 1000;
  /* Inférieur au BurgerMenu (1100) */
  cursor: pointer;
}

.sidebar {
  position: fixed;
  left: -100%;
  /* Cache la sidebar hors de l'écran */
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--white);
  transition: var(--transition);
  z-index: 1005;
  /* Supérieur à l'overlay (1000), inférieur au BurgerMenu (1100) */
  display: flex;
  flex-direction: column;
}

.content {
  overflow-y: auto;
}

.sidebar.open {
  left: 0;
  /* Fait glisser la sidebar dans l'écran */
}

.mainMenu {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* Scrollbar Firefox */
  scrollbar-width: var(--firefox-width);
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

/* Scrollbar base Chromium */

.mainMenu::-webkit-scrollbar {
  width: var(--chromium-width);
  height: var(--chromium-height);
}

.mainMenu::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

.mainMenu::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border: 2px solid transparent;
}

.selectedCategory {
  background-color: var(--neutral-ultra-light);
  /* Couleur de fond pour la catégorie sélectionnée */
}

.error {
  color: var(--inclusive);
}

.lineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: var(--text-line-height);
  aspect-ratio: 2/3;
}

.line {
  position: absolute;
  width: 60%;
  height: .2rem;
  background-color: currentColor;
}

.line:first-child {
  transform: rotate(45deg);
}

.line:last-child {
  transform: rotate(-45deg);
}

.navList {
  position: relative;
}

.navList li {
  border-top: 1px solid var(--neutral-ultra-light);
}

.navList li:last-child {
  border-bottom: 1px solid var(--neutral-ultra-light);
}

.buttonContainer {
  display: flex;
  gap: var(--space-xs);
  flex-wrap: wrap;
  padding-inline: var(--gutter);
  padding-top: var(--gutter);
}

.socialsContainer {
  margin-top: auto;
  padding: var(--gutter);
  gap: var(--space-xs);
}

.socialsLabel {
  position: relative;
  margin: 0;
  font-size: var(--text-m);
}

.socials {
  display: flex;
  justify-content: flex-start;
}

.socialsIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;
  aspect-ratio: 1;
  padding: var(--space-xs);
  color: currentColor;
  transition: var(--transition);
}

.socialsIcon:hover {
  color: var(--secondary);
}

.socialsIcon svg {
  height: var(--text-line-height);
  fill: currentColor;
  transition: var(--transition);
}

.searchContainer {
  padding: var(--gutter);
}

.searchForm {
  display: flex;
  align-items: center;
  width: auto;
  background-color: var(--neutral-ultra-light);
  border-radius: var(--radius-circle);
  padding-inline: var(--btn-padding-inline);
  padding-block: var(--btn-padding-block);
  gap: var(--space-xs);
}

.searchForm:focus-within {
  transition: var(--transition);
  outline-style: solid;
  outline-color: var(--focus-color);
  outline-width: var(--focus-width);
  outline-offset: var(--focus-offset);
}

.searchForm input {
  outline: none !important;
  width: 100%;
}

.searchForm button {
  cursor: pointer;
}


.searchForm svg {
  font-size: var(--text-l);
  color: var(--secondary);
}

.headerButton,
.accessButton {
  position: relative;
}

.accessButton {
  --btn-background: transparent;
  --btn-background-hover: var(--primary-light);
  --btn-text-color: var(--primary);
  --btn-text-color-hover: var(--white);
  --btn-border-color: var(--primary);
  --btn-border-color-hover: var(--primary-light);
  --focus-color: var(--primary-light);
}

.headerButton:hover .icon,
.accessButton:hover .icon {
  color: var(--white);
}

.icon {
  height: var(--text-l);
  width: var(--text-l);
}

.accessibilityIcon {
  color: var(--secondary);
}

.customizeIcon {
  color: var(--white);
}

.sidebar .closeButton {
  border-radius: 0;
  background: none;
  border: none;
  cursor: pointer;
  min-width: auto;
  height: var(--responsive-navbar);
  justify-content: flex-start;
  align-items: center;
  padding-inline: 0 var(--gutter);
  align-self: flex-start;
  gap: 0;
}

.sidebar .closeButton:focus {
  outline-offset: calc(var(--focus-offset) * -1);
}

.closeButton .burger {
  height: var(--responsive-navbar);
  width: auto;
  aspect-ratio: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: center;
}

.closeButton .label {
  padding-block: var(--btn-padding-block);
  padding-inline: var(--btn-padding-block);
  font-weight: 700;
  font-size: 2rem;
}

@media screen and (max-width: 600px) {
  .buttonContainer button {
    width: 100%;
    justify-content: flex-start;
  }
}

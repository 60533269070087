.summary {
  padding: var(--space-s);
  background-color: var(--neutral-ultra-light);
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.summary ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.summary a {
  transition: var(--transition);
}

.summary a:hover {
  color: var(--secondary);
}
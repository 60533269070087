/* src/components/SearchBar.module.css */
form {
  display: flex;
  width: 100%;
}

.searchBarContainer {
  position: relative;
  width: 100%;
}

.searchInput {
  width: 100%;
  border-top-left-radius: var(--radius-circle);
  border-bottom-left-radius: var(--radius-circle);
  border: var(--btn-border-width) var(--btn-border-style) var(--neutral-light);
  border-right: none;
  padding-block: var(--btn-padding-block);
  padding-inline: var(--btn-padding-inline);
  line-height: var(--text-line-height);
  font-size: var(--text-l);
}

.fileTypeSelect {
  background-color: var(--neutral-ultra-light);
  border: var(--btn-border-width) var(--btn-border-style) var(--neutral-light);
  border-right: none;
  padding-block: var(--btn-padding-block);
  padding-inline: var(--btn-padding-inline) calc(var(--btn-padding-inline)*2);
  cursor: pointer;
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.dropdownContainer {
  position: relative;
  display: flex;
}

.dropdownContainer svg {
  position: absolute;
  font-size: var(--text-m);
  top: 50%;
  right: calc(var(--btn-padding-block) + (var(--text-m)/4));
  transform: translateY(-50%);
  pointer-events: none; /* Empêche l'interaction avec l'élément */
}

.searchBarContainer .searchButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-sizing: content-box !important;
  min-inline-size: initial !important;
}

@media screen and (max-width: 1024px) {
  .searchInput {
    font-size: var(--text-m);
  }

  .searchBarContainer .searchButton {
    box-sizing: border-box !important;
    min-width: auto !important;
  }

  .searchBarContainer .searchButton span {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .fileTypeSelect {
    max-width: 0;
    padding-inline: var(--btn-padding-inline);
  }
}

/* ./SearchBar.module.css */
.suggestionsList {
  list-style: none;
  background-color: var(--white);
  border: 1px solid var(--neutral-light);
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.suggestionItem {
  padding-inline: var(--btn-padding-inline);
  padding-block: var(--btn-padding-block);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--space-xs);
}

.suggestionItem:hover {
  background-color: var(--neutral-ultra-light);
}

.suggestionLabel {
  font-weight: bold;
}

.suggestionType {
  font-size: var(--text-s);
  color: currentColor;
  opacity: .75;
}

.loading {
  margin-top: 5px;
  font-size: var(--text-s);
  color: currentColor;
  opacity: .75;
}

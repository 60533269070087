/* SubSidebar.module.css */

.subSidebar {
  position: fixed;
  left: -450px;
  /* Cache la SubSidebar hors de l'écran */
  top: 0;
  height: 100%;
  width: 450px;
  background-color: var(--white);
  /* Couleur par défaut */
  transition: var(--transition);
  z-index: 1003;
  /* Supérieur à l'overlay de la SubSidebar (1002) */
  display: flex;
  flex-direction: column;
}

.headingContainer {
  height: var(--navbar);
  min-height: var(--navbar);
  display: flex;
  gap: var(--content-gap);
  padding-inline: var(--gutter);
  align-items: center;
}

.subSidebar.open {
  left: 450px;
  /* Fait glisser la SubSidebar à côté de la Sidebar */
}

.subSidebar .backButton {
  background: none;
  border: none;
  color: currentColor;
  cursor: pointer;
  padding: 0;
  min-width: 0;
  margin-left: auto;
  border-radius: 0;
}

.categoryLink {
  color: currentColor;
  text-decoration: none;
  font-size: calc(var(--text-m)*1.2);
  line-height: var(--text-line-height);
}

.pageMenu {
  overflow-y: auto;
  flex-grow: 1;
  /* Scrollbar Firefox */
  scrollbar-width: var(--firefox-width);
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

/* Scrollbar base Chromium */

.pageMenu::-webkit-scrollbar {
  width: var(--chromium-width);
  height: var(--chromium-height);
}

.pageMenu::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

.pageMenu::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border: 2px solid transparent;
}

.heading {
  display: flex;
  gap: var(--space-xs);
  align-items: center;
}

.icon {
  display: flex;
}

.icon svg {
  height: 4rem;
  width: 4rem;
  fill: var(--white);
}

.navList li {
  border-top: 1px solid currentColor;
}

.navList li:last-child {
  border-bottom: 1px solid currentColor;
}

/* Classes spécifiques pour chaque slug */
.services-et-demarches {
  --focus-color: currentColor;
  background-color: var(--services);
  color: var(--white);
}

.services-et-demarches a:not(.backButton, .categoryLink):hover {
  background-color: var(--services-light);
}


.enfance-et-jeunesse {
  --focus-color: currentColor;
  background-color: var(--jeunesse);
  color: var(--white);
}

.enfance-et-jeunesse a:not(.backButton, .categoryLink):hover {
  background-color: var(--jeunesse-light);
}


.seniors {
  --focus-color: currentColor;
  background-color: var(--seniors);
  color: var(--white);
}

.seniors a:not(.backButton, .categoryLink):hover {
  background-color: var(--seniors-light);
}


.mobilite {
  --focus-color: currentColor;
  background-color: var(--mobilite);
  color: var(--white);
}

.mobilite a:not(.backButton, .categoryLink):hover {
  background-color: var(--mobilite-light);
}


.evenements {
  --focus-color: currentColor;
  background-color: var(--events);
  color: var(--white);
}

.evenements a:not(.backButton, .categoryLink):hover {
  background-color: var(--events-light);
}


.visiter-arras {
  --focus-color: currentColor;
  background-color: var(--visiter);
  color: var(--white);
}

.visiter-arras a:not(.backButton, .categoryLink):hover {
  background-color: var(--visiter-light);
}


.votre-collectivite {
  --focus-color: currentColor;
  background-color: var(--collectivite);
  color: var(--white);
}

.votre-collectivite a:not(.backButton, .categoryLink):hover {
  background-color: var(--collectivite-light);
}


.numeros-d-urgence {
  --focus-color: currentColor;
  background-color: var(--urgence);
  color: var(--white);
}

.numeros-d-urgence a:not(.backButton, .categoryLink):hover {
  background-color: var(--urgence-light);
}


.arras-ville-dynamique {
  --focus-color: currentColor;
  background-color: var(--inclusive);
  color: var(--white);
}

.arras-ville-dynamique a:not(.backButton, .categoryLink):hover {
  background-color: var(--inclusive-light);
}


.carte-interactive {
  --focus-color: currentColor;
  background-color: var(--carte);
  color: var(--white);
}

.carte-interactive a:not(.backButton, .categoryLink):hover {
  background-color: var(--carte-light);
}


.contact {
  --focus-color: currentColor;
  background-color: var(--contact);
  color: var(--white);
}

.contact a:not(.backButton, .categoryLink):hover {
  background-color: var(--contact-light);
}


.partenaires {
  --focus-color: currentColor;
  background-color: var(--partenaires);
  color: var(--white);
}

.partenaires a:not(.backButton, .categoryLink):hover {
  background-color: var(--partenaires-light);
}


.pinned {
  --focus-color: currentColor;
  background-color: var(--pinned);
  color: var(--neutral);
}

.pinned a:not(.backButton, .categoryLink):hover {
  background-color: var(--pinned-light);
}

.pinned.redGreen {
  --focus-color: currentColor;
  background-color: var(--red-green-pinned);
  color: var(--neutral);
}

.pinned.blueYellow {
  --focus-color: currentColor;
  background-color: var(--blue-yellow-pinned);
  color: var(--neutral);
}

.pinned.blackWhite {
  --focus-color: currentColor;
  background-color: var(--black-white-pinned);
  color: var(--neutral);
}

.pinned.dark {
  --focus-color: currentColor;
  background-color: var(--dark-pinned);
  color: var(--white);
}
.colorSettings label {
    position: relative;
  }

  .colorSettings label:focus,
  .colorSettings label:focus-visible,
  .colorSettings label:focus-within {
    outline: solid;
    outline-width: var(--focus-width);
    outline-offset: var(--focus-offset);
    outline-color: var(--focus-color);
  }
  
  .colorSettings input[type="checkbox"] {
    /* opacity: 0; A reactiver après les bugfixes */
    position: absolute;
    /* width: 0; A reactiver après les bugfixes */
    top: 0;
    left: 0;
  }
  
  .colors {
    display: flex;
    gap: calc(var(--space-xs)/2);
    flex-wrap: wrap;
  }

  .colors li {
    width: var(--space-s);
    height: var(--space-s);
    border-radius: var(--radius-circle);
  }

  /* Colors */

  .white {
    background-color: var(--white);
  }
  .neutral {
    background-color: var(--neutral);
  }

  .primary {
    background-color: var(--primary);
  }

  .secondary {
    background-color: var(--secondary);
  }
  .secondaryLight {
    background-color: var(--secondary-light);
  }
  .secondaryUltraLight {
    background-color: var(--secondary-ultra-light);
  }
  .secondaryDark {
    background-color: var(--secondary-dark);
  }
  .secondaryUltraDark {
    background-color: var(--secondary-ultra-dark);
  }
  
  .pinned {
    background-color: var(--pinned);
  }
  .services-et-demarches {
    background-color: var(--services);
  }
  .jeunesse {
    background-color: var(--jeunesse);
  }
  .seniors {
    background-color: var(--seniors);
  }
  .mobilite {
    background-color: var(--mobilite);
  }
  .events {
    background-color: var(--events);
  }
  .visiter {
    background-color: var(--visiter);
  }
  .collectivite {
    background-color: var(--collectivite);
  }
  .inclusive {
    background-color: var(--inclusive);
  }
  .urgence {
    background-color: var(--urgence);
  }
  .carte {
    background-color: var(--carte);
  }
  .contact {
    background-color: var(--contact);
  }
  .partenaires {
    background-color: var(--partenaires);
  }

  .rgPrimary {
    background-color: var(--red-green-primary);
  }
  .rgSecondary {
    background-color: var(--red-green-secondary);
  }
  .rgSecondaryUltraLight {
    background-color: var(--red-green-secondary-ultra-light);
  }
  .rgSecondaryLight {
    background-color: var(--red-green-secondary-light);
  }
  .rgSecondaryDark {
    background-color: var(--red-green-secondary-dark);
  }
  .rgSecondaryUltraDark {
    background-color: var(--red-green-secondary-ultra-dark);
  }
  
  .rgPinned {
    background-color: var(--red-green-pinned);
  }
  .rgServices {
    background-color: var(--red-green-services);
  }
  .rgJeunesse {
    background-color: var(--red-green-jeunesse);
  }
  .rgSeniors {
    background-color: var(--red-green-seniors);
  }
  .rgMobilite {
    background-color: var(--red-green-mobilite);
  }
  .rgEvents {
    background-color: var(--red-green-events);
  }
  .rgVisiter {
    background-color: var(--red-green-visiter);
  }
  .rgCollectivite {
    background-color: var(--red-green-collectivite);
  }
  .rgInclusive {
    background-color: var(--red-green-inclusive);
  }
  .rgUrgence {
    background-color: var(--red-green-urgence);
  }
  .rgCarte {
    background-color: var(--red-green-carte);
  }
  .rgContact {
    background-color: var(--red-green-contact);
  }
  .rgPartenaires {
    background-color: var(--red-green-partenaires);
  }

  .byPrimary {
    background-color: var(--blue-yellow-primary);
  }
  .bySecondary {
    background-color: var(--blue-yellow-secondary);
  }
  .bySecondaryUltraLight {
    background-color: var(--blue-yellow-secondary-ultra-light);
  }
  .bySecondaryLight {
    background-color: var(--blue-yellow-secondary-light);
  }
  .bySecondaryDark {
    background-color: var(--blue-yellow-secondary-dark);
  }
  .bySecondaryUltraDark {
    background-color: var(--blue-yellow-secondary-ultra-dark);
  }

  .byPinned {
    background-color: var(--blue-yellow-pinned);
  }
  .byServices {
    background-color: var(--blue-yellow-services);
  }
  .byJeunesse {
    background-color: var(--blue-yellow-jeunesse);
  }
  .bySeniors {
    background-color: var(--blue-yellow-seniors);
  }
  .byMobilite {
    background-color: var(--blue-yellow-mobilite);
  }
  .byEvents {
    background-color: var(--blue-yellow-events);
  }
  .byVisiter {
    background-color: var(--blue-yellow-visiter);
  }
  .byCollectivite {
    background-color: var(--blue-yellow-collectivite);
  }
  .byInclusive {
    background-color: var(--blue-yellow-inclusive);
  }
  .byUrgence {
    background-color: var(--blue-yellow-urgence);
  }
  .byCarte {
    background-color: var(--blue-yellow-carte);
  }
  .byContact {
    background-color: var(--blue-yellow-contact);
  }
  .byPartenaires {
    background-color: var(--blue-yellow-partenaires);
  }

  .bwPrimary {
    background-color: var(--black-white-primary);
  }
  .bwSecondary {
    background-color: var(--black-white-secondary);
  }
  .bwSecondaryUltraLight {
    background-color: var(--black-white-secondary-ultra-light);
  }
  .bwSecondaryLight {
    background-color: var(--black-white-secondary-light);
  }
  .bwSecondaryDark {
    background-color: var(--black-white-secondary-dark);
  }
  .bwSecondaryUltraDark {
    background-color: var(--black-white-secondary-ultra-dark);
  }

  .bwPinned {
    background-color: var(--black-white-pinned);
  }
  .bwServices {
    background-color: var(--black-white-services);
  }
  .bwJeunesse {
    background-color: var(--black-white-jeunesse);
  }
  .bwSeniors {
    background-color: var(--black-white-seniors);
  }
  .bwMobilite {
    background-color: var(--black-white-mobilite);
  }
  .bwEvents {
    background-color: var(--black-white-events);
  }
  .bwVisiter {
    background-color: var(--black-white-visiter);
  }
  .bwCollectivite {
    background-color: var(--black-white-collectivite);
  }
  .bwInclusive {
    background-color: var(--black-white-inclusive);
  }
  .bwUrgence {
    background-color: var(--black-white-urgence);
  }
  .bwCarte {
    background-color: var(--black-white-carte);
  }
  .bwContact {
    background-color: var(--black-white-contact);
  }
  .bwPartenaires {
    background-color: var(--black-white-partenaires);
  }
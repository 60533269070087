.citoyen {
    --btn-background: var(--primary);
    --btn-background-hover: var(--primary-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--primary);
    --btn-border-color-hover: var(--primary-light);
    --focus-color: var(--primary-light);
  }

.touriste {
    --btn-background: var(--visiter);
    --btn-background-hover: var(--visiter-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--visiter);
    --btn-border-color-hover: var(--visiter-light);
    --focus-color: var(--visiter-light);
  }

  .jeune {
    --btn-background: var(--jeunesse);
    --btn-background-hover: var(--jeunesse-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--jeunesse);
    --btn-border-color-hover: var(--jeunesse-light);
    --focus-color: var(--jeunesse-light);
  }

  .senior {
    --btn-background: var(--seniors);
    --btn-background-hover: var(--seniors-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--seniors);
    --btn-border-color-hover: var(--seniors-light);
    --focus-color: var(--seniors-light);
  }

  .parent {
    --btn-background: var(--collectivite);
    --btn-background-hover: var(--collectivite-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--collectivite);
    --btn-border-color-hover: var(--collectivite-light);
    --focus-color: var(--collectivite-light);
  }

  .professionnel {
    --btn-background: var(--partenaires);
    --btn-background-hover: var(--partenaires-light);
    --btn-text-color: var(--white);
    --btn-text-color-hover: var(--white);
    --btn-border-color: var(--partenaires);
    --btn-border-color-hover: var(--partenaires-light);
    --focus-color: var(--partenaires-light);
  }